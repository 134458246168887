import { __number_format } from "./common_functions.es6";

export function SB_Round(number, decimals) {
	var k = Math.pow(10, decimals);
	return Math.round(number * k) / k;
}

export function get_precision(number) {
	return String(number).match(/-?(\d*)(\.?)(\d*)/)[3].length;
}

export function SB_Add(number1, number2) {
	var precision = Math.max(get_precision(number1), get_precision(number2));
	return SB_Round(number1 + number2, precision);
}

export function SB_Subtract(number1, number2) {
	var precision = Math.max(get_precision(number1), get_precision(number2));
	return SB_Round(number1 - number2, precision);
}

export function human_filesize(bytes, decimals = 2) {
    let size = ['B','KB','MB','GB','TB','PB','EB','ZB','YB'];
    let factor = Math.floor((`${bytes}`.length - 1) / 3);
	let num = SB_Round(bytes / Math.pow(1000, factor), decimals);
	num = __number_format(num,decimals,',','.')
    return `${num} ${size[factor]}`;
}