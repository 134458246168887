<script>
	export default {
		name: "transition-expand",
		functional: true,
		props: ['appear'],
		render: function (createElement, context) {
			var oldHeight = "";
			var oldDisplay = "";
			var data = {
				props: {
					name: 'transition-expand',
					appear: context.props.appear,
					// mode: 'out-in',
				},
				on: {
					beforeEnter: function (el) {
						// console.log(el);
						var tmp = jQuery(el).clone(false);
						tmp.css({
							visibility: 'hidden',
							position: 'absolute',
						})

						// Elementet vi skal animere højden på.
						// Hvis det er en tabel, så skal vi finde et wrapper-element vi kan animere.
						let elm = jQuery(el);
						let tmpEl = elm.find(".td-wrapper");
						if (tmpEl.length && tmp.is("tbody")) {
							elm = tmpEl;
						}
						oldHeight = elm[0].style.height;
						oldDisplay = elm[0].style.display;
						// tmp.insertAfter(jQuery(context.parent.$el));

						context.parent.$nextTick(()=>{
							let tmpEl = tmp.find(".td-wrapper");
							let height;
							tmp.appendTo(jQuery(el).parent());
							if (tmpEl.length && tmp.is("tbody")) {
								// tmp = tmpEl;
								let parent = tmpEl.parent();
								height = (parseFloat(tmpEl.css("height")) - parseFloat(parent.css("border-top-width")) - parseFloat(parent.css("border-bottom-width"))) + "px";
							} else {
								height = tmp.css("height");
							}
							// tmp.insertAfter(jQuery(el).prev());
							// elm.css("display", "block");
							elm.css("height", height);
							tmp.remove();
						})
					},
					afterEnter: function (el) {
						let elm = jQuery(el);
						let tmpEl = elm.find(".td-wrapper");
						if (tmpEl.length && elm.is("tbody")) {
							elm = tmpEl;
						}
						elm.css({
							height: oldHeight,
							// display: oldDisplay,
						});
					},
					beforeLeave: function (el) {
						// console.log(el);
						let elm = jQuery(el);
						let tmpEl = elm.find(".td-wrapper");
						if (tmpEl.length && elm.is("tbody")) {
							elm = tmpEl;
						}
						oldHeight = elm[0].style.height;
						// elm.css("display", "block");
						elm.css("height", elm.css("height"));
						return;
					},
					afterLeave: function (el) {
						// console.log(el);
						let elm = jQuery(el);
						let tmpEl = elm.find(".td-wrapper");
						if (tmpEl.length && elm.is("tbody")) {
							elm = tmpEl;
						}
						elm.css({
							height: oldHeight,
							// display: oldDisplay,
						});
					},
				},
			};
			return createElement('transition', data, context.children)
		}
	};
</script>
