<script>
	export default {
		name: "transition-fade",
		functional: true,
		props: ['appear'],
		render: function (createElement, context) {
			var data = {
				props: {
					name: 'fade',
					mode: 'out-in',
					appear: context.props.appear,
				},

			};
			return createElement('transition', data, context.children)
		}
	};
</script>
