<template>
	<textarea class="input input-textarea" :style="[computed_style, inherited_style]" v-bind="$attrs" v-on="$listeners" @input="/*$emit('input', $event.target.value)*/"></textarea>
</template>
<script>
export default {
	name: "sb-input-textarea",
	computed: {
		computed_style: function() {
			if (this.$attrs && this.$attrs.autosizeHeight) {
				return {
					position: "relative",
					minHeight: this.$attrs.autosizeHeight,
				};
			}
			return {};
		},
		inherited_style: function() {
			var styles = {};
			$attrs = this.$attrs;
			if ($attrs._style) {
				$attrs._style.split(";").forEach(function(val) {
					if (!val.trim()) return;
					var o = {};
					val = val.split(":");
					var k = val.shift();
					styles[k] = val.join(":").trim();
				});
			}
			return styles;
		},
	},
};
</script>
