<template>
	<div class="List">
		<div v-if="quickselect"
			class='submenu'
			:id='`quickselect-${id}`'
			style='display:none; position:absolute; z-index: 10000;'
			data-placement='right-start'
			data-boundaries-element='.List'
		>
			<div class='mini-quickselect'>
				<a v-for="(option) in _quickselect" :key="option[0]" class='button' @click="handle_quickselect(option[0])">{{option[1]}}</a>
			</div>
			<div class='popper__arrow' data-popper-arrow style='border-color: var(--PopupRightMenuBorder);'></div>
		</div>

		<table>
			<thead v-if="has_titles">
				<tr>
					<th v-if="highlight == 'multiple'" width="25px">
						<div v-if="quickselect"
							class="has-submenu"
							:data-popper-el='`#quickselect-${id}`'
							style='display:inline; margin-left: -3px; width: 12px;'
						><i class="fa fa-square-o"></i></div>
					</th>
					<th v-for="(column, i) in visible_columns" :key="i" :class="{right: column.align == 'right'}" :width="column.width || ''">
						{{ column.title }}
					</th>
				</tr>
			</thead>
			<template v-if="transition">
				<transition-expand v-for="(row, id) in dataset" :key="row.id || id">
					<tbody :class="[_rc(row, row.id || id), {highlight: list_highlighted.indexOf(row.id || id) >= 0}]">
						<tr @click="toggleHighlight(row.id || id, $event)">
							<td v-if="highlight == 'multiple'" multihighlighthelper width="25px" @click.stop="toggleHighlight(row.id || id, ['meta'])"><span class="on"><i class="fa fa-check-square-o"></i></span><span class="off"><i class="fa fa-square-o"></i></span></td>
							<td v-for="(column, i) in visible_columns" :key="i" :class="{right: column.align == 'right'}" :title="_title(row, column)">
								<div class="td-wrapper">
									<slot :name="column.key" v-bind:row="row">
										{{ resolve(row, column.key) | filter(column.filter) }}
									</slot>
								</div>
							</td>
						</tr>
					</tbody>
				</transition-expand>
			</template>
			<template v-else>
				<tbody v-for="(row, id) in dataset" :key="row.id || id" :class="[_rc(row, row.id || id), {highlight: list_highlighted.indexOf(row.id || id) >= 0}]">
					<tr @click="toggleHighlight(row.id || id, $event)">
						<td v-if="highlight == 'multiple'" multihighlighthelper width="25px" @click.stop="toggleHighlight(row.id || id, ['meta'])"><span class="on"><i class="fa fa-check-square-o"></i></span><span class="off"><i class="fa fa-square-o"></i></span></td>
						<td v-for="(column, i) in visible_columns" :key="i" :class="{right: column.align == 'right'}" :title="_title(row, column)">
							<div class="td-wrapper">
								<slot :name="column.key" v-bind:row="row">
									{{ resolve(row, column.key) | filter(column.filter) }}
								</slot>
							</div>
						</td>
					</tr>
				</tbody>
			</template>
		</table>
	</div>
</template>
<script>
/**
 * _get utility - an alternative to lodash.get
 * @author @harish2704, @muffypl, @pi0, @imnnquy
 * @param {Object} object
 * @param {String|Array} path
 * @param {*} defaultVal
 */
function _get (object, path, defaultVal) {
  if (!path) {
    return object === undefined ? defaultVal : object;
  }
  if (object === undefined) {
    return defaultVal;
  }
  const _path = Array.isArray(path)
    ? path
    : path.replace(/(\[(\d)\])/g, '.$2').split('.').filter(i => i.length)

  let _object = object[_path[0]];

  if (null === _object) {
	  return _object;
  }

  if (_object && _path.length <= 1) {
    return _object === undefined ? defaultVal : _object
  }

  return _get(_object, _path.slice(1), defaultVal)
}

// import _get from "lodash/get";

export default {
	name: "sb-list",
	model: {
		prop: 'highlighted',
		event: 'highlight',
	},
	props: {
		columns: {
			default() {
				return [];
			},
		},
		dataset: {
			default() {
				return [];
			},
		},
		quickselect: {
			required: false,
			default: ()=>false,
		},
		id: {
			required: false,
			default: () => 'random-id',
		},
		paginator: {
			required: false,
			default: ()=>false,
			// default: () => {return {
			// 	reset_highlight: false
			// }},
		},
		rowclass: {
			required: false,
			default: function() {
				return function() {
					return "";
				};
			},
		},
		titles: {
			required: false,
			default: function() {
				return {};
			},
		},
		highlight: {
			default: false,
		},
		highlighted: {
			default: () => [],
		},
		transition: {
			default: false,
		},
	},
	data: function() {
		return {
			list_highlighted: this.highlighted,
			_last_clicked_id: false,
			show_quickselect: false,
		};
	},
	watch: {
		highlighted(newVal, oldVal) {
			if (newVal != oldVal) {
				this.list_highlighted = newVal;
			}
		},
	},
	computed: {
		has_titles: function() {
			var has_titles = false;
			this.columns.forEach(function(val) {
				if (val.title) {
					has_titles = true;
				}
			});
			return has_titles;
		},
		visible_columns() {
			return this.columns.filter(col => col.hidden != true);
		},
		_quickselect() {
			if (this.quickselect === true) {
				if (this.paginator) {
					if (this.paginator.reset_highlight === false) {
						return [
							['select_page', "Vælg alle på denne side"]
							['select_all', "Vælg alle på alle sider"]
						];
	
					} else {
						return [['select_page', "Vælg alle på denne side"]];
					}
				} else {
					return [['select_page', "Vælg alle"]];
				}
			}
		},
	},
	methods: {
		resolve: function(row, keypath) {
			return _get(row, keypath, "");
		},
		toggleHighlight: function(id, $event) {
			var modifiers = this.get_modifiers($event);
			if (!modifiers.meta && (!modifiers.shift || this._last_clicked_id === false)) {
				if (this.list_highlighted.indexOf(id) >= 0) {
					this.list_highlighted = [];
					this._last_clicked_id = false;
				} else {
					this.list_highlighted = [id];
					this._last_clicked_id = id;
				}
			} else if (modifiers.meta && !modifiers.shift) {
				if (this.list_highlighted.indexOf(id) >= 0) {
					Vue.delete(
						this.list_highlighted,
						this.list_highlighted.indexOf(id)
					);
					this._last_clicked_id = false;
				} else {
					this.list_highlighted.push(id);
					this._last_clicked_id = id;
				}
			} else if (modifiers.shift) {
				let a = this.dataset.findIndex(el=>el.id == id);
				let b = this.dataset.findIndex(el=>el.id == this._last_clicked_id);
				let from = Math.min(a, b);
				let to = Math.max(a, b);
				for (let i = from; i<=to; i++) {
					if (this.list_highlighted.indexOf(this.dataset[i].id) == -1) {
						this.list_highlighted.push(this.dataset[i].id);
					}
				}
			}
			this.$emit("highlight", this.list_highlighted);
		},
		get_modifiers: function($event) {
			var tmp = {
				alt: false,
				ctrl: false,
				meta: false,
				shift: false,
			};
			if (typeof $event === "string") {
				$event = [$event];
			}
			if (Array.isArray($event)) {
				$event.forEach(val => {
					tmp[val] = true;
				});
			} else {
				if ($event.altKey) {
					tmp.alt = true;
				}
				if ($event.ctrlKey) {
					tmp.ctrl = true;
					tmp.meta = true;
				}
				if ($event.metaKey) {
					tmp.meta = true;
				}
				if ($event.shiftKey) {
					tmp.shift = true;
				}
			}
			return tmp;
		},
		handle_quickselect(option) {
			if (option == 'select_page') {
				this.list_highlighted = [];
				this.dataset.forEach(val=>{this.list_highlighted.push(val.id)});
				this.$emit("highlight", this.list_highlighted);
				document.body.click()
			}
			this.$emit('quickselect', option[0])
		},
		_rc: function(row, id) {
			return typeof this.rowclass === "function"
				? this.rowclass(row, id)
				: this.rowclass;
		},
		_title: function(row, column) {
			if (typeof this.titles[column.key] === "function") {
				return this.titles[column.key](row);
			}
			if (typeof this.$options.filters[column.filter] === "function") {
				return this.$options.filters[column.filter](row[column.key]);
			}
			return row[column.key];
		},
	},
	filters: {
		filter: function(input, filter) {
			switch (filter) {
				case "date":
					if (typeof input === "boolean") {
						return "-";
					}
					return dateFormat(input*1000, "dd/LL-yyyy") || "-";
				case "date_time":
					if (typeof input === "boolean") {
						return "-";
					}
					return dateFormat(input*1000, "yyyy-LL-dd HH:mm:ss") || "-";
				case "price":
					if (typeof input === "boolean") {
						return "-";
					}
					return !isNaN(input) ? SB_FormatNumber(input) : "-";
				default:
					if (typeof input === "boolean") {
						return "";
					}
					return input;
			}
		},
	},
};
</script>