import { SB_Popup } from "./popup.es6";
import { SB_Input } from "./ui_elements.es6";
import { SB_WindowRedirect } from "./common_functions.es6";
import { SB_Ajax } from "./ajax.es6";

export var SB_MainMenu = {
	Active : false,
	MouseOver : false,
	Timeout : false,
//	MenuVersion : "classic",
	ShowingShortcutHints : false,
	HoldingAlt : false,
	
	Init : function()
	{
//		if ($$('.body.modern').size() > 0 || $$('.body.modern2').size() > 0)
//		{
//			SB_MainMenu.MenuVersion = "modern";
//		}

		// if (SB_TouchDevice)
		// {
		// 	$$('.MainMenuItem').invoke("observe","touchstart",SB_MainMenu.Click).invoke("observe","mouseenter",SB_MainMenu.MouseEnter).invoke("observe","mouseleave",SB_MainMenu.MouseLeave);
		// 	$$('.ChildMenuItem').invoke("observe","touchstart",function() {$$('.ChildMenuItem.active').invoke("removeClassName","active"); this.addClassName("active");});
		// }
		// else
		{
			jQuery(document).on("mousedown",".MainMenuItem", function(evt) {
				SB_MainMenu.Click.bind($(this))(evt);
				return false;
			});
			jQuery(document).on("mouseenter",".ChildMenuItem", function() {
				jQuery('.ChildMenuItem.active').removeClass("active");
				jQuery(this).addClass("active");
			});
			// $$('.MainMenuItem')
			// 	.invoke("observe","mousedown",SB_MainMenu.Click)
			// 	.invoke("observe","mouseenter",SB_MainMenu.MouseEnter)
			// 	.invoke("observe","mouseleave",SB_MainMenu.MouseLeave)
			// ;
			// $$('.ChildMenuItem')
			// 	.invoke("observe","mouseenter",function() {
			// 		$$('.ChildMenuItem.active')
			// 			.invoke("removeClassName","active");
			// 		this.addClassName("active");
			// 	})
			// ;
		}

		// SB_MainMenu.Active = $('MainMenu').down(".MainMenuItem.active");
	},
	
	MouseEnter : function(event)
	{
/*		if (SB_MainMenu.MenuVersion == "classic")
		{
			$$('.MainMenuItem.active').invoke("removeClassName","active");
			this.addClassName("active");
			
			SB_MainMenu.MouseOver = true;
			if (SB_MainMenu.Timeout!==false)
			{
				clearTimeout(SB_MainMenu.Timeout);
				SB_MainMenu.Timeout = false;
			}
		}
		else
		{
		}*/
	},
	
	MouseLeave : function(event)
	{
/*		if (SB_MainMenu.MenuVersion == "classic")
		{
			SB_MainMenu.MouseOver = false;
			
			SB_MainMenu.Timeout = setTimeout(function() {
				if (!SB_MainMenu.MouseOver)
				{
					$$('.MainMenuItem.active').invoke("removeClassName","active");
					SB_MainMenu.Active.addClassName("active");
				}
			}, 1000);
		}
		else
		{
		}*/
	},
	
	Click : function(event)
	{
/*		if (SB_MainMenu.MenuVersion == "classic")
		{
			$$('.MainMenuItem.active').invoke("removeClassName","active");
			this.addClassName("active");

			SB_MainMenu.Active = this;
		}
		else*/
//		{
			if (event && $(event.target).up(".ChildMenu"))
			{
			}
			else 
			{
				if (!this.hasClassName("open"))
				{
					$$('.MainMenuItem.preactive').invoke("removeClassName","active");

					$$('.MainMenuItem.open').invoke("removeClassName","open");
					this.addClassName("open");

					SB_MainMenu.Active = this;
				}
				else
				{
					this.removeClassName("open");
					$$('.MainMenuItem.preactive').invoke("addClassName","active");
				}

				$$('.ChildMenuItem').invoke("removeClassName","active");
				$$('.ChildMenuItem.preactive').invoke("addClassName","active");

			}
//		}
	},

	Mousedown : function(event)
	{
//		if (SB_MainMenu.MenuVersion != "modern")
//			return false;

		var OpenMenu = $('MainMenu').down(".open");
		if (!OpenMenu)
			return false;

		if (!event.element().up(".MainMenu"))
		{
			SB_MainMenu.Click.bind(OpenMenu)();
		}
	},

	Keydown : function(event)
	{
//		if (SB_MainMenu.MenuVersion != "modern")
//			return false;
		if (Object.size(SB_Popup.Popups) > 0)
			return false;

		let keyCode = event.keyCode;

		if (event.keyCode == 219)
			keyCode = 56;
		if (event.keyCode == 221)
			keyCode = 57;

		var OpenMenu = $('MainMenu').down(".open")

		if (keyCode == 18 && !event.ctrlKey && !event.shiftKey && SB_MainMenu.HoldingAlt != "invalid")
		{
			SB_MainMenu.ShowingShortcutHints = true;


			if (!OpenMenu || !SB_MainMenu.HoldingAlt)
			{
				let i = -1;
				$$(".MainMenuItem").each(function(item) {
					i ++; 
					if (item.hasClassName("open"))
						return false;

					var a = item.down(".MainMenuItemTitle");
					var hint = item.down(".MainMenuItemHint");
					if (!a)
						return false;

					a.setOpacity(0.4);

					if (!hint)
					{
						item.insert({'top' : '<a class="MainMenuItemHint">[Alt + '+i+']</a>'});
					}
				});
			}
			else
			{
				let i = 0;
				OpenMenu.select(".ChildMenuItem").each(function(item) {
					i ++; 
					var a = item.down(".ChildMenuItemTitle");
					var hint = item.down(".ChildMenuItemHint");
					if (!a)
						return false;

					a.setOpacity(0.3);

					if (!hint)
					{
						item.insert({'top' : '<a class="ChildMenuItemHint">[Alt + '+i+']</a>'});
					}
				});
			}
		}
		else if (event.altKey && ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) || (keyCode == 39 && !OpenMenu)) && !event.ctrlKey && !event.shiftKey && SB_MainMenu.HoldingAlt != "invalid")
		{
		// For at man kan bruge ALT til at springe frem og tilbage når man står i et input-felt (men kun hvis det er udfyldt)
			if (keyCode == 39 && event.element().hasClassName("input") && SB_Input(event.element()).value() != "")
				return false;

			// Tag højde for nummertastatur
			if (keyCode >= 96 && keyCode <= 105)
				keyCode -= 48;
			// højre piltast optræder også som Alt+1
			var num = (keyCode == 39) ? 1 : (keyCode - 48);

			if (!OpenMenu || !SB_MainMenu.HoldingAlt)
			{
				var MenuItem = $$(".MainMenuItem")[num];
				if (!MenuItem)
					return false;

				var a = MenuItem.down(".MainMenuItemTitle");
				if (a && a.onclick)
					a.onclick();
				else if (a && a.href)
					SB_WindowRedirect(a.href);

				if (a && a.onmouseover)
					a.onmouseover();


				SB_MainMenu.Click.bind(MenuItem)();
				SB_MainMenu.Keyup({'keyCode' : 18, 'altKey' : false});
				
				if (keyCode == 39)
				{
					SB_MainMenu.HoldingAlt = "invalid";
				}
				else
				{
					SB_MainMenu.HoldingAlt = true;
					SB_MainMenu.Keydown({'keyCode' : 18, 'altKey' : true});
				}
			}
			else
			{
				if (keyCode == 39)
					return false;

				var ChildMenuItem = OpenMenu.select(".ChildMenuItem .ChildMenuItemTitle")[num-1];
				if (!ChildMenuItem)
					return false;

				if (ChildMenuItem && ChildMenuItem.onclick)
					ChildMenuItem.onclick();
				else if (ChildMenuItem && ChildMenuItem.href)
					SB_WindowRedirect(ChildMenuItem.href);
			}

			event.Stopped = true;
			event.stop();
		}
		else if (keyCode == Event.KEY_DOWN)
		{
			if (OpenMenu)
			{
				var Active = OpenMenu.down(".active");
				if (Active)
				{
					Active.removeClassName("active");
					var Next = Active.next();
					if (Next)
						Next.addClassName("active");
				}
				else
				{
					OpenMenu.down(".ChildMenuItem").addClassName("active");
				}

				event.Stopped = true;
				event.stop();
			}
		}
		else if (keyCode == Event.KEY_UP)
		{
			if (OpenMenu)
			{
				let Active = OpenMenu.down(".active");
				if (Active)
				{
					Active.removeClassName("active");
					var Previous = Active.previous();
					if (Previous)
						Previous.addClassName("active");
				}
				else
				{
					OpenMenu.down(".ChildMenuItem:last-child").addClassName("active");
				}

				event.Stopped = true;
				event.stop();
			}
		}
		else if (keyCode == Event.KEY_LEFT)
		{
			if (OpenMenu)
			{
				OpenMenu.removeClassName("open");
				if (OpenMenu.previous())
					OpenMenu.previous().addClassName("open");

				SB_MainMenu.RemoveHints();

				event.Stopped = true;
				event.stop();
			}
		}
		else if (keyCode == Event.KEY_RIGHT)
		{
			if (OpenMenu)
			{
				OpenMenu.removeClassName("open");
				if (OpenMenu.next())
					OpenMenu.next().addClassName("open");

				SB_MainMenu.RemoveHints();

				event.Stopped = true;
				event.stop();
			}
		}
		else if (keyCode == Event.KEY_RETURN)
		{
			if (OpenMenu)
			{
				let Active = OpenMenu.down(".active");
				if (Active)
				{
					if (Active.down("a").onclick)
						Active.down("a").onclick();
					else
						SB_WindowRedirect(Active.down("a").href);
				}
				else if (!OpenMenu.down(".ChildMenu"))
				{
					if (OpenMenu.down("a").onclick)
						OpenMenu.down("a").onclick();
					else
						SB_WindowRedirect(OpenMenu.down("a").href);
				}

				event.Stopped = true;
				event.stop();
			}
		}
		else if (keyCode == Event.KEY_ESC)
		{
			if (OpenMenu)
			{
				SB_MainMenu.Click.bind(OpenMenu)();
			
				event.Stopped = true;
				event.stop();
			}
		}

	},

	Keyup : function(event)
	{
//		if (SB_MainMenu.MenuVersion != "modern")
//			return false;
//		if (Object.size(SB_Popup.Popups) > 0)
//			return false;
		
		if (SB_MainMenu.ShowingShortcutHints === true && !event.altKey)
		{
			SB_MainMenu.RemoveHints();

			SB_MainMenu.HoldingAlt = false;
			SB_MainMenu.ShowingShortcutHints = false;
		}
		else if (SB_MainMenu.HoldingAlt == "invalid" && !event.altKey)
			SB_MainMenu.HoldingAlt = false;

		// Check om ALT-key stadig holdes inde...
	},

	RemoveHints : function()
	{
		$$(".MainMenuItem").each(function(item) {
			var a = item.down(".MainMenuItemTitle");
			var hint = item.down(".MainMenuItemHint");
			if (!a)
				return false;
			
			a.setOpacity(1);
			if (hint)
				hint.remove();
		});

		$$(".ChildMenuItem").each(function(item) {
			var a = item.down(".ChildMenuItemTitle");
			var hint = item.down(".ChildMenuItemHint");
			if (!a)
				return false;

			a.setOpacity(1);

			if (hint)
				hint.remove();
		});
	},
	
	/*Click : function(element, id)
	{
		$$('.MainMenuItem.active').invoke('removeClassName','active');
		var MainMenuItem = $(element).up('.MainMenuItem').addClassName('active');
		
		var cookie = Cookie.get('menu2').evalJSON();
		cookie['main'] = id;
		Cookie.set('menu2',Object.toJSON(cookie));
		
		if (MainMenuItem.select(".ChildMenuItem").size() == 1)
			SB_WindowRedirect(MainMenuItem.down('.ChildMenuItem a').readAttribute('href'));
	},*/
	
	Logout : function()
	{
		new SB_Ajax("login_update.php","action=Logout").Request();
	}
}
// SB_Onload(SB_MainMenu.Init);