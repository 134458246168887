/* global $$ */
import { SB_Ajax } from "../ajax.es6.js";
import { __, SB_ReadNumber, SB_FormatNumber } from "../common_functions.es6.js"
import { SB_Add, SB_Subtract } from "../math.es6.js";
import { SB_List } from "../list.es6.js";
import { SB_Input } from "../ui_elements.es6.js";
import { Vue } from "../vue.es6.js";

export var SB_CheckTransaction = function(id)
{
	var DebetColumn = 7;
	var KreditColumn = 8;

	var TotalAmount = 0;
	var AbsoluteAmount = 0;
	var AccountsSelected = true;

	var Tbody = $('financial_post_list').down("tbody[rowid='"+id+"']");

	if (!Tbody) 
		return false;

	Tbody.select("tr .account_key input[type='hidden']").each(function(account_key) {
		if (account_key.value == "e_0_0" || account_key.value == "")
		{
			AccountsSelected = false;
			return false;
		}	
	});

	var Rows = Tbody.select("tr");
	
	Rows.each(function(tr) {
		var DebetCell = tr.cells[DebetColumn-1];
		var KreditCell = tr.cells[KreditColumn-1];

		var DebetInput = DebetCell.down("input");
		var Debet = (DebetInput) ? DebetInput.value : DebetCell.innerHTML;
		Debet = (Debet != "") ? SB_ReadNumber(Debet) : 0;

		var KreditInput = KreditCell.down("input");
		var Kredit = (KreditInput) ? KreditInput.value : KreditCell.innerHTML;
		Kredit = (Kredit != "") ? SB_ReadNumber(Kredit) : 0;
		
		TotalAmount = SB_Add(TotalAmount, Debet);
		TotalAmount = SB_Subtract(TotalAmount, Kredit);

		AbsoluteAmount = SB_Add(AbsoluteAmount, Math.abs(Debet));
		AbsoluteAmount = SB_Add(AbsoluteAmount, Math.abs(Kredit));
	});

	if (Rows.size() == 1)
		TotalAmount = 0;

	if (AccountsSelected === false || TotalAmount != 0 || AbsoluteAmount == 0)
		Tbody.removeClassName("green").addClassName("red");
	else
		Tbody.removeClassName("red").addClassName("green");


};

export var SB_AccountMultiplePosts = function(event) {
	var Row = (this.Highlighted.size() == 1) ? this.GetRow(this.Highlighted[0]) : false;
	
	if (Row)
	{
		var RowArgs = Row.readAttribute('rowid').split("_");
		
		var RowType = RowArgs[0];
		var AccountId = RowArgs[1];
		var AccountNum = RowArgs[2];
		var TaxRateId = RowArgs[3];
		var Description = RowArgs[4];
		var Balance = RowArgs[5];
	
		$$(".FormElement.multi-editable-left-account").each(function(FormElement) {
			let AccountKey = SB_Input(FormElement.down("input[type='hidden']"));
			let AccountSearch = SB_Input(FormElement.down("input[type='text']"));
			let TransactionId = FormElement.up("tbody").readAttribute("rowid");

			if (RowType == "e")
			{
				AccountKey.update("");
				AccountSearch.update("");
			}
			else if (RowType == "f")
			{
				AccountKey.update("f_"+AccountId+"_"+AccountNum);
				AccountSearch.update(__("F ")+AccountNum);
			}
			else if (RowType == "d" || RowType == "i")
			{
				AccountKey.update("d_"+AccountId+"_"+AccountNum);
				AccountSearch.update(__("D ")+AccountNum);
				
			}			
			else if (RowType == "k")
			{
				AccountKey.update("k_"+AccountId+"_"+AccountNum);
				AccountSearch.update(__("K ")+AccountNum);
				
			}			
		
		
			SB_CheckTransaction(TransactionId);
		});
		
		$$(".FormElement.multi-editable-tax-rate").each(function(FormElement) {
			SB_Input(FormElement.down("select")).update(TaxRateId);
		
		});
	}
	
	SB_Input(this.AutoComplete).update("");
	
}

export var SB_FinancialPostAccountSelect = function(event, tmp) {
	var transaction_id = this.AjaxParams.transaction_id;
	var post_id = this.AjaxParams.post_id;
	
	var Row = (this.Highlighted.size() == 1) ? this.GetRow(this.Highlighted[0]) : false;
	
	if (Row)
	{
		var RowArgs = Row.readAttribute('rowid').split("_");
		
		var RowType = RowArgs[0];
		var AccountId = RowArgs[1];
		var AccountNum = RowArgs[2];
		var TaxRateId = RowArgs[3];
		var Description = RowArgs[4];
		var Balance = RowArgs[5];
		
		if (RowType == "e")
		{
			SB_Input("FinancialPostList["+transaction_id+"][posts]["+post_id+"][account_key]").update("");
			SB_Input("FinancialPostList["+transaction_id+"][posts]["+post_id+"][account_search]").update("");
		}
		else if (RowType == "f")
		{
			SB_Input("FinancialPostList["+transaction_id+"][posts]["+post_id+"][account_key]").update("f_"+AccountId+"_"+AccountNum);
			SB_Input("FinancialPostList["+transaction_id+"][posts]["+post_id+"][account_search]").update(__("F ")+AccountNum);

			if (SB_Input("FinancialPostList["+transaction_id+"][description]") && SB_Input("FinancialPostList["+transaction_id+"][description]").value() == "")
				SB_Input("FinancialPostList["+transaction_id+"][description]").update(Description);
			else if (SB_Input("FinancialPostList["+transaction_id+"][posts]["+post_id+"][description]") && SB_Input("FinancialPostList["+transaction_id+"][posts]["+post_id+"][description]").value() == "")
				SB_Input("FinancialPostList["+transaction_id+"][posts]["+post_id+"][description]").update(Description);
		}
		else if (RowType == "d" || RowType == "i")
		{
			SB_Input("FinancialPostList["+transaction_id+"][posts]["+post_id+"][account_key]").update("d_"+AccountId+"_"+AccountNum);
			SB_Input("FinancialPostList["+transaction_id+"][posts]["+post_id+"][account_search]").update(__("D ") + AccountNum);
			
			if (RowType == "i")
			{
				if ($("input[FinancialPostList["+transaction_id+"][posts]["+post_id+"][description]]"))
					SB_Input("FinancialPostList["+transaction_id+"][posts]["+post_id+"][description]").update(Description);
				if ($("input[FinancialPostList["+transaction_id+"][description]]"))
					SB_Input("FinancialPostList["+transaction_id+"][description]").update(Description);
					
				if ($("input[FinancialPostList["+transaction_id+"][kredit]]"))
				{
					SB_Input("FinancialPostList["+transaction_id+"][debet]").update("");
					SB_Input("FinancialPostList["+transaction_id+"][kredit]").update(SB_FormatNumber(Balance));
				}
				else if ($("input[FinancialPostList["+transaction_id+"][posts]["+post_id+"][kredit]]"))
				{
					SB_Input("FinancialPostList["+transaction_id+"][posts]["+post_id+"][debet]").update("");
					SB_Input("FinancialPostList["+transaction_id+"][posts]["+post_id+"][kredit]").update(SB_FormatNumber(Balance));
				}	
			}
		}
		else if (RowType == "k")
		{
			SB_Input("FinancialPostList["+transaction_id+"][posts]["+post_id+"][account_key]").update("k_"+AccountId+"_"+AccountNum);
			SB_Input("FinancialPostList["+transaction_id+"][posts]["+post_id+"][account_search]").update(__("K ")+AccountNum);
			

		}

		if (RowType == "f" || RowType == "d" || RowType == "k")
		{
			if (SB_Input("FinancialPostList["+transaction_id+"][description]") && SB_Input("FinancialPostList["+transaction_id+"][description]").value().trim() == "")
				SB_Input("FinancialPostList["+transaction_id+"][description]").update(Description);
			else if (SB_Input("FinancialPostList["+transaction_id+"][posts]["+post_id+"][description]") && SB_Input("FinancialPostList["+transaction_id+"][posts]["+post_id+"][description]").value() == "")
				SB_Input("FinancialPostList["+transaction_id+"][posts]["+post_id+"][description]").update(Description);
		}

		if (SB_Input("FinancialPostList["+transaction_id+"][posts]["+post_id+"][tax_rate_id]"))
			SB_Input("FinancialPostList["+transaction_id+"][posts]["+post_id+"][tax_rate_id]").update(TaxRateId);

	}
	else
	{
		new SB_Ajax('financial_post_list_ajax.php','action=SelectAccount&transaction_id='+transaction_id+'&post_id='+post_id+'&search='+SB_Input("FinancialPostList["+transaction_id+"][posts]["+post_id+"][account_search]").value()).SetDelay(0).SetLoadingEffect(false).Request();
//		SB_Input("FinancialPostList["+transaction_id+"][posts]["+post_id+"][account_key]").update($("input[FinancialPostList["+transaction_id+"][posts]["+post_id+"][account_key]]").readAttribute('value'));
//		SB_Input("FinancialPostList["+transaction_id+"][posts]["+post_id+"][account_search]").update($("input[FinancialPostList["+transaction_id+"][posts]["+post_id+"][account_search]]").readAttribute('value'));
	}

	SB_CheckTransaction(transaction_id);
	
	var td = this.AutoComplete.up("td");
	if (!event.shiftKey)
	{
		while (td = td.next())
		{
			var input = td.down(".input")
			if (input && !input.disabled)
			{
				SB_Input(input).activate();
				break;
			}
		}
	}
	else
	{
		while (td = td.previous())
		{
			let input = td.down(".input")
			if (input && !input.disabled)
			{
				SB_Input(input).activate();
				break;
			}
		}
	}

	return true;
}

var SB_FinancialPostListEnter = function(event) {
	if (this.Highlighted.size()>0)
	{
		this.InPlaceEdit();
		return true;
	}
	
	return false;
};

var SB_FinancialPostListHighlight = function(financial_post_list_options) {
	if (this.Highlighted.length == 1) {
		financial_post_list_options.transaction_id = this.Highlighted[0];
	} else {
		financial_post_list_options.transaction_id = financial_post_list_options.user_id;
	}
}

var vue_instance = false;
export async function init_financial_post_list(element, options) {
	let financialPostListComponent = await import( /* webpackChunkName: "financial-post-list" */ "../../vue-components/pages/financial_post_list.vue");
	let vue_financial_post_list = await Vue.extend(financialPostListComponent.default);

	if (vue_instance) {
		return vue_instance;
	}

	vue_instance = new vue_financial_post_list({
	}).$mount();

	vue_instance.from_date = options.FromDate;
	vue_instance.to_date = options.ToDate;
	vue_instance.show_recorded = options.ShowRecorded;
	vue_instance.show_invoices = options.ShowInvoices;
	vue_instance.latest_import = options.LatestImport;
	vue_instance.show_latest_import = options.ShowLatestImport;
	vue_instance.is_voucher_module_active = options.IsVoucherModuleActive;
	vue_instance.user_id = options.UserId;
	vue_instance.transaction_id = options.UserId;
	vue_instance.loose_files = options.LooseFiles;
	element.appendChild(vue_instance.$el);

	let list = new SB_List('financial_post_list','financial_post_list_ajax.php',{
		'FromDate': options.FromDate,
		'ToDate': options.ToDate,
		'ShowRecorded': options.ShowRecorded,
		'ShowInvoices': options.ShowInvoices,
		'ShowLatestImport': options.ShowLatestImport,
	})
		.SetAutoComplete('input[financial_post_list_search]')
		.ListenGlobal()
		.SetContextBar('financial_post_list_options')
		.SetAllowHighlight('multiple')
		.AddEvent('enter',SB_FinancialPostListEnter)
		.AddEvent('dblclick',SB_FinancialPostListEnter)
		.AddEvent('highlight', function() {SB_FinancialPostListHighlight.bind(this)(vue_instance)})
		.AddEvent('toggleinplaceedit', (on_or_off)=>{vue_instance.inplaceediting = on_or_off})
		.Refresh()
	;

	vue_instance.$on('voucher_changed', ()=>{
		if (!list.InPlaceEditing) {
			list.ForceRefresh().Refresh();
		}
	})


	return vue_instance;
}
