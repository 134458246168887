/*global $$*/
import { SB_Dialog, SB_Form, SB_Input } from "../ui_elements.es6.js";
import { SB_List, SB_Lists } from "../list.es6.js";
import { SB_OpenInvoice, SB_OpenRecorded, SB_Timestamp } from "../common_functions.es6.js";
import { SB_MessageStack } from "../message_stack.es6.js";
import { SB_Popup } from "../popup.es6.js";
import { Vue } from "../vue.es6.js";

var ReportType = false;

export var SB_FinancialReportChangeType = function(value, options = {}) {
	SB_Lists["financial_report_list"].ReportType = value;
	if (typeof vue_instance !== "undefined") {
		vue_instance.report_type = value;
		vue_instance.post_id = 0;
	}
	options = Object.assign({
		fetch_info: '<i class="fa fa-info-circle fa-fw fa-lg"></i> Hent oplysninger',
		show_wrapper: value,
	}, options);

	$("financial_report_list_options").update("");

	$("fetch_info")
		.down("span")
		.update(options.fetch_info);

	if (options.show_wrapper !== false) {
		$$(".OptionWrapper").invoke("hide");
	}

	if (options.show_wrapper != "") {
		var WrapperId = "Wrapper_" + options.show_wrapper;
		if (options.show_wrapper == "provision_day") WrapperId = "Wrapper_provision";

		$(WrapperId).show();
		// trick for at skjulte selects ser korrekte ud
		$(WrapperId)
			.select(".chosen-select select")
			.each(function(sel) {
				Event.fire(sel, "chosen:updated");
			});

		$("Wrapper_submit").show();
	} else {
		$("Wrapper_submit").hide();
	}

	if (
		value == "printfakturaer" ||
		value == "saft"
	) {
		$("fetch_info").hide();
	} else {
		$("fetch_info").show();
	}

	if (
		value == "nogletalsrapport" ||
		value == "saldobalance" ||
		value == "saldoliste" ||
		value == "kreditorliste" ||
		value == "posteringsliste" ||
		value == "kontospecifikation" ||
		value == "fakturajournal" ||
		value == "printfakturaer" ||
		value == "saft" ||
		value == "custom1"
	) {
		$("Wrapper_export").show();
	} else {
		$("Wrapper_export").hide();
	}

	if (value == "" || value == "printbogforinger" || value == "printfakturaer" || value == "saft") $("Wrapper_print_pdf").hide();
	else $("Wrapper_print_pdf").show();

	if (value == "momsrapport" && ReportType != "momsrapport")
		SB_Input("FinancialReport[momsrapport][period]").activate();

	if (value == "saldoliste" || value == "kreditorliste" || value == "printbogforinger" || value == "fakturajournal" || value == "kontospecifikation" || value == "posteringsliste")
		SB_Lists["financial_report_list"].SetAllowHighlight("single");
	else if (value == "nogletalsrapport" || value == "saldobalance")
		SB_Lists["financial_report_list"].SetAllowHighlight("multiple");
	else SB_Lists["financial_report_list"].SetAllowHighlight(false);

	ReportType = value;
};

function SB_FinancialReportHighlight(vue_instance) {
	if (SB_Lists["financial_report_list"].ReportType == "kontospecifikation" || SB_Lists["financial_report_list"].ReportType == "posteringsliste") {
		if (this.Highlighted.length == 1) {
			vue_instance.post_id = this.Highlighted[0];
		} else {
			vue_instance.post_id = 0;
		}
	}
}

export var SB_FinancialReportEnter = function(event) {
	if (this.AjaxParams["FinancialReport[type]"] == "fakturajournal") {
		if (this.Highlighted.size() == 1) {
			SB_OpenInvoice(this.Serialize());
			return true;
		}
	} else if (this.AjaxParams["FinancialReport[type]"] == "printbogforinger") {
		if (this.Highlighted.size() == 1) {
			SB_OpenRecorded(this.Serialize());
			return true;
		}
	}

	return false;
};

export var export_file = function() {
	let export_without_vouchers = () => {
		if ([
				// 'nogletalsrapport',
				// 'saldobalance',
				// 'momsrapport',
				// 'budget',
				// 'saldoliste',
				// 'kreditorliste',
				// 'posteringsliste',
				// 'kontospecifikation',
				// 'fakturajournal',
				'printfakturaer',
				// 'printbogforinger',
				'saft',
			].indexOf(SB_Input('FinancialReport[type]').value()) !== -1
		) {
			// Disse exports kan ikke inkludere bilag, og det er derfor kun til forbirring at vi spørger om man vil have dem med.
			SB_MessageStack.Add("success", "Udlæser fil... Dette kan tage op til et par minutter.<br><br>Hvis du forlader siden inden udtrækket er færdigt, så får du en besked i din SkvizBiz-indbakke når udtrækket er klar.");
		} else {
			SB_MessageStack.Add("success", 'Udlæser fil... Dette kan tage op til et par minutter.');
		}
		new SB_Form("FinancialReportForm").AddParam(`action=GetList&FinancialReport[export]=1`).Submit();
	};
	if ([
			// 'nogletalsrapport',
			// 'saldobalance',
			'momsrapport',
			'budget',
			'saldoliste',
			'kreditorliste',
			// 'posteringsliste',
			// 'kontospecifikation',
			'fakturajournal',
			'printfakturaer',
			'printbogforinger',
			'saft',
		].indexOf(SB_Input('FinancialReport[type]').value()) !== -1
	) {
		// Disse exports kan ikke inkludere bilag, og det er derfor kun til forbirring at vi spørger om man vil have dem med.
		export_without_vouchers();
	} else {
		SB_Dialog({
			id: "confirm-" + SB_Timestamp(),
			title: "Bekræft venligst",
			text: "Ønsker du også at eksportere bilag",
			buttons: [
				{
					label: "Nej",
					fnc: export_without_vouchers,
					width: "175",
					// shortcut: "Esc",
				},
				{
					label: "Ja",
					className: "dialog-button-ok",
					fnc: () => {
						SB_MessageStack.Add("success", 'Udlæser fil... Dette kan tage op til et par minutter.');
						new SB_Form("FinancialReportForm")
							.AddParam("action=GetList&FinancialReport[export]=1&FinancialReport[include_vouchers]=1")
							.Submit();
					},
					shortcut: "Enter",
					width: "175",
				},
			],
		});
	}
};

var vue_instance = false;
export async function init(element, options) {
	let voucher_component = await import( /* webpackChunkName: "financial-report-list" */ "../../vue-components/pages/financial_report_list.vue");
	let vue_voucher_component = await Vue.extend(voucher_component.default);

	if (vue_instance) {
		return vue_instance;
	}

	vue_instance = new vue_voucher_component({
	}).$mount();

	vue_instance.report_type = typeof options.report_type !== "undefined" ? options.report_type : "";
	element.appendChild(vue_instance.$el);

	new SB_List("financial_report_list", "financial_report_list_ajax.php")
		.AddInput("input[name^='FinancialReport['], select[name^='FinancialReport[']")
		.AddEvent("dblclick", SB_FinancialReportEnter)
		.AddEvent('highlight', function() {SB_FinancialReportHighlight.bind(this)(vue_instance)})
		.Refresh()
		.SetContextBar("financial_report_list_options");
	SB_FinancialReportChangeType(SB_Input("FinancialReport[type]").value());
}
