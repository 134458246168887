/* global setImmediate SB_TouchDevice */

// @ts-ignore
import { Class, $$, $ } from "Prototype";
import {
	SB_Onload,
	CookieVar,
	SB_Timestamp,
	SB_CalcField,
	ResetChosenFocus,
	SB_UrlEncode,
} from "./common_functions.es6.js";
import { SB_Popup } from "./popup.es6.js";
import { SB_Lists } from "./list.es6.js";
import { SB_Ajax } from "./ajax.es6.js";
import { createPopper } from "../bootstrap-tooltip.es6";
import jQuery from "jquery";

/*
var ContextBarAdjustTimer = false;
export var ContextBarAdjust = function(timeout)
{
	if(!$$('.LayoutWrapper .LayoutBar')[0]) return;
	timeout = timeout || false;

	if (ContextBarAdjustTimer!==false)
		clearTimeout(ContextBarAdjustTimer);
	
	if (timeout)
		ContextBarAdjustTimer = setTimeout(__ContextBarAdjust,150);
	else
		__ContextBarAdjust();
};
var __ContextBarAdjust = function()
{
	var dimensions = document.viewport.getDimensions();
	var position = "fixed";
	
	var LayoutBar = $$('.LayoutWrapper .LayoutBar')[0];

	if (dimensions.width < 1000)
		position = "absolute";
	else
	{
		var LayoutBarHeight = LayoutBar.getHeight();

		if (dimensions.height < LayoutBarHeight + 35)
			position = "absolute";
	}

	LayoutBar.setStyle({'position' : position});
	if(position == "fixed")
		$(document.body).addClassName("LayoutBarFixed").removeClassName("LayoutBarAbsolute");
	else
		$(document.body).addClassName("LayoutBarAbsolute").removeClassName("LayoutBarFixed");
	
	ContextBarAdjustTimer = false;
};
window.onresize = function() {ContextBarAdjust(true);};
SB_Onload(ContextBarAdjust);
SB_Onload(function(){setInterval(ContextBarAdjust, 1000)});
*/

export var Collapsables = {
	Init : function()
	{
		$$('.collapsable').each(function(Box) {
			if (! $(Box).hasClassName('Initialized'))
			{
				var Trigger = Box.down();
				Trigger.observe("click",Collapsables.Click);

				Trigger.insert({'bottom' : "<div class='CollapsableToggleOn'>+</div><div class='CollapsableToggleOff'>-</div>"});

				var id;
				if ((id = Box.readAttribute("id")) && Box.hasClassName("persistent")) {
					if (CookieVar(id) !== null) {
						if (+CookieVar(id)) {
							Box.addClassName("expanded");
						} else {
							Box.removeClassName("expanded");
						}
					}
				}
				
				Box.addClassName("Initialized");
			}
		});
	},

	Click : function(/* event */)
	{
		var area = this.up(".collapsable");

		area.toggleClassName("expanded");

		var id; 
		if ((id = area.readAttribute("id")) && area.hasClassName("persistent")) {
			CookieVar(id, +area.hasClassName("expanded"))
		}
	}

/*	Expand : function(Trigger,area)
	{
		var tabicon = Trigger.down(".tabicon");
		if (tabicon)
			tabicon.update("-");

		Trigger.nextSiblings().invoke("show");

		area.addClassName("expanded");
	},

	Collapse : function(Trigger,area)
	{
		var tabicon = Trigger.down(".tabicon");
		if (tabicon)
			tabicon.update("-");

		Trigger.nextSiblings().invoke("show");

		area.addClassName("expanded");
	}*/
};
SB_Onload(Collapsables.Init);


/*
 ###      #            ##
 #  #                   #
 #  #    ##     ###     #     ##     ##    ####
 #  #     #    #  #     #    #  #   #  #   ##
 #  #     #    #  #     #    #  #   # ##     ##
 ###     ###    ####   ###    ##     # #   ####
                                       #
                                     ##
*/

export var SB_ConfirmPromise = function(msg, fnc_ok, fnc_cancel, skip_msg, label_ok, label_cancel) {
	return new Promise((resolve, reject) => {
		let promise_fnc_ok;
		if (fnc_ok) {
			promise_fnc_ok = ()=>{
				return resolve(fnc_ok())
			}
		} else {
			promise_fnc_ok = resolve;
		}
		let promise_fnc_cancel;
		if (fnc_cancel) {
			promise_fnc_cancel = ()=>{
				return reject(fnc_cancel())
			}
		} else {
			promise_fnc_cancel = reject;
		}
	
		SB_Confirm(msg, promise_fnc_ok, promise_fnc_cancel, skip_msg, label_ok, label_cancel)
	})
}
export var SB_Confirm = function(msg, fnc_ok, fnc_cancel, skip_msg, label_ok, label_cancel)
{
	fnc_ok = fnc_ok || function() {};
	fnc_cancel = fnc_cancel || function() {};

	label_ok = label_ok || "Bekræft";
	label_cancel = label_cancel || "Annuller";

	if (skip_msg === true)
	{
		fnc_ok();
		return true;
	}

	SB_Dialog({
		"id" 		: "confirm-"+SB_Timestamp(),
		"title" 	: "Bekræft venligst",
		"text"		: msg,
		"buttons"	: [
			{
				"label" : label_cancel,
				"fnc" : fnc_cancel,
				"shortcut" : "Esc",
			},
			{
				"label" : label_ok,
				"className" : "dialog-button-ok",
				"fnc" : fnc_ok,
				"shortcut" : "Enter",
			},
		]
	});
}

export var SB_Alert = function(msg,fnc_ok)
{
	fnc_ok = fnc_ok || function() {};
	
	// StackIndex = SB_Popup.StackIndex;
	SB_Popup.StackIndex += 1000;
	SB_Dialog({
		"id" 		: "alert-"+SB_Timestamp(),
		"title" 	: "Bemærk",
		"text"		: msg,
		"buttons"	: [
			{
				"label" : "OK",
				"className" : "dialog-button-ok",
				"fnc" : fnc_ok,
				"shortcut" : "Enter",
			},
		]
	});
	SB_Popup.StackIndex -= 1000;
}

export var SB_Prompt = async function(title, msg, value, options = {}) {
	return new Promise((resolve, reject)=>{
		let label_ok = "Gem";
		let label_cancel = "Annuller";
	
		let id = "prompt-"+SB_Timestamp();
	
		msg = `${msg}<br><br><input type="text" value="${value}" name="${id}" id="${id}_input" style="width:100%;" />`
	
		SB_Dialog({
			"id" 		: id,
			"title" 	: title,
			"text"		: msg,
			"buttons"	: [
				{
					"label" : label_cancel,
					"fnc" : ()=>{
						reject('cancelled');
					},
					"shortcut" : "Esc",
				},
				{
					"label" : label_ok,
					"className" : "dialog-button-ok",
					"fnc" : ()=>{
						let result = document.getElementById(`${id}_input`).value;
						resolve(result);
					},
					"shortcut" : "Enter",
				},
			],
			"onShow": ((typeof options.onShow === "function") ? options.onShow : false),
		});
	})
}

export var SB_Dialog = function(options)
{
	options = Object.extend({
		"id" 		: "dialog",
		"title" 	: false,
		"text" 		: false,
		"buttons" 	: [],
		"width" 	: 350,
		"onRemove"  : false,
		"onShow"    : false,
	}, options || {});
	
	var Popup = SB_Popup.Init(options.id);
	if (options['onRemove']!==false)
		Popup.AddEvent("onRemove",options['onRemove']);
	if (options['onShow']!==false)
		Popup.AddEvent("onShow",options['onShow']);
	//	if (options['class']!==false)
//		Popup.Wrapper.removeClassName('Popup').addClassName("dialog");
	if (options['width']!==false)
		Popup.Wrapper.setStyle({'width' : options['width']+"px"});
		
	Popup.Wrapper.addClassName("DialogPopup");
//	Popup.Update(new Element('div').addClassName('dialog-bg'));
	
	var DialogBody = new Element("div").addClassName("ContentWrapper Dialog");
	
	DialogBody.insert(
		new Element("span").setStyle({
			"position": "absolute",
			"top": "-9px",
			"right": "-9px",
			"width": "18px",
			"height": "18px",
			"background": "white",
			"line-height": "18px",
			"text-align": "center",
			"border-radius": "10px",
			"font-weight": "bold",
			"color": "gray",
			"font-size": "14px",
			"cursor": "pointer"
		})
		.update("&times;")
		.writeAttribute("onclick","SB_Popup.RemoveFromTop()")
	);

	if (options.title!==false)
		DialogBody.insert(new Element("h1").update(options.title));
	if (options.text!==false)
		DialogBody.insert(new Element("p").addClassName("PopupBody").update(options.text));
	
	if (options.buttons.size() > 0)
	{
		var btn;
		var DialogButtons = new Element("tr").addClassName("dialog-buttons");
		options.buttons.each(function(button) {
			button = Object.extend({
				"label"		: "OK",
				"className"	: "dialog-button",
				"fnc"		: function() {},
				"width" 	: false,
				"shortcut"  : false,
			}, button);
			
			btn = new Element("button").addClassName(button.className).update(button.label).observe("click",function() {SB_Popup.RemoveFromTop(true);}).observe("click",button.fnc);
			if (button['width']!==false)
				btn.setStyle({'width' : button['width']+"px"});

			if (button['shortcut']) {
				btn.writeAttribute("sb_shortcut", button['shortcut']);
			}
			
			var td = new Element("td").insert(btn);
			DialogButtons.insert(td);
			// DialogButtons.insert(btn);
		});
		
		var DialogTable = new Element("table").insert(DialogButtons).setStyle({width:"100%"});
		DialogBody.insert(DialogTable);
		// DialogBody.insert(DialogButtons);
	}
		
		
	Popup.Insert(DialogBody).Show();
}

export var SB_PopupAreas = {
	InitAll : function()
	{
		$$('.PopupArea').each(function(div) {
			if (! $(div).hasClassName('Initialized'))
				SB_PopupAreas.Init(div);
		});
	},
	
	Init : function(area)
	{
		if (area.hasClassName('Initialized')) {
			return;
		}

		var a = area.down();
		if (a.tagName.toLowerCase() == "a")
		{
			if (area.hasClassName('expandible'))
				a.observe('click',SB_PopupAreas.Click);

			if (a && typeof(a) != "undefined")
			{
				a.down('.tabicon').update('+');
				if (area.hasClassName('collapsed'))
				{
					// a.down('.tabicon').update('&times;');
					a.next().hide();
				}
				else
				{
					// a.down('.tabicon').update('&times;');
					a.next().show();
				}
			}
		}
		
		area.addClassName('Initialized');
	},
	
	Click : function(event)
	{
		var area = this.up('.PopupArea');
		
		if (area.hasClassName('animating'))
			return false;
		
		if (area.hasClassName('collapsed'))
			SB_PopupAreas.Expand(this);
		else
			SB_PopupAreas.Collapse(this);
	},
	
	Expand : function(a)
	{
		var area = a.up('.PopupArea');

		if (area.hasClassName("exclusive"))
		{
			$$(".PopupArea.exclusive:not(.collapsed)").each(function(other) {
				SB_PopupAreas.Collapse(other.down("a"));
			});
		}
		
		area.addClassName('animating').removeClassName('collapsed');
		// a.down('.tabicon').update('&times;');
		
		var content = a.next();
		animate_height_expand(content, ()=>{
			area.removeClassName('animating');
		})
	},
	
	Collapse : function(a)
	{
		var area = a.up('.PopupArea');
		
		area.addClassName('animating collapsed');
		// a.down('.tabicon').update('&times;');
		
		var content = a.next();
		// var width = a.getWidth();
		animate_height_collapse(content, ()=>{
			content.hide();
			area.removeClassName('animating');
		})
	}
}

export const animate_height_expand = function(element, callback = function(){}) {
	// Trick for at finde elementets korrekte bredde
	element.setAttribute('original-height', element.style.height);
	element.setStyle({'height' : '1px'}).show();
	let width = element.getWidth();
	element.setStyle({'width' : width+'px'});
	element.hide();
	element.setStyle({'height' : ''});
	var height = element.getHeight()-1;
	let childStyles = getComputedStyle(element.firstElementChild);
	// height += parseInt(childStyles.marginBottom)
	height += parseInt(childStyles.marginTop)
	element.setStyle({'height' : '0px'}).show();

	element.addEventListener("transitionend", function tmpExpand() {
		callback();
		element.setStyle({'width' : '', 'height' : ''});
		
		// Fix til IE, som har brug for at få triggered en redraw, for at være sikker på at hele indholdet vises.
		element.style['-ms-transform'] = 'translateZ(0)';
		setImmediate(function(){
			element.style['-ms-transform'] = "none";
		});
		element.removeEventListener("transitionend", tmpExpand);
	})
	getComputedStyle(element).transform;
	element.setStyle({height: height + 'px'});
}

export const animate_height_collapse = function(element, callback = function(){}) {
	let width = element.getWidth();
	element.setStyle({'width' : width+'px'});

	var height = element.getHeight()-1;
	
	element.setStyle({'height' : height + 'px'});
	element.addEventListener("transitionend", function tmpCollapse() {
		callback();
		element.setStyle({'width' : '', 'height' : element.getAttribute('original-height') ? element.getAttribute('original-height') : '' });
		element.removeEventListener("transitionend", tmpCollapse);
	});
	getComputedStyle(element).transform;
	element.setStyle({'height' : '0px'});
}

export var SB_Input = function(id) {
	var input = (typeof(id) == "string") ? $("input["+id+"]") : $(id);
	if (!input) return false;
	
	id = input.identify();
	
	if (typeof(SB_FormElements[id]) != "undefined" && SB_FormElements[id].Input.up("body"))
		return SB_FormElements[id];
	else
		return new SB_FormElement(input);
}
export var SB_FormElements = {};
export var SB_FormElement = Class.create({
	initialize : function(input)
	{
		this.Input = input;
		this.InputType = false;
		if (this.Input.hasClassName("input-select"))
			this.InputType = "select";
		else if (this.Input.hasClassName("custom-select"))
			this.InputType = "custom-select";
		else if (this.Input.hasClassName("input-textarea"))
			this.InputType = "textarea";
		else if (this.Input.hasClassName("input-search") || this.Input.hasClassName("input-text"))
			this.InputType = "text";
		else if (this.Input.hasClassName("input-checkbox"))
			this.InputType = "checkbox";
		
		this.FormElement = this.Input.up(".FormElement");
			
		SB_FormElements[this.Input.identify()] = this;
		
		return this;
	},
	
	onfocus : function()
	{
		// if (this.InputType != "select")
		// {
		// 	var sb_placeholder = this.Input.readAttribute('sb_placeholder');
		// 	if (this.Input.hasClassName('placeholder') || this.Input.value == sb_placeholder)
		// 	{
		// 		this.Input.removeClassName('placeholder');
		// 		if (this.Input.value == this.Input.readAttribute('sb_placeholder'))
		// 			this.Input.value = '';
		// 	}
		// }

		if (this.FormElement) {
			this.FormElement.addClassName('active');
		}
		
		if (this.Input.hasClassName("input-time"))
		{
			this.Input.select();
		}
		
		return this;
	},
	
	onchange : function()
	{
		if (this.InputType == "custom-select")
		{
			var span = this.Input.previous('.custom-select-text');
			var text = this.Input.options[this.Input.selectedIndex].text;

			span.update("<span>" + text + "</span>");
			
			if (this.Input.selectedIndex === -1) {
				// Måske ikke en holdbar løsning, men et quickfix så der ikke sker browserfejl. Næste linje skal måske ikke udkommenteres, men det kræver mere undersøgelse.
				// span.addClassName('placeholder');
			} else {
				if (span.hasClassName('placeholder') && !this.Input.options[this.Input.selectedIndex].hasClassName('placeholder'))
				{
					span.removeClassName('placeholder');
				}
				else if (!span.hasClassName('placeholder') && this.Input.options[this.Input.selectedIndex].hasClassName('placeholder'))
				{
					span.addClassName('placeholder');
				}
			}
		}
		else if (this.InputType == "select" && !this.Input.multiple)
		{
			if (this.Input.selectedIndex === -1) {
				// span.addClassName('placeholder');
			} else {
				if (this.Input.hasClassName('placeholder') && !this.Input.options[this.Input.selectedIndex].hasClassName('placeholder'))
				{
					this.Input.removeClassName('placeholder');
				}
				else if (!this.Input.hasClassName('placeholder') && this.Input.options[this.Input.selectedIndex].hasClassName('placeholder'))
				{
					this.Input.addClassName('placeholder');
				}
			}
		}
		
		if (this.FormElement) {
			this.FormElement.removeClassName("input-error");
		}

		let tokenized_search_field = this.Input.closest(".filtered-search-wrapper");
		if (tokenized_search_field) {
			this.Input.dispatchEvent(new Event('input')) 
			// tokenized_search_field.__vue__.input = this.Input.value;
		}
	},

	onkeydown: function (evt) {
		// eslint-disable-next-line compat/compat
		let platform = window.navigator.platform ? window.navigator.platform : (navigator.userAgentData ? navigator.userAgentData.platform : 'windows');
		if (this.InputType == "textarea" && evt.code == "Enter" && (evt.metaKey || (!/(Mac|iPhone|iPod|iPad)/i.test(platform) && evt.ctrlKey)) && evt.target && evt.target.form) {
			if (typeof evt.target.form.onsubmit === "function") {
				evt.target.form.onsubmit();
			} else {
				new SB_Form(evt.target.form).Submit();
			}
		}
	},
	
	onkeyup : function(evt)
	{
		if (this.InputType == "custom-select")
		{
			this.Input.onchange();
		}
		else if (this.InputType == "textarea" && this.FormElement.hasClassName("auto-resize") && !SB_TouchDevice) // går for langsomt på touch device)
		{
			if (typeof(this.AutoResizeMinHeight) == "undefined")
				this.AutoResizeMinHeight = (this.FormElement.style.height) ? (this.FormElement.style.height.replace("px","")-0) : 0;
		
			this.FormElement.setStyle({'height' : ''});
			this.Input.setStyle({'height' : '1px'}).setStyle({'height' : Math.max(this.AutoResizeMinHeight, this.Input.scrollHeight+5,this.FormElement.getHeight()-2)+'px'});
		}
	},
	
	onclick : function()
	{
	},
	
	onblur : function()
	{
		this.FormElement.removeClassName("input-error");
		this.FormElement.removeClassName('active');

		var calc;
		if (null !== (calc = this.Input.readAttribute("calc-input"))) {
			if (calc == "same_decimals" || !calc) {
				if (this.Input.value.match(/[^\d,.]/)) {
					let tmp = SB_CalcField(this.Input.value, "avoid");
					if (tmp) {
						this.Input.value = tmp;
					}
				}
			}
		}

		if(this.FormElement.hasClassName("calc-input") && this.Input.value)
		{
			let tmp = SB_CalcField(this.Input.value);
			if (tmp) {
				this.Input.value = tmp;
			}
		}
		
		// if (this.InputType == "text" || this.InputType == "textarea")
		// {
		// 	var sb_placeholder = this.Input.readAttribute('sb_placeholder');
		// 	if (this.Input.value == "" || this.Input.value == sb_placeholder)
		// 		this.update("");
		// }
	},
	
	onselectstart : function()
	{
		if (typeof(window.event)!="undefined")
		window.event.cancelBubble = true;
		
		return true;
	},
	
	enable : function(enabled)
	{
		enabled = (enabled === false) ? false : true;
		
		if (enabled)
		{
			this.Input.enable();
			this.FormElement.removeClassName('disabled');
		}
		else
		{
			this.Input.blur();
			this.Input.disable();
			this.FormElement.addClassName('disabled');
		}

		if (this.FormElement.hasClassName("chosen-select"))
			Event.fire(this.Input, "chosen:updated");
		
		return this;
	},
	
	disable : function()
	{
		this.enable(false);
		
		return this;
	},
	
	check : function()
	{
		this.Input.checked = true;
		
		return this;
	},
	
	uncheck : function()
	{
		this.Input.checked = false;
		
		return this;
	},
	
	activate : function()
	{
			ResetChosenFocus();

		if (this.InputType == 'text' || this.InputType == 'textarea')
		{
			
			this.Input.activate();
		}
		else
		{
			if(this.Input.Chosen)
			{
				let tmp = this.Input;
//				this.Input.focus();
// Forsinkes for ikke at komme op at slås med ResetChosenFocus
				setTimeout(function() {
					tmp.fire("chosen:activate");
				},40);
			}
			else
			{
				
				this.Input.focus();
			}
		}

		
		return this;
	},
	
	blur : function()
	{
		this.Input.blur();
	},
	
	update : function(value)
	{
		// var sb_placeholder = this.Input.readAttribute('sb_placeholder');
		// if (value == sb_placeholder)
		// 	value = "";
		
		// if (!this.FormElement.hasClassName('active') && value === "")
		// {
		// 	value = this.Input.readAttribute('sb_placeholder') || ""; 
			
		// 	this.Input.addClassName('placeholder').value = value;
		// }
		// else
		// {
		// 	this.Input.removeClassName('placeholder').value = value;
		// }
		
		this.Input.value = value;

		if (this.FormElement && this.FormElement.down('.lock-text')) {
			this.FormElement.down('.lock-text').update("<span>" + value + "</span>");
		}
		
		if (typeof this.onchange === "function") {
			this.onchange();
		}
		
		if (this.FormElement && this.FormElement.hasClassName("chosen-select")) {
			Event.fire(this.Input, "chosen:updated");
		}
		
		return this;
	},
	
	show : function()
	{
		this.FormElement.show();
		
		return this;
	},
	
	hide : function()
	{
		this.FormElement.hide();
		
		return this;
	},

	toggle: function(on) {
		if (on) {
			this.show();
		} else {
			this.hide();
		}
		return this;
	},
	
	changeLabel : function(value)
	{
		var label = this.FormElement.down('label');
		if (label)
			label.update(value);

		if (this.FormElement.hasClassName("chosen-select"))
			Event.fire(this.Input, "chosen:updated");
			
		return this;
	},
	
	unlock : function()
	{
		if (this.FormElement.hasClassName('lock'))
		{
			this.FormElement.writeAttribute("onclick","")
				.removeClassName('lock');
			
			this.enable();

// Unødvendig, da enable() selv opdaterer
//			if (this.FormElement.hasClassName("chosen-select"))
//				Event.fire(this.Input, "chosen:updated");
//			else
				this.activate();
		}
		
		return this;
	},
	
	ucfirst : function()
	{
		if (this.Input.value != ""/* && !this.Input.hasClassName('placeholder')*/)
		{
			this.Input.value = (this.Input.value + '').charAt(0).toUpperCase() + (this.Input.value + '').substr(1);
			
		}
		
		return this;
	},
	
	hasValue : function()
	{
		return (this.Input.value != ""/* && !this.Input.hasClassName('placeholder')*/);
	},
	
	checked : function()
	{
		return this.Input.checked;
	},
	
	value : function()
	{
		return this.Input.value;
		// return (!this.Input.hasClassName('placeholder')) ? this.Input.value : "";
	},

	select : function(name)
	{
		for ( var i = 0; i < this.Input.options.length; i++ ) {
			if ( this.Input.options[i].value == name ) {
				this.Input.options[i].selected = true;
			}else{
				this.Input.options[i].selected = false;
			}
		}
		Event.fire($(this.Input), "chosen:updated");
		return this;
	}
});

export var SB_FormSubmitting = false;
export var SB_Form = Class.create({
	
	initialize : function(form)
	{
//		this.buttons = [];
		
		if (form !== null && form)
		{	this.url = $(form).readAttribute('action');
			this.params = $(form).serialize();
			
//			this.buttons = $(form).select("input[type='submit']");
		}
		else
		{
			this.url = false;
			this.params = "";
		}

		this.WaitForOtherFormsSubmitting = false;
		
		return this;
	},
	
	SetUrl : function(url)
	{
		this.url = url;
		
		return this;
	},
	
	AddParam : function(param)
	{
		this.params = (this.params == "") ? param : this.params + "&" + param;
		
		return this;
	},
	
	AddForm : function(form)
	{
		if ($(form))
		{
			this.AddParam($(form).serialize());
		}
		
		return this;
	},
	
	AddList : function(list)
	{
		this.AddParam('Highlighted='+SB_Lists[list].Serialize());
		
		return this;
	},
	
	AddSelector : function(rule)
	{
		$$(rule).each((function(input) {
			if (input.disabled)
				return false;
				
			let tmp = SB_Input(input);
			if (tmp.InputType == "checkbox" || tmp.InputType == "radio")
			{
				if (input.checked)
				{
					if (input.readAttribute('value'))
						this.AddParam( input.readAttribute('name')+"="+SB_UrlEncode(input.readAttribute('value')) );
					else
						this.AddParam( input.readAttribute('name')+"=1");
				}
			}
			else if (input.InputType !== false)
			{
				this.AddParam( input.readAttribute('name')+"="+SB_UrlEncode(tmp.value()));
			}
		}).bind(this));
		
		return this;
	},
	
/*	AddButton : function(button)
	{
		this.buttons.push(button);
		
		return this;
	},
	*/
/*	Serialize : function(rule)
	{
		var Serialized = "";
		
		$$(rule).each(function(input) {
			if (Serialized != "")
				Serialized = Serialized + "&";
			
			Serialized = Serialized + input.readAttribute('name') +"="+ SB_Input(input).value();
		});
		
		return Serialized;
	},*/

/*	WaitForOtherForms : function()
	{
		this.WaitForOtherFormsSubmitting = true;

		return this;
	},*/
	
	Serialize : function()
	{
		return this.params;
	},

	
	Submit : function()
	{
//		this.buttons.invoke("addClassName","submitting");

		if (SB_FormSubmitting === false)
		{
			SB_FormSubmitting = true;
			return new SB_Ajax(this.url,this.params).AddEvent("onComplete",function() {SB_FormSubmitting=false;}).Request().finally(lda(typeof event !== "undefined" ? event : null));
		}
		return Promise.reject("Already submitting");
/*		else if (this.WaitForOtherFormsSubmitting)
		{
			setTimeout(function() {this.Submit();}.bind(this),100);
		}*/
	}
	
});

import * as Ladda from "ladda";

var lda = function(e) {
	let el = findClickedElement(e);
	if (el) {
		el = el.attr("data-style","slide-left")[0];
		if (el.tagName == "INPUT") {
			let newHtml = jQuery(el.outerHTML.replace(/^<input/i, "<button").replace(/\?>$/, ">")+el.getAttribute("value")+"</button>");
			jQuery(el).replaceWith(newHtml);
			el = newHtml[0];
		}
		let l = Ladda.create(el);
		l.start();
		var t = new Date();
		// console.log("Started", el);
		return function() {
			setTimeout(function(){
				l.stop();
				setTimeout(function(){
					l.remove();
				}, 300)
				// console.log("Stopped", el);
			}, Math.max(300-((new Date())-t), 50));
		}
	} else {
		return function(){};
	}
}

var findClickedElement = function(e) {
	var el;
	if (!e || e.type == "submit") {
		el = jQuery(":submit:focus, :button:focus");
		if (el.length) {
			// console.log("By focus");
		} else {
			// console.log("No result");
			return false;
		}
	} else {
		el = jQuery(e.target);
		if (el.is(":button")) {
			return el;
		} else if ((el = el.closest(":button")) && el.length) {
			return el;
		} else {
			return false;
		}
		// console.log("By target");
	}
	// console.log(el);
	return el.length ? el : false;
}


import autosize from "./autosize.js";

SB_Onload(function(){
	function whichTransitionEvent() {
		return "transitionend";

		/* var t,
			el = document.createElement("fakeelement");

		var transitions = {
			"transition"      : "transitionend",
			"OTransition"     : "oTransitionEnd",
			"MozTransition"   : "transitionend",
			"WebkitTransition": "webkitTransitionEnd"
		}

		for (t in transitions){
			if (el.style[t] !== undefined){
				return transitions[t];
			}
		} */
	}
	var transitionEvent = whichTransitionEvent();

	jQuery(document).on("focus", "textarea[autosize]", function() {
		var self = this;
		var el = jQuery(self);
		var origHeight = el[0].style.height;
		el[0].style.height = el.css("height");
		var calcedHeight = el[0].style.height;

		autosize(self);
		el.closest(".textarea").addClass("autosized");
		el.one(transitionEvent,function(/* evt */){
			el[0].style.transition = "initial";
		});
		
		el.one("blur", function() {
			el[0].style.transition = "";
			el[0].style.height = calcedHeight;
			autosize.destroy(self);
			// webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend
			// webkitAnimationEnd oanimationend msAnimationEnd animationend
			el.closest(".textarea").removeClass("autosized");
			el.one(transitionEvent,function(/* evt */){
				if (el.is(":focus")) {
					return;
				}
				el[0].style.height = origHeight;
			});
		})
	});
});

jQuery(document).on("click", ".has-submenu", function(/* evt */) {
	var ref = jQuery(this);
	var el = ref.attr("data-popper-el") ? jQuery(ref.attr("data-popper-el").replace( /(:|\.|\[|\]|,|=)/g, "\\$1" )) : ref.find(".submenu");
	if (el.is(":visible")) {
		if (el.hasClass("has-submenu")) {
			return false;
		}
	} else {
		el.data(
			"popper",
			createPopper(ref[0], el[0], {
				// @ts-ignore
				placement: el.attr("data-placement") ? el.attr("data-placement") : "left-start",
				strategy: "fixed",
				modifiers: [
					{
						name: 'preventOverflow',
						options: {
							boundary: el.attr("data-boundaries-element")
								? (
									~['scrollParent', 'window', 'viewport'].indexOf(el.attr("data-boundaries-element"))
										? el.attr("data-boundaries-element")
										: el.closest(el.attr("data-boundaries-element"))[0]
								)
								: "viewport",
							// enabled: false,
						},
					// }, {
					// 	name: 'hide',
					// 	options: {
					// 		// enabled: false,
					// 	},
					}, {
						name: 'offset',
						options: {
							offset: [-5, 5], // "0px,5px",
						},
					},
				],
			})
		);
		setImmediate(function() {
			el.data("popper").update();
			el.show();
			jQuery(document).on("click", function removeSubmenu(evt) {
				if (!el.has(evt.target).length) {
					el.hide();
					el.data("popper").destroy();
					jQuery(document).off("click", removeSubmenu);
				}
			});
		});
		// debugger;
	}
});


import { Tooltip } from "../bootstrap-tooltip.es6.js";

/**
 * 
 * @param {HTMLElement} el 
 * @returns {Tooltip}
 */
const getVariableValidationTooltipForElement = (el) => {
	if (typeof el.$variable_input_validation === "undefined") {
		el.$variable_input_validation = new Tooltip(el, {
			placement: 'bottom',
			container: 'body, .Popup',
			trigger: 'manual',
			html: true,
			title: 'Det ser ud til at der bruges ugyldige variabler i dette felt',
			popperOptions: {
				modifiers: {
					preventOverflow: {
						boundariesElement: 'viewport',
						priority: ['right','left','bottom','top'],
					},
				},
			},
		});

		let tmp_interval = setInterval(() => {
			if (!document.contains(el)) {
				el.$variable_input_validation.destroy();
				clearInterval(tmp_interval);
			}
		}, 100);
	}

	return el.$variable_input_validation;
}

jQuery(document).on("keyup", "[data-has-variable-hints]", (evt) => {
	let el = evt.target;
	let problematic = validate_variable_hints(el);
	let variable_input_validation = getVariableValidationTooltipForElement(el);
	if (el.value && problematic.length) {
		variable_input_validation.setContent('Det ser ud til at der bruges ugyldige variabler i dette felt (' + (problematic.join(", ")) + ')');
		$(el).up().addClassName('client-side-input-error'); variable_input_validation.show()
	} else {
		$(el).up().removeClassName('client-side-input-error'); variable_input_validation.hide()
	}
})
jQuery(document).on("blur", "[data-has-variable-hints]", (evt) => {
	let el = evt.target;
	let problematic = validate_variable_hints(el);
	let variable_input_validation = getVariableValidationTooltipForElement(el);
	if (el.value && problematic.length) {
		$(el).up().addClassName('client-side-input-error'); variable_input_validation.hide()
	} else {
		$(el).up().removeClassName('client-side-input-error'); variable_input_validation.hide()
	}
})
jQuery(document).on("focus", "[data-has-variable-hints]", (evt) => {
	let el = evt.target;
	let problematic = validate_variable_hints(el);
	let variable_input_validation = getVariableValidationTooltipForElement(el);
	if (el.value && problematic.length) {
		variable_input_validation.setContent('Det ser ud til at der bruges ugyldige variabler i dette felt (' + (problematic.join(", ")) + ')');
		$(el).up().addClassName('client-side-input-error'); variable_input_validation.show()
	} else {
		$(el).up().removeClassName('client-side-input-error'); variable_input_validation.hide()
	}
})

const validate_variable_hints = (el) => {
	let hints = el.dataset.hasVariableHints;
	let allowed_keys = hints.split(",").map(hint => {
		let matches = hint.match(/{{\s*(\S+)\s*(?:(?:\|\s*)(.*\b)(?:\s*)?)?}}/);
		if (matches && matches[1]) {
			return matches[1].toLowerCase();
		}
		return false;
	}).filter(m=>m!==false);

	let used = el.value.match(/{{\s*([^|}]+)\s*(?:(?:\|\s*)(.*\b)(?:\s*)?)?}}/gi)
	if (used) {
		used = used.map(hint => hint.match(/{{\s*([^|}]+)\s*(?:(?:\|\s*)(.*\b)(?:\s*)?)?}}/i)[1].trim());
		let problematic = used.filter(key => (allowed_keys.indexOf(key.toLowerCase()) == -1));
		return problematic;
	}

	return [];
}