<script>
	export default {
		name: "transition-group-expand",
		functional: true,
		props: ['tag', 'appear'],
		render: function (createElement, context) {
			var data = {
				props: {
					name: 'transition-group-expand',
					mode: 'out-in',
					tag: context.props.tag,
					appear: context.props.appear,
				},
				on: {
					beforeEnter: function (el) {
						// console.log(el);
						var tmp = jQuery(el).clone(false);
						tmp.css({
							visibility: 'hidden',
							position: 'absolute',
						})
						tmp.insertAfter(jQuery(context.parent.$el));
						jQuery(el).css("height", tmp.css("height"));
						// jQuery(el).height(tmp.height());
						tmp.remove();
					},
					afterEnter: function (el) {
						// console.log(el);
						jQuery(el).css({
							height: ""
						});
					},
					beforeLeave: function (el) {
						jQuery(el).css("height", jQuery(el).css("height"));
						// jQuery(el).height(jQuery(el).height());
						return;
					},
					afterLeave: function (el) {
						// console.log(el);
						jQuery(el).css({
							height: ""
						});
					},
				},
			};
			return createElement('transition-group', data, context.children)
		}
	};
</script>
