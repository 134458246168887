/* global SMS_points, SMS_autobuy */

import { __substr_count } from "./common_functions.es6.js";
import { SB_Popup } from "./popup.es6.js";
import { SB_Alert } from "./ui_elements.es6.js";

export var SB_SmsShortcut = function (event) {
	if (event.altKey && event.keyCode == 83) {
		if (SB_CheckSMSPoints()) {
			SB_Popup.Init('sms_create').Ajax('sms_create.php', '').Request();
		}

		event.Stopped = true;
		event.stop();
	}
}

export var SB_CheckSMSPoints = function (min) {
	let points = SMS_points || 0;
	let autobuy = SMS_autobuy || 0;
	if (autobuy) {
		return true;
	}
	min = min || 1;
	if (points < min) {
		let msg = points != 0 ? "Du har ikke nok SMS-point til denne transaktion." : "Du har ikke flere SMS-point.";
		SB_Alert(msg + "<br><br>Du kan købe flere <span style='cursor:pointer; font-weight: 700; text-decoration: underline;' onclick=\"SB_Popup.Init('sms_buy').Ajax('sms_list_buy.php').Request(); SB_Popup.RemoveFromTop();\">her</span>.", function () { SB_Popup.Init('sms_buy').Ajax('sms_list_buy.php').Request() });
		return false;
	}
	return true;
}

export var SB_SmsCounter = function (msg, target) {
	var vars = (
		msg.indexOf("%kundenavn%") !== -1 ||
		msg.indexOf("%dagsdato%") !== -1 ||
		msg.indexOf("%fakturadato%") !== -1 ||
		msg.indexOf("%opgaver%") !== -1 ||
		msg.indexOf("%saldo%") !== -1 ||
		msg.indexOf("%forfaldensaldo%") !== -1 ||
		msg.indexOf("%betalingsid%") !== -1 ||
		msg.match(/{{\s*([^|}]+)\s*(?:(?:\|\s*)(.*\b)(?:\s*)?)?}}/gi)
	);

	let len = GetGsmStrLen(msg);
	let count = CalculateChainLength(msg);
	let count_text = count == 1 ? "SMS" : "SMS'er"

	$(target).update(len + " tegn" + ` (${count} ${count_text})` + ((vars) ? " + variabler" : ""));
};

/**
 * Tæl antal tegn i en SMS.
 * @param {string} msg Teksten der skal beregnes længden på
 * @returns {int}
 */
function GetGsmStrLen(msg) {
	msg = msg.replace("%kundenavn%", "")
		.replace("%dagsdato%", "")
		.replace("%fakturadato%", "")
		.replace("%opgaver%", "")
		.replace("%saldo%", "")
		.replace("%forfaldensaldo%", "")
		.replace("%betalingsid%", "")
		.replace(/{{\s*([^|}]+)\s*(?:(?:\|\s*)(.*\b)(?:\s*)?)?}}/gi, "")
	;

	let len = msg.length;
	len += __substr_count(msg, "^");
	len += __substr_count(msg, "{");
	len += __substr_count(msg, "}");
	len += __substr_count(msg, "[");
	len += __substr_count(msg, "]");
	len += __substr_count(msg, "~");
	len += __substr_count(msg, "\\");
	len += __substr_count(msg, "|");
	len += __substr_count(msg, "\n");

	return len;
}


export function CalculateChainLength(contents) {
	let len = GetGsmStrLen(contents);
	
	let HasSpecialChars = hasSpecialChars(contents);
	let smses = 1;
	while (len > getCharacterLimit(HasSpecialChars, smses)) {
		smses++;
	}
	return smses;
}

function hasSpecialChars(str) {
	return (str != str.replace(/[^\@\£\$\¥\è\é\ù\ì\ò\Ç\Ø\ø\Å\å\_\^\{\}\\[\~\]\|\Æ\æ\ß\É\!\\\"\#\¤\%\&\\\'\(\)\*\+\,\-\.\/0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§\¿ abcdefghijklmnopqrstuvwxyzäöñüà \r\n]/g,""));
}

// function highlightSpecialChars($str) {
// 	return preg_replace("/([^\@\£\$\¥\è\é\ù\ì\ò\Ç\Ø\ø\Å\å\_\^\{\}\\[\~\]\|\Æ\æ\ß\É\!\\\"\#\¤\%\&\\\'\(\)\*\+\,\-\.\/0123456789:;<=>?\!ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖNÜ§\¿ abcdefghijklmnopqrstuvwxyzäönüa \r\n]+)/u",'<span bgcolor="yellow" style="background-color:yellow; color: black;">${1}</span>',$str);
// }

// function listSpecialChars($str) {
// 	return mb_eregi_replace("[\@\£\$\¥\è\é\ù\ì\ò\Ç\Ø\ø\Å\å\_\^\{\}\\[\~\]\|\Æ\æ\ß\É\!\\\"\#\¤\%\&\\\'\(\)\*\+\,\-\.\/0123456789:;<=>?\!ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖNÜ§\¿ abcdefghijklmnopqrstuvwxyzäönüa \r\n]+","",$str);
// }

function getCharacterLimit(HasSpecialChars, max_chain) {
	if (!HasSpecialChars) {
		if (max_chain == 1) {
			return 160;
		} else {
			return (max_chain * 153) + 7;
		}
	} else {
		if (max_chain == 1) {
			return 70;
		} else {
			return (max_chain * 67);
		}
	}
}

/**
 * 
 * @param {string} cell Telefonnummer der skal tjekkes
 */
export function validate_cellphone_number(cell) {
	let phone = cell.replace(/\D/, "");
	let regex = /((2[0-9]|3[01]|4[012]|5[0-3]|6[01]|[78]1|9[123])[0-9]|(34[24-9]|35[679]|36[256]|389|398|4[34]1|46[268]|47[2468]|48[5689]|49[3-689]|54[235]|55[126]|57[1-479]|58[4679]|59[78]|62[79]|64[19]|658|66[2-57]|69[2-47]|77[12]|78[235689]|82[679]))[0-9]{5}/;
	return !!phone.match(regex);
}