import {SB_Onload, SB_Timestamp} from "./common_functions.es6.js";
import { SB_Input } from "./ui_elements.es6";

export var SB_MessageStack = {
	Initializing : true,
	
	Init : function() 
	{
		var key = false;
		var entries = [];
		for (var i = 0; i < sessionStorage.length; i++){
			key = sessionStorage.key(i);
			
			if (key.substring(0,13) == "MessageStack[")
			{
				entries.push(sessionStorage.getItem(key).evalJSON());
				sessionStorage.removeItem(key);
			}
		}
		
		entries.each(function(entry) {
			var expiry = (entry.expires == -1) ? -1 : (entry.timestamp-SB_Timestamp())*1000 + (entry.expires-0);
			if (expiry > 0 || entry.expires == -1)
				SB_MessageStack.Add(entry.type, entry.msg, entry.timestamp, expiry);
		});
		
		SB_MessageStack.Initializing = false;

	},
	
	Add : function(type, msg, timestamp, expires, inputs)
	{
		timestamp = timestamp || SB_Timestamp();
		expires = expires || false;
		inputs = inputs || [];
		
		var MsgID = new Date().getTime();
		
		// window.scrollTo(0,0);
		
		var root_element = $('MessageStack');
		if (!root_element) {
			let body_container = document.querySelector("#body-container");
			if (!body_container) {
				// alert(msg.replace(/<br \/>/g,"\n").replace(/<br \/>/g,"\n"));
				return;
			}
			root_element = document.createElement("div");
			root_element.id = "MessageStack";
			body_container.appendChild(root_element);
			root_element = $(root_element);
		}


		var tmp = root_element.childElements();
		if (tmp.size() > 0)
		{
			tmp.each(function(el) {if (el.readAttribute("timestamp") != timestamp && el.readAttribute("timestamp") != timestamp-1 ) SB_MessageStack.Remove(el);});
		//	setTimeout(function() {SB_MessageStack.Add(type, msg);}, 300);
		//	return;
		}
		
		var icon;
		switch (type) {
			case "error":
				icon = "exclamation-triangle";
			break;
			case "notice":
				icon = "info-circle";
			break;
			case "success":
				icon = "check-circle";
			break;
			default:
				icon = type;
			break;
		}

		var div = new Element('div')
			.addClassName('message-stack '+type+' out')
			.writeAttribute("timestamp",timestamp)
			.observe("click",function() {SB_MessageStack.Remove(div);})
			.update("<div><div class='symbol'><i class='fa fa-"+icon+"'></i></div><div class='message'>"+msg+"</div><div class='close'><i class='fa fa-close'></i> Klik for at lukke denne meddelelse</div></div>")
		;
		
		$('MessageStack').insert({'bottom' : div}).show();
		
		var Height = div.getHeight() - 1;
		
		if (!SB_MessageStack.Initializing)
		{
			// div.setStyle({'height' : '0px'}).show();
			
			// new Effect.Morph(div, {'style' : {'height' : Height + 'px'}, 'duration' : 0.5, afterFinish : function() {div.setStyle({'height' : 'auto'}); }, queue: { position: 'end', scope: 'MessageStack' } } );
		}
		else
		{
			// div.setStyle({'height' : 'auto'}).show();
		}
		setTimeout(function(){
			div.removeClassName("out");
		},0);

		div.removing = false;
		div.MsgID = MsgID;

		$$('.input-error').invoke("removeClassName","input-error");
		inputs.each(function(input) {
			var i = SB_Input(input);
			if (!i)
				return false;
			
			i.FormElement.addClassName("input-error");
			jQuery(i.Input).one("change blur", function(){
				i.FormElement.removeClassName("input-error");
			});
		});
		
		if (expires != false)
		{
			if (expires != -1)
			{
				setTimeout(function() {SB_MessageStack.Remove(div);},expires);
			}
		
			var store = {
				"type" 		: type,
				"msg" 		: msg,
				"timestamp" : timestamp,
				"expires" 	: expires
			};
			
			sessionStorage.setItem("MessageStack["+MsgID+"]",Object.toJSON(store));
		}
		
		/*
		var OriginalHeight = $('MessageStack').getHeight();
		$('MessageStack').setStyle({'height' : OriginalHeight + "px"}).show();
		
		var div = new Element('div',{}).update("Test");
		$('MessageStack').insert({'bottom' : div});
		
		var NewHeight = $('MessageStack').scrollHeight;
		new Effect.Morph('MessageStack', {'style' : {'height' : NewHeight + 'px'}, 'duration' : 2 });*/
	},
	
	Reset : function() 
	{
		$('MessageStack').childElements().each(function(el) {SB_MessageStack.Remove(el);});
	},
	
	Remove : function(element)
	{
		if (!element || element.removing == true)
			return false;
			
		element.removing = true;
		element.stopObserving();

		element.addClassName("out");
		setTimeout(function(){element.remove()}, 300);
				
		sessionStorage.removeItem("MessageStack["+element.MsgID+"]");
	}
};
SB_Onload(SB_MessageStack.Init);

export function SB_handleErrorResponse(response) {
	let message_count = 0;
	if (response.success && response.success.length) {
		let msg = response.success.map(val=>val.message).join("<br />");
		let expiry = -1;
		response.success.forEach(function(val){
			if (val.expiry !== false && val.expiry != -1 && (val.expiry > expiry || val.expiry == -1)) {
				expiry = val.expiry;
			}
		});
		SB_MessageStack.Add('success', msg, false, expiry);
		message_count += response.success.length;
	}
	if (response.notice && response.notice.length) {
		let msg = response.notice.map(val=>val.message).join("<br />");
		let expiry = -1;
		response.notice.forEach(function(val){
			if (val.expiry !== false && val.expiry != -1 && (val.expiry > expiry || val.expiry == -1)) {
				expiry = val.expiry;
			}
		});
		SB_MessageStack.Add('notice', msg, false, expiry);
		message_count += response.notice.length;
	}
	if (response.error && response.error.length) {
		let msg = "Der opstod følgende fejl:<br />" + response.error.map(val=>val.message).join("<br />");
		let expiry = -1;
		let inputs = [];
		response.error.forEach(function(val){
			if (val.expiry !== false && val.expiry != -1 && (val.expiry > expiry || val.expiry == -1)) {
				expiry = val.expiry;
			}
			if (val.input.length) {
				val.input.forEach(input=>{
					inputs.push(input);
				})
			}
		});
		SB_MessageStack.Add('error', msg, false, expiry, inputs);
		message_count += response.error.length;
	}

	return message_count;
}

export function SB_handleJsonResponse(response) {
	if (response.status == "success") {
		return response.response;
	} else {
		SB_handleErrorResponse(response.response)
		throw new Error("Fejl");
	}
}