(function($,undefined) {

	$.widget('ui.sortable', $.ui.sortable,{
		setOrder: function(order) {
			var self = this;
			$(order).each(function(i, id){
				jQuery(self.element).children("[rowid='"+id+"']").remove().appendTo(self.element);
			})
			if(typeof self.options.update === "function")
				self.options.update();
			self.element.trigger("sortupdate");
		},
		setOrderStable: function(order) {
			let prevElement = jQuery(this.element).children("[rowid='"+order[0]+"']");
			if (prevElement.length) {
				order.forEach((id, index, arr) => {
					if (index === 0) {
						return;
					}
					let currentElement = jQuery(this.element).children("[rowid='"+id+"']").remove();
					if (currentElement.length) {
						currentElement.insertAfter(prevElement);
						prevElement = currentElement;
					}
				});
			}

			if (typeof this.options.update === "function") {
				this.options.update();
			}
			this.element.trigger("sortupdate");
		},
		getOrder: function() {
			return this.toArray({attribute: "rowid"});
		},
	});

	$.extend($.ui.sortable,{version:'v0.1'});
 })(jQuery);

function jq(id) {
	return jQuery('#' + id.replace( /(:|\.|\[|\]|,|=)/g, "\\$1" ));
}

(function() {
var re = /([^&=]+)=?([^&]*)/g;
var decodeRE = /\+/g;  // Regex for replacing addition symbol with a space
var decode = function (str) {return decodeURIComponent( str.replace(decodeRE, " ") );};
jQuery.parseParams = function(query) {
    var params = {}, e;
    while ( e = re.exec(query) ) { 
        var k = decode( e[1] ), v = decode( e[2] );
        if (k.substring(k.length - 2) === '[]') {
            k = k.substring(0, k.length - 2);
            (params[k] || (params[k] = [])).push(v);
        }
        else params[k] = v;
    }
    return params;
};
})();

function swapElements(a, b) {
	// create a temporary marker div
	var aNext = jQuery('<div>').insertAfter(a);
	a.insertAfter(b);
	b.insertBefore(aNext);
	// remove marker div
	aNext.remove();
}

module.exports = { jq }