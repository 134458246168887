/* global $$ */

import { Class } from "Prototype";
import { Cookie } from "./common_functions.es6";
import { Tooltip } from "../bootstrap-tooltip.es6.js";
import { SB_Input } from "./ui_elements.es6";
import 'tippy.js/animations/shift-toward.css';
import { SB_Ajax } from "./ajax.es6.js";

var Tour = Class.create({
	initialize: function(object){
		this.tips = [];
		this.activeTip = 0;
		this.started = false;
//		this.status = "inactive";

		return this;
	},
	
	tip: Class.create({
		initialize: function(obj){
			Object.extend(this,obj);
			
			
			return this;
		},
		
		show: function(){
			if(this.guided_tour == "1" && !Cookie.get("guided_tour"))
				return this;

			if(this.guided_tour == "1")
				Cookie.set("guided_tour",true);

			if(this.guided_tour == "end")
				Cookie.set("guided_tour",null);

			if(this.type == "billboard")
			{
				if(!$("billboard"))
				{
					var billboard = new Element("div").writeAttribute("id","billboard").setStyle({
						position: "fixed",
						top: "0px",
						bottom: "0px",
						left: "0px",
						right: "0px",
						background: "rgba(255,255,255,0.85)",
						zIndex: 5000,
						"text-align": "center",
						"padding-top": "180px",
						opacity: 0,
						"transition": "opacity 1s ease-in-out"
					})/*.observe("click",function(){tour.next();})*/;
					$(document.body).insert({
						top: billboard
					});
				}

				setTimeout(function(){($("billboard").show().style.opacity = 1)}, 0);
				var buttons;
				if(this.guided_tour == "start")
					buttons = "<br><br><a class='Button Blue' onclick='tour.next(); Cookie.set(\"guided_tour\", true);' style='margin:10px;'>Start rundvisningen</a><a class='Button' onclick='tour.later()' style='margin:10px;'>Vent til senere</a>";
				else
					buttons = "<br><br><a class='Button Blue' onclick='tour.next();' style='margin:10px;'>Forstået</a>";
				$("billboard").update(this.message + buttons);
				return this;
			}

			var previous_button = (tour.tips[tour.activeTip-1] ? "<a class='button' onclick='tour.previous()'>Gå tilbage</a>" : "");
//			var next_button = "<a class='button' onclick='tour.next()'>" + (tour.tips[tour.activeTip+1] ? "N&aelig;ste" : "Slut") + "</a>";
			var next_button = "<a class='button' onclick='tour.next()'>" + (["Tak!","Smart!","Forstået","Videre","Okay"][Math.floor(Math.random()*4)]) + "</a>";
			var message;
			if(!this.guided_tour)
				message = this.message + "<br><br>" + previous_button + next_button + "<a class='button' onclick='tour.later()'>Se senere</a>";
			else
				message = this.message;
				
			// Opentip.lastZIndex = 5;
			if(!$(this.element)) {
				let element = SB_Input(this.element).FormElement;
				if(!element){
					element = $$(this.element)[0];
				}
				if (!element) {
					return;
				}
				this.element = element;

			// Trick for at tooltip ikke vises bagved tilhørende popup
				// if (this.element.up(".Popup"))
				// 	Opentip.lastZIndex = this.element.up(".Popup").style.zIndex + 2;
			}

			if(this.guided_tour)
			{
				$(this.element).observe("mousedown",(function(){tour.next()}).bind(this));

				if(this.hide_trigger)
				{
					$(this.element).observe(this.hide_trigger,(function(){this.hide()}).bind(this));
				}
			}
			
//			if(!this.opentip){
				this.opentip = Tooltip($(this.element), {
					content: message,
					allowHTML: true,
					interactive: true,
					showOnCreate: true,
					animation: "shift-toward",
					duration: 250,
				});
//			}
			// ophæv trick fra før, så tooltips virker andre steder
			// Opentip.lastZIndex = 10000;

			this.opentip.show();

			// if(this.fixed){
			// 	this.opentip.container[0].style.position = "fixed";
			// }
			
			return this;
		},
		
		hide: function(){
			if(this.type == "billboard" && $("billboard"))
			{
				$("billboard").style.opacity = 0;
				setTimeout(function(){($("billboard").update().hide())}, 1000);
				return this;
			}

			if(this.opentip){
				this.opentip.hide();
			}

			return this;
		},
	}),
	
	addTip: function(obj){
		for(let i in this.tips){
			if(this.tips[i].name == obj.name && !this.tips[i].guided_tour){
				return this;
			}
		}
	
		let tip = new this.tip(obj);
		this.tips.push(tip);

		return this;
	},
	
	go: function(dir){
		if(this.tips[this.activeTip]){
			this.tips[this.activeTip].hide();

			if(dir != 0 && this.tips[this.activeTip].goto)
			{
//				h = Cookie.get("guided_tour");
				window.location = this.tips[this.activeTip].goto/* + (h ? "#guided_tour=true" : "")*/;
			}
		}
//		this.status = "inactive";

		this.activeTip += dir;

		if(this.tips[this.activeTip]){
			this.tips[this.activeTip].show();
//			this.status = "active";
		}

		return this;
	},

	previous: function(){
		return this.go(-1);
	},
	next: function(ignore){
//		if(!this.tips[this.activeTip].guided_tour || this.tips[this.activeTip].guided_tour == "end")
		{
			new SB_Ajax("tooltips_update.php",{
				name: this.tips[this.activeTip]["name"],
				action: "seen",
			}).SetLoadingEffect(false).Request();
		}

		return this.go(1);
	},
	
	start: function(){
		this.started = true;
		this.activeTip = 0;
		setTimeout("tour.go(0)", 500);
/*
		if(this.tips[this.activeTip])
			this.tips[this.activeTip].show();
*/
		
		return this;
	},
	stop: function(){
		if(this.tips[this.activeTip]){
			this.tips[this.activeTip].hide();
		}
		this.started = false;
//		this.status = "inactive";
		return this;
	},
	
	status: function(){
		let result = "inactive";
		this.tips.forEach((val)=>{
			if(val.opentip.state.isVisible) {
				result = "active";
			}
		})
		return result;
	},
	
	resume: function(){
		if(this.status() == "inactive")
			setTimeout("tour.go(0)", 500);
		return this;
	},
	
	later: function(){
		new SB_Ajax("tooltips_update.php",{
			name: this.tips[this.activeTip]["name"],
			action: "postpone",
		}).SetLoadingEffect(false).Request();
		this.stop();
	},
});
export var tour = new Tour();
/*
Event.observe(document, "dom:loaded", function(){
	tour = new Tour();
	
	tour.addTip({
		element: $$("[for='input[InvoiceCreate[payment_date]]']")[0],
		message: "Lala",
		title: "Individuel betalingsfrist!",
		tipJoint: "top center",
	});
	tour.start();
});
*/
