<template>
	<select :data-placeholder="placeholder" class="input input-select" size="1" @change="$emit('input', $event.target.value)">
		<option v-if="placeholder"></option>
		<option
			v-for="option in cleaned_options"
			:key="option.value + option.label"
			:value="option.value"
			:selected="option.value == value"
			:disabled="option.disabled"
			v-html="option.label"
		></option>
	</select>
</template>
<script>
	export default {
		name: "chosen-select",
		props: {
			placeholder: String,
			options: null,
			value: {
				default: "",
			},
			deselect: {
				default: true,
			}
		},
		// model: {
		// 	prop: 'value',
		// 	event: 'input',
		// },
		data: function() {
			return {
				chosen: undefined,
			};
		},

		computed: {
			cleaned_options() {
				let cleaned_options = [];
				this.options.forEach((val, key) => {
					let opt = {};
					if (Array.isArray(val)) {
						opt.value = val[0];
						opt.label = val[1];
						opt.disabled = false;
					} else if(typeof val.label === 'object') {
						opt.value = val.label.value;
						opt.label = val.label.label;
						opt.disabled = !!val.label.disabled;
					} else {
						opt = val;
					}
					cleaned_options.push(opt);
				})
				return cleaned_options;
			},
		},

		mounted: function() {
			var self = this;
			this.mount();
			// $(this.$el).observe("change", function(){
			// 	self.$emit("input", this.value);
			// });
			// this.$nextTick(function(){
			// 	self.$el.value = self.value;
			// 	Event.fire($(self.$el), "chosen:updated");
			// });
		},

		// beforeDestroy: function() {
		destroyed: function() {
			setTimeout(()=>{
				this.chosen.destroy();
			}, 1000);
		},

// onfocus="SB_Input(this).onfocus();" onblur="SB_Input(this).onblur();" onchange="SB_Input(this).onchange(); if (SB_Input(this).value()=='new') {$$('.BankImportNewFilter').invoke('show');} else {$$('.BankImportNewFilter').invoke('hide');} SB_Lists['bank_import'].Refresh({'filter' : SB_Input(this).value()}).RefreshContextBar();" onclick="SB_Input(this).onclick();" onkeyup="SB_Input(this).onkeyup();" onselectstart="SB_Input(this).onselectstart();"

		methods: {
			mount: function() {
				this.chosen = new Chosen(this.$el, {
					disable_search: true,
					allow_single_deselect: !!this.placeholder,
					width : '100%',
				});
			},
		},

		watch: {
			options: function(value) {
				this.$nextTick(()=>{
					this.chosen.destroy();
					this.mount();
				})
			},
			value: function(value) {
				this.$el.value = value;
				Event.fire($(this.$el), "chosen:updated");
			},
		},
	};
</script>