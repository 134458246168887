<template>
	<div>
		<span v-if="clearable" class='input clear-button' @click="$emit('input','')" style="
			position: absolute;
			right: 0px;
			top: 1px;
			margin: 0px;
			width: 2em;
			text-align: center;
			cursor: pointer;
		" title="Nulstil"><i class="fa fa-times" style="color: #888888;"></i></span>
		<input type="text" ref="input" class="input input-text" v-bind="$attrs" v-on="$listeners" @focus="show_calendar = true" @blur="show_calendar = false" />
	</div>
</template>
<script>
import { SB_CalendarInit } from "../sb_modules/calendar.es6.js";
import { SB_DateToTimestamp, SB_FormatDate } from '../sb_modules/common_functions.es6.js';
export default {
	inheritAttrs: false,
	name: "sb-input-date",
	props: {
		closeOnSelect: {
			default() {return false},
			type: Boolean,
		},
		clearable: {
			default() {return false},
			type: Boolean,
		},
	},
	data() {
		return {
			/** @property {Pikaday} */
			_calendar: null,
			show_calendar: false,
		};
	},
	mounted: function() {
		jQuery(this.$refs.input).on("input", event => {
			this.input(event);
		});
		SB_CalendarInit(this.$attrs.id, {bound: true}).then((cal) => {
			this._calendar = cal;
			this._calendar._o.onSelect = () => {
				this.$nextTick(() => {
					if (this.closeOnSelect) {
						this.toggle_calendar(false);
						this.$refs.input.blur();
						setTimeout(()=>{
							this.$refs.input.blur();
						}, 50)
					}
				})
			}
			if (this.$refs.input.value) {
				this._calendar.setDate(new Date(SB_DateToTimestamp(this.$refs.input.value) * 1000), false);
			} else if (this.clearable) {
				this.value = "";
			} else {
				this._calendar.setDate(new Date());
			}
		});
	},
	beforeDestroy: function() {
		jQuery(this.$refs.input).off("input");
	},
	methods: {
		input: function($event) {
			this.$emit("input", $event.target.value);
		},
		toggle_calendar(on) {
			if (on) {
				this._calendar.show()
				this.$refs.input.select();
			} else {
				this._calendar.hide()
			}
		},
	},
	watch: {
		"$attrs.value": function(value) {
			if (!SB_DateToTimestamp(value)) {
				return false;
			}
			let caldate = this._calendar.getDate();
			let current_calendar_date;
			if (!caldate) {
				current_calendar_date = 0;
			} else {
				current_calendar_date = SB_FormatDate(caldate.getTime().toString().substring(0,10)-0);
			}
			if (value != current_calendar_date) {
				let newDate = new Date( SB_DateToTimestamp(value) * 1000);
				this._calendar.setDate(newDate, true);
			}
			// this.$emit("input", value);
		},
		show_calendar(value) {
			// this.toggle_calendar(value);
		}
	},
};
</script>
