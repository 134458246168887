// export async function load_financial_report_list() {
// 	document.querySelector(".LayoutWrapper").style.display = "none";
// 	await import(
// 		/* webpackChunkName: "financial_report_list" */
// 		/* disable_webpackPrefetch: true */
// 		"./financial_report_list.js"
// 	);
// 	document.querySelector(".LayoutWrapper").style.display = "block";
// 	return true;
// }

export { init as load_financial_report_list } from "./financial_report_list.js";
export * from "./financial_report_list.js";

export * from "./financial_post_list.js";
export * from "./deal_edit.js";
// export async function load_financial_post_list(el, financial_post_list_options) {
// 	if (!init_financial_post_list_done) {
// 		financial_post_list_instance = init_financial_post_list(el, financial_post_list_options);
// 		init_financial_post_list_done = true;
// 	}
// 	return Promise.resolve();
// }