<template>
	<div class="filtered-search-wrapper">
		<div class="filtered-search-box" :class="{focus : focus}">
			<div class="filtered-search-box-input-container droplab-dropdown">
				<div class="scroll-container">
					<ul class="tokens-container list-unstyled">
						<li v-for="(token, i) in tokens" :key="i" class="js-visual-token filtered-search-token">
							<div class="selectable" role="button">
								<div class="name">{{ token.name }}</div>
								<div v-if="token.comparison" class="comparison" v-html="Array.isArray(token.comparison) ? token.comparison[1] : token.comparison"></div>
								<div v-if="token.value" class="value-container">
									<div class="value" v-html="Array.isArray(token.value) ? token.value[1] : token.value"></div>
									<div class="remove-token" role="button" @click="remove_token(i)">
										<i class="fa fa-close"></i>
									</div>
								</div>
							</div>
						</li>
						<li class="input-token">
							<input
								v-model="input"
								ref="input_field"
								class="form-control filtered-search"
								autofocus
								@focus="focus = true"
								@blur="focus = false; highlighted_token = -1"
								@keydown.tab.exact="tab_pushed"
								@keydown.enter.exact="enter_pushed"
								@keydown.delete="reopen_last_token($event, true)"
								@keydown.down="highlight_next"
								@keydown.up="highlight_previous"
								@keydown.esc="hide_highlighted"
								@keydown.left.stop="left_pushed"
								@keydown.right.stop="right_pushed"
								:placeholder="tokens.length == 0 ? placeholder : ''"
							>
							<div class="acceptable_tokens" v-if="highlighted_token != -1 && focus">
								<ul class="list-unstyled">
									<li v-for="(list_element, i) in autocomplete_list" :key="i" :class="{highlighted: i===highlighted_token}" @mousedown="autocomplete_clicked(i)" v-html="list_element.name ? highlight_search_result(list_element.name) : highlight_search_result(list_element)"></li>
								</ul>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<button v-if="tokens.length || input.length" class="clear-search" type="button" @click="tokens = []; input = ''; reclaim_focus()">
				<i aria-hidden="true" data-hidden="true" class="fa fa-times"></i>
			</button>
			<button v-if="acceptable_tokens && acceptable_tokens.length" class="open-options" :class="{'active': (this.highlighted_token != -1)}" @click="toggle_helper(); reclaim_focus()">
				<i aria-hidden="true" data-hidden="true" class="fa fa-sliders"></i>
			</button>
		</div>
		<input type="text" style="position: absolute; opacity:0; z-index: -1;" @focus="reclaim_focus" :value="parsed" @input="set_parsed($event.target.value)" ref="output_field" :id="id ? id : 'input['+name+']'" :name="name">
	</div>
</template>
<script>
	import fuzzysort from "../fuzzysort.js";
	export default {
		props: {
			"acceptable_tokens": {
				default() {
					return [];
				},
			},
			"id": {

			},
			"name": {

			},
			"placeholder": {

			},
			"value": {

			},
		},
		name: "tokenized-search",
		data: function() {return {
			focus: false,
			comparisons: [["=", "Lig med"], ["!=", "Forskellig fra"], ["<", "Mindre end"], [">", "Større end"], ["<=", "Mindre end eller lig med"], [">=", "Større end eller lig med"]],
			highlighted_token: -1,
			// tokens: this.parse_tokens(this.value).tokens,
			// input: this.parse_tokens(this.value).remainder,
			tokens: [],
			input: "",
			calendar: false,
		}},
		mounted: function() {
			this.tokens = this.parse_tokens(this.value).tokens;
			this.input = this.parse_tokens(this.value).remainder;
		},
		methods: {
			set_parsed: function(newVal) {
				let reopen = !!this.input;
				let parts = this.parse_tokens(newVal);
				this.tokens = parts.tokens;
				this.input = parts.remainder;
				if (reopen) {
					this.reopen_last_token();
				}
			},
			parse_tokens: function(str) {
				var quote_open = false;
				var strlen = str.length;
				var cache = "";
				var out = [""];
				var tokens = 0;

				for (var pos = 0; pos < strlen; pos++) {
					if (str[pos] == '"') {
						quote_open = !quote_open;
					}
					if (!quote_open && str[pos] == ' ') {
						out[tokens] && tokens++;
					} else {
						(!out[tokens]) && (out[tokens] = "");
						out[tokens] += ""+str[pos];
					}
				}

				var remainder = "";
				out = out.map((v) => {
					var name, value, comparison;
					if (v.match("::")) {
						var tmp = v.split("::");
						name = tmp.shift().replace(/^"+|"+$/g, "");
						let current_token = this.acceptable_tokens.find(function(acceptable_token) {
							return (acceptable_token.name ? acceptable_token.name : acceptable_token) == name;
						});
						let comparisons = (current_token && current_token.comparisons && current_token.comparisons !== true) ? current_token.comparisons : this.comparisons;
						let possible_values = (current_token && current_token.possible_values) ? current_token.possible_values : false;

						value = tmp.join("::").replace(/^"+|"+$/g, "");
						if (value.substr(0,2).match(/<=|>=|!=/)) {
							comparison = value.substr(0,2);
							if (Array.isArray(comparisons)) {
								let comp = comparisons.find((comp)=>(comp[0] == comparison));
								if (comp) {
									comparison = comp;
								}
							}
							value = value.substr(2);
						} else if (value.substr(0,1).match(/<|>|=/)) {
							comparison = value.substr(0,1);
							if (Array.isArray(comparisons)) {
								let comp = comparisons.find((comp)=>(comp[0] == comparison));
								if (comp) {
									comparison = comp;
								}
							}
							value = value.substr(1);

						} else if (value.match(/(LIKE|NOT LIKE)::.*/)) {
							comparison = value.match(/(LIKE|NOT LIKE)::.*/)[1];
							let comp_len = comparison.length + 3;
							if (Array.isArray(comparisons)) {
								let comp = comparisons.find((comp)=>(comp[0] == `${comparison}::`));
								if (comp) {
									comparison = comp;
								}
							}
							console.log(comparisons);
							console.log(comparison);
							console.log(current_token);
							value = value.substr(comp_len);

						}
						if (possible_values) {
							value = possible_values.find((val) => (val[0] == value));
						}
					} else {
						value = v;
						name = null;
					}
					if (!name) {
						if (remainder) {
							remainder = remainder + " " + value;
						} else {
							remainder = value;
						}
						return;
					} else {
						return {value: value, name: name, comparison: comparison};
					}
				}).filter(Boolean);

				return {tokens: out, remainder: remainder};
			},

			autocomplete_clicked: function(i) {
				this.highlighted_token = i;
				this.input_done();
				setTimeout(() => {
					this.reclaim_focus();
					this.highlighted_token = i;
				}, 20);
			},
			reclaim_focus: function() {
				if (this.$refs.input_field) {
					this.$refs.input_field.style.transform = "translateY(" + (50 + document.viewport.getScrollOffsets().top - this.$refs.input_field.cumulativeOffset().top) + "px)";
					this.$refs.input_field.style.zIndex = -10;
					
					let forceLayout = this.$refs.input_field.clientLeft;

					setImmediate(() => {
						this.$refs.input_field.focus({preventScroll: true});
						setImmediate(() => {
							this.$refs.input_field.style.transform = "";
							this.$refs.input_field.style.zIndex = "";
						})
					})

					// this.$refs.input_field.focus();
				}
			},
			input_done: function(e) {
				if (this.highlighted_token != -1 && this.autocomplete_list.length != 0) {
					var token = this.autocomplete_list[this.highlighted_token];
					var selected = token.name ? token.name : token;
					// this.input = token.name ? token.name : token;
					this.highlighted_token = 0;

					if (!this.current_token) {
						this.tokens.push({
							name: selected,
							comparison: "",
							value: "",
						});
					} else {
						if (this.current_token.comparisons && !this.tokens[this.tokens.length - 1].comparison) {
							this.tokens[this.tokens.length - 1].comparison = selected;
						} else {
							this.tokens[this.tokens.length - 1].value = selected;
						}
					}

					this.input = "";
				} else {
					var comparisons = this.comparisons;
					var current_token = this.current_token;

					if (this.tokens.length && this.tokens[this.tokens.length - 1].name && !this.tokens[this.tokens.length - 1].value) {
						var is_input_comparison = false;
						if (current_token.comparisons) {
							var possible_values = current_token.comparisons === true
								? comparisons
								: current_token.comparisons;
							var fitting_comparison = false;
							var input = this.input;
							possible_values = possible_values.filter(function(token){
								var t = Array.isArray(token) ? token[0] : token;
								return t === input;
							});
							is_input_comparison = possible_values[0];
						}

						if (is_input_comparison && this.current_token.comparisons && !this.tokens[this.tokens.length - 1].comparison) {
							this.tokens[this.tokens.length - 1].comparison = is_input_comparison;
						} else {
							this.tokens[this.tokens.length - 1].value = this.input;
						}
						// this.tokens[this.tokens.length - 1].value = this.input;
						this.input = "";
					}
				}
				
				this.reclaim_focus();
			},
			tab_pushed: function(e) {
				if (this.highlighted_token != -1 && this.autocomplete_list.length != 0) {
					e.stopPropagation();
					e.preventDefault();
				}

				this.input_done(e);
			},
			enter_pushed: function(e) {
				if (this.highlighted_token != -1 && this.autocomplete_list.length != 0) {
					e.stopPropagation();
					e.preventDefault();
				}

				this.input_done(e);
			},
			reopen_last_token: function(e, force = false) {
				let ret = true;
				if (!this.tokens.length) {
					return ret;
				}
				var token = this.tokens[this.tokens.length - 1];
				if (this.input == "") {
					if (token.value) {
						this.input = Array.isArray(token.value)
							// ? token.value[1]
							? ""
							: token.value;
						if (this.current_token.possible_values && this.current_token.possible_values.length) {
							this.input = "";
						}
						token.value = "";
					} else if (token.comparison) {
						// this.input = token.comparison;
						token.comparison = "";
					} else {
						// this.input = token.name;
						if (force) {
							this.tokens.pop();
						}
						ret = false;
					}
					if (e) {
						e.stopPropagation();
						e.preventDefault();
					}
				}
				return ret;
			},
			remove_token: function(i) {
				this.tokens.splice(i, 1);
			},
			highlight_next: function(e) {
				if (this.highlighted_token == -1 || this.autocomplete_list.length == 0) {
					return;
				} else {
					e.stopPropagation();
					e.preventDefault();
				}
				if (this.autocomplete_list.length == 0) {
					this.highlighted_token = 0;
				} else if (this.highlighted_token >= this.autocomplete_list.length - 1) {
					this.highlighted_token = 0;
				} else {
					this.highlighted_token += 1;
				}
			},
			highlight_previous: function(e) {
				if (this.highlighted_token == -1 || this.autocomplete_list.length == 0) {
					return;
				} else {
					e.stopPropagation();
					e.preventDefault();
				}
				if (this.autocomplete_list.length == 0) {
					this.highlighted_token = 0;
				} else if (this.highlighted_token <= 0) {
					this.highlighted_token = this.autocomplete_list.length - 1;
				} else {
					this.highlighted_token -= 1;
				}
			},
			hide_highlighted: function(e) {
				if (this.highlighted_token != -1) {
					this.highlighted_token = -1;
					e.stopPropagation();
					e.preventDefault();
				}
			},
			left_pushed: function(e) {
				if (!this.input.length) {
					this.highlighted_token = -1;
				}
			},
			right_pushed: function(e) {
				if (doGetCaretPosition(this.$refs.input_field) != this.input.length) {
					return;
				}
				if (this.highlighted_token == -1) {
					this.highlighted_token = 0;
				} else if (this.autocomplete_list.length && this.autocomplete_list.length > this.highlighted_token) {
					this.input_done();
				}
			},
			toggle_helper: function() {
				if (this.highlighted_token == -1) {
					this.highlighted_token = 0;
				} else {
					this.highlighted_token = -1;
				}
			},
			highlight_search_result: function(val) {
				var input = this.input;
				if (input) {
					var output;
					if (Array.isArray(val)) {
						output = fuzzysort.highlight(fuzzysort.single(input, val[1]));
						return output ? output : val[1];
					} else {
						output = fuzzysort.highlight(fuzzysort.single(input, val));
						return output ? output : val;
					}
				} else {
					if (Array.isArray(val)) {
						return val[1];
					} else {
						return val;
					}
				}
			},
		},
		computed: {
			filtered_acceptable_tokens: function() {
				var input = this.input;

				var acceptable_tokens = this.acceptable_tokens.filter(function(token) {
					var t = token.name ? token.name : token;
					if (input && !fuzzysort.single(input, t)) {
						return false;
					}
					return true;

					// var name = token.name ? token.name : token;
					// if (input.toLowerCase() && name.toLowerCase().search(input) == -1) {
					// 	return false;
					// }
					// return true;
				});

				if (input) {
					acceptable_tokens.sort(function(a, b){
						var a1 = a.name ? a.name : a;
						var b1 = b.name ? b.name : b;

						var a2 = fuzzysort.single(input, a1).score;
						var b2 = fuzzysort.single(input, b1).score;

						if (a2 > b2) {
							return -1;
						} else if (a2 < b2) {
							return 1;
						} else {
							return 0
						}
					});
				}

				return acceptable_tokens;
			},
			current_token: function() {
				var acceptable_tokens = JSON.parse(JSON.stringify(this.acceptable_tokens));
				if (this.tokens.length && this.tokens[this.tokens.length - 1].name && !this.tokens[this.tokens.length - 1].value) {
					var token = this.tokens[this.tokens.length - 1];
					acceptable_tokens = acceptable_tokens.filter(function(acceptable_token) {
						return (acceptable_token.name ? acceptable_token.name : acceptable_token) == token.name;
					});
					if (acceptable_tokens.length) {
						return acceptable_tokens[0];
					}
				}
				return false;
			},
			last_token: function() {
				var tokens = this.tokens;
				if (tokens.length) {
					return tokens[tokens.length - 1];
				}
				return false;
			},
			show_calendar: function() {
				let focus = this.focus;
				let tmp_focus = document.body.querySelector("textarea:focus, input:focus, select:focus");
				if (tmp_focus && tmp_focus.hasClassName("pika-select")) {
					focus = true;
				}
				let current_token = this.current_token;
				let last_token = this.last_token;
				let autocomplete_list = this.autocomplete_list;
				return (focus && current_token && current_token.date_input && !autocomplete_list.length);
			},
			autocomplete_list: function() {
				var filtered_acceptable_tokens = this.filtered_acceptable_tokens;
				var comparisons = this.comparisons;
				var input = this.input;
				var last_token = this.last_token;
				var current_token = this.current_token;
				if (current_token) {
					var possible_values;
					if (current_token.comparisons && !last_token.comparison) {
						possible_values = current_token.comparisons === true
							? comparisons
							: current_token.comparisons
						;
					} else if (current_token.possible_values) {
						possible_values = current_token.possible_values;
					} else {
						possible_values = [];
					}

					possible_values = JSON.parse(JSON.stringify(possible_values));
					possible_values = possible_values.filter(function(token) {
						var t = Array.isArray(token) ? token[1] : token;
						if (input && !fuzzysort.single(input, t)) {
							return false;
						}
						return true;
					});
					if (input) {
						possible_values.sort(function(a, b){
							var a1 = Array.isArray(a) ? a[1] : a;
							var b1 = Array.isArray(b) ? b[1] : b;

							var a2 = fuzzysort.single(input, a1).score;
							var b2 = fuzzysort.single(input, b1).score;

							if (a2 > b2) {
								return -1;
							} else if (a2 < b2) {
								return 1;
							} else {
								return 0
							}
						});
					}

					return possible_values;
				} else {
					return filtered_acceptable_tokens;
				}
			},
			parsed: function() {
				var tokens = JSON.parse(JSON.stringify(this.tokens));
				var input = this.input;
				var input_used = false;
				var output = "";
				output = tokens.map(function(token) {
					if (!token.value) {
						token.value = input;
						input_used = true;
					}
					// if (token.value) {
						var name = token.name.match(" ") ? '"' + token.name + '"' : token.name;
						var value = Array.isArray(token.value) ? token.value[0] : token.value;
						value = value.match(" ") ? '"' + value + '"' : value;
						if (token.comparison) {
							if (Array.isArray(token.comparison)) {
								return name + '::"' + token.comparison[0] + '"' + value;
							} else {
								return name + '::"' + token.comparison + '"' + value;
							}
						} else {
							return name + "::" + value;
						}
					// } else {
					// 	return "";
					// }
				}).join(" ");
				if (input && !input_used) {
					output += " " + input;
				}
				return output.trim();
			},
		},
		watch: {
			parsed: function(newValue) {
				var element = this.$refs.output_field;
				this.$nextTick(function(){
					if (document.createEvent) {
						event = document.createEvent("HTMLEvents");
						event.initEvent("keyup", true, true);
					} else {
						event = document.createEventObject();
						event.eventType = "keyup";
					}

					event.eventName = "keyup";

					if (document.createEvent) {
						element.dispatchEvent(event);
					} else {
						element.fireEvent("on" + event.eventType, event);
					}
				});
				this.$emit('input', newValue);
			},
			value: function (newValue, oldValue) {
				if (newValue != oldValue && newValue != this.parsed) {
					this.set_parsed(newValue);
					// this.parsed = newValue;
				}
			},
			input: function (newValue) {
				if (newValue && this.autocomplete_list) {
					if (this.highlighted_token != -1) {
						this.highlighted_token = 0;
					}
				}
			},
			show_calendar: function(newValue) {
				// if (this.calendar) {
					// if (this.focus && this.current_token && this.current_token.date_input && this.last_token && this.last_token.comparison) {
					if (newValue) {
						if (!this.calendar) {
							SB_CalendarInit(this.$refs.input_field , {bound: false})
							.then(cal => {
								this.calendar = cal;
								var self = this;
								this.calendar._o.onChange = function() {
									self.$nextTick(() => {
										if (/* self.$refs.input_field.value &&  */self.show_calendar) {
											self.input = self.$refs.input_field.value;
										}
									})
								}
								// onDraw bliver kaldt når man bruger måned/år i toppen af kalenderen til at springe til en anden dato.
								// Men når man bruger dem bliver fokus som udgangspunkt ikke sat tilbage 
								this.calendar._o.onDraw = function() {
									self.reclaim_focus();
								}

								if (self.$refs.input_field.value) {
									this.calendar.setDate(new Date(SB_DateToTimestamp(this.$refs.input_field.value) * 1000), false);
								} else {
									this.calendar.setDate(new Date());
								}
								this.calendar.show();
								this.$refs.input_field.select();
							});
						} else {
							this.$nextTick(()=>{
								if (!this.input) {
									this.calendar.setDate(new Date());
								}
								this.calendar.show();
								setTimeout(()=>{
									this.$refs.input_field.select();
								}, 100)
							})
						}
					} else {
						if (this.calendar) {
							setTimeout(()=>{
								// Vi tjekker om det er en pika-select der har fået fokus, for så er man bare ved at skifte dato med år/måned-vælgeren i toppen af kalenderen, og det skal vi ikke lukke kalenderen midt i.
								let tmp_focus = document.body.querySelector("textarea:focus, input:focus, select:focus");
								if (tmp_focus && tmp_focus.hasClassName("pika-select")) {
	
								} else {
									this.calendar.hide();
								}
							}, 50);
						}
					}
				// }
			}
		},
	}

	/**
	 * Returns the caret (cursor) position of the specified text field.
	 * Return value range is 0-oField.value.length.
	*/
	function doGetCaretPosition (oField) {
		// Initialize
		var iCaretPos = 0;

		if (document.selection) {
			// IE Support

			// Set focus on the element
			oField.focus();

			// To get cursor position, get empty selection range
			var oSel = document.selection.createRange();

			// Move selection start to 0 position
			oSel.moveStart('character', -oField.value.length);

			// The caret position is selection length
			iCaretPos = oSel.text.length;
		} else if (oField.selectionStart || oField.selectionStart == '0') {
			// Firefox support
			iCaretPos = oField.selectionStart;
		}

		// Return results
		return iCaretPos;
	}
</script>

<style lang="less" scoped>


	.filtered-search-wrapper {
		background-color: #fff;
		box-sizing: border-box;
		font-size: 16px;
	}
	.filtered-search-wrapper:not(.inContextMenu) {
		font-size: 20px;
		box-shadow: 0px 1px 2px 0.5px rgba(0, 0, 0, 0.3);
		margin-bottom: 10px;
	}

	.filtered-search-box {
		position: relative;
		flex: 1;
		display: -webkit-flex;
		display: flex;
		width: 100%;
		min-width: 0;
		/* border: 1px solid #e5e5e5; */
		line-height: 1.8;
		// height: 1.9em;
	}

	.filtered-search-box *,
	.filtered-search-box *::before,
	.filtered-search-box *::after {
		box-sizing: border-box;
	}

	.filtered-search-box-input-container {
		flex: 1;
		position: relative;
		min-width: 0;
	}

	.scroll-container {
		display: -webkit-flex;
		display: flex;
		/* overflow-x: auto; */
		white-space: nowrap;
		width: 100%;
		height: 100%;
	}

	.list-unstyled {
		padding-left: 0;
		list-style: none;
		margin-top: 0;
	}

	ul.tokens-container.list-unstyled {
		display: -webkit-flex;
		display: flex;
		flex: 1;
		-webkit-flex: 1;
		flex-wrap: wrap;
		padding-left: 12px;
		position: relative;
		margin-bottom: 0;
	}

	li.js-visual-token.filtered-search-token {
		display: -webkit-flex;
		display: flex;
		flex-shrink: 0;
		margin-top: 5px;
		margin-bottom: 5px;

		font-size: 0.7em;
	}

	.filtered-search-token .selectable, .filtered-search-term .selectable {
		display: -webkit-flex;
		display: flex;
	}

	button, html [type="button"], [type="reset"], [type="submit"], [role="button"] {
		-webkit-appearance: inherit;
		cursor: pointer;
	}

	.filtered-search-token .name, .filtered-search-term .name {
		background-color: #f8f8f8;
		color: rgba(0,0,0,0.55);
		border-radius: 2px 0 0 2px;
		margin-right: 1px;
		&::first-letter {
			text-transform: capitalize;
		}
	}

	.filtered-search-token .name, .filtered-search-token .value, .filtered-search-term .name, .filtered-search-term .value {
		display: inline-block;
		padding: 2px 7px;
	}

	.filtered-search-token .value-container, .filtered-search-term .value-container {
		background-color: #f0f0f0;
		color: rgba(0,0,0,0.85);
		border-radius: 0 2px 2px 0;
		margin-right: 5px;
		padding-right: 8px;
	}

	.filtered-search-token .value, .filtered-search-term .value {
		padding-right: 0;
	}

	.filtered-search-token .name, .filtered-search-token .value, .filtered-search-term .name, .filtered-search-term .value {
		display: inline-block;
		padding: 2px 7px;
	}

	.filtered-search-token .remove-token, .filtered-search-term .remove-token {
		display: inline-block;
		padding-left: 4px;
		padding-right: 0;
	}

	.filtered-search-wrapper .input-token:only-child, .filtered-search-wrapper .input-token:last-child {
		flex: 1;
		-webkit-flex: 1;
		max-width: inherit;
		position: relative;
	}

	.filtered-search-wrapper .input-token {
		max-width: 200px;
		padding: 0;
	}

	
	.filtered-search-box .form-control, .filtered-search-box .search form, .search .filtered-search-box form {
		position: relative;
		padding-left: 0;
		height: 100%;
		line-height: inherit;
		border-color: transparent;
		padding-right: 5px;
	}
	.filtered-search-wrapper:not(.inContextMenu) .filtered-search-box .form-control, .filtered-search-box .search form, .search .filtered-search-box form {
		padding-right: 25px;
		min-width: 200px;
	}
	.form-control, .search form {
		border-radius: 2px;
		padding: 6px 10px;
	}

	.form-control, .search form {
		display: block;
		width: 100%;
		/* padding: 0.375rem 0.75rem; */
		/* font-size: 1rem; */
		/* line-height: 20px; */
		color: #2e2e2e;
		background-color: transparent;
		background-clip: padding-box;
		border: 1px solid #dfdfdf;
		/* border-radius: 0.25rem; */
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	}

	.filtered-search-box .form-control:focus, .filtered-search-box .common-note-form .md-area.is-focused, .common-note-form .filtered-search-box .md-area.is-focused, .filtered-search-box .search form:focus, .search .filtered-search-box form:focus, .filtered-search-box .search.search-active form, .search.search-active .filtered-search-box form, .filtered-search-box .form-control:hover, .filtered-search-box .filtered-search-box:hover, .filtered-search-box .search form:hover, .search .filtered-search-box form:hover {
		outline: none;
		border-color: transparent;
		box-shadow: none;
	}

	.filtered-search-wrapper:not(.inContextMenu) .filtered-search-box.focus {
		// border-color: #03A9F4;
		// background-color: #E1F5FE;
		border-color: var(--TopMenuBg);
		background-color: var(--RightMenuBg);
	}

	.acceptable_tokens {
		position: absolute;
		z-index: 1000;
		top: 100%;
		left: 0;
		box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
		border-radius: 2px;
		overflow: hidden;
		font-size: 0.7em;
	}

	.acceptable_tokens > ul > li.highlighted {
		background-color: #cccccc;
	}

	.acceptable_tokens > ul > li {
		padding: 3px 9px;
		background-color: white;
		cursor: pointer;
	}

	.acceptable_tokens > ul > li::first-letter {
		text-transform: capitalize;
	}

	.filtered-search-box {
		.clear-search,
		.open-options {
			width: 35px;
			background-color: transparent;
			border: 0;
			outline: none;
			z-index: 1;
		}

		.clear-search {
			padding-right: 0;
		}
		.open-options {
			padding-left: 0;
			&.active {
				color: var(--Green);
			}
		}
	}

	.filtered-search-token .comparison, .filtered-search-term .comparison {
		background-color: #eeeeee;
		color: rgba(0,0,0,0.55);
		border-radius: 2px 0 0 2px;
		margin-right: 1px;
		padding: 2px 6px;
	}
</style>