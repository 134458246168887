/* global SB_TouchDevice */
import { Class } from "Prototype";
import { SB_Ajax } from "./ajax.es6";
import { SB_RemoveFocus } from "./common_functions.es6";

export var SB_Popup = {
	Popups : {},
	
	StackIndex : 0,
	
	Init : function(Id)
	{
		if (!Id)
		{
			alert("ID missing");
			return false;
		}
		
		this.StackIndex += 1;
		
		if (typeof(this.Popups[Id]) == "undefined")
		{
			this.Popups[Id] = new __SB_Popup(Id, this.StackIndex);
		}
		else if (!this.Popups[Id].IsVisible())
		{
			this.Popups[Id].SetStackIndex(this.StackIndex);
		}
		
		return this.Popups[Id];
	},

	Esc : function(event)
	{
		if (event.keyCode == Event.KEY_ESC)
		{
			if (Object.size(SB_Popup.Popups) > 0)
			{
				SB_Popup.RemoveFromTop();
			
				event.Stopped = true;
				event.stop();
			}
		}
	},

	RemoveFromTop : async function(ignore_remove_event)
	{
		var TopIndex = -1;
		var TopId = false;
		for (let Id in this.Popups)
		{
			if (this.Popups[Id].StackIndex > TopIndex)
			{
				TopIndex = this.Popups[Id].StackIndex;
				TopId = Id;
			}
		}
		
		if (TopId !== false)
		{
			try {
				await this.Popups[TopId].Remove(ignore_remove_event);
				return true;
			} catch (err) {
				return false;
			}
			
		}
		else
		{
			return false;
		}
	},

	getCurrentPath: function () {
		return jQuery
			.map(SB_Popup.Popups, function (obj) {
				return [[obj.StackIndex, obj.AjaxUrl ? obj.AjaxUrl : obj.Id]]
			})
			.sort(function (arr1, arr2) {
				return arr1[0] < arr2[0] ? -1 : 1
			})
			.map(function (arr) {
				return arr[1]
			});
	}
};

var __SB_Popup = Class.create({
	Background : false,
	Wrapper : false,
	Draggable : false,
	
	AjaxObj : false,
	AjaxUrl : false,
	AjaxParams : false,
	
	Id : false,
	StackIndex : 0,
	
	initialize : function(Id, StackIndex)
	{
		this.Id = Id;
		this.StackIndex = StackIndex;
		this.CustomEvents = {};
		
		this.Background = new Element('div').addClassName('PopupBackground').hide();
		
		this.Wrapper = new Element('div').addClassName('Popup').hide();

		document.fire("sb:popup.initialized");
		
		return this;
	},
	
	Ajax : function(Url,Params)
	{
		this.AjaxUrl = Url;
		this.AjaxParams = Params;
		
		this.AjaxObj = new SB_Ajax(Url,Params).SetTarget(this.Wrapper).SetDelay(false);
		
		return this.AjaxObj;
	},
	
	Reload : function()
	{
		if (typeof(this.CustomEvents.onRemove) != "undefined")
			this.CustomEvents.onRemove();
		
		return this.Ajax(this.AjaxUrl,this.AjaxParams).Request();
	},
	
	SetStackIndex : function(StackIndex)
	{
		this.StackIndex = StackIndex;
		
		return this;
	},
	
	AddEvent : function(name, fnc)
	{
		this.CustomEvents[name] = fnc;
		
		return this;
	},
	
	Update : function(html)
	{
		this.Wrapper.update(html);

		this.InitDraggable();

		return this;
	},
	
	Insert : function(html)
	{
		this.Wrapper.insert(html);

		this.InitDraggable();

		return this;
	},
	
	IsVisible : function()
	{
		return (this.Wrapper !== false && this.Wrapper.up("body"));
	},

	AdjustHeader : function()
	{
		var h1 = this.Wrapper.down("h1");
		if (!h1)
			return false;

		var offset = 0;
		h1.select(".nav").each(function(nav) {
			if (nav.hasClassName("spacer"))
				return false;
			let w = nav.getWidth() + 4;

			if (nav.hasClassName("left"))
				offset -= w;
			else
				offset += w;
		});

		if (offset > 10)
			h1.insert({'bottom' : "<div class='nav left auto-spacer' style='width: "+offset+"px;'>&nbsp;</div>"});
		else if (offset < -10)
			h1.insert({'bottom' : "<div class='nav right auto-spacer' style='width: "+(-offset)+"px;'>&nbsp;</div>"});
	},
	
	Show : function()
	{
		SB_RemoveFocus();
		// window.scroll(0,0);

		if (!$(document.body).hasClassName("has-popup"))
			$(document.body).addClassName("has-popup");
	
		if (!this.IsVisible())
		{
			var Top = (!this.Wrapper.hasClassName('confirm')) ? 30 : 150;

			this.Wrapper.addClassName("out").addClassName("visible");
			$(document.body).insert(this.Background);
			this.Background.insert(this.Wrapper)
			// $(document.body).insert(this.Wrapper);
			
			this.Background.setStyle({'zIndex' : (100 + this.StackIndex*2), 'opacity' : 0, 'transition': 'all .3s ease-in-out'}).show();

			var left = document.body.offsetWidth/2 - $(this.Wrapper).getWidth()/2;
			this.Wrapper.show().setStyle({
				'position' : 'absolute',
				'zIndex' : (101 + this.StackIndex*2),
				'left' : left + 'px',
				// 'top' : '-' + (this.Wrapper.offsetHeight+50) + 'px',
				'top' : Top+'px',
			});

			setTimeout((function(){
				var el = jQuery(this.Wrapper).find("[autofocus]");
				if (el.length) {
					el[0].focus();
				}
			}).bind(this), 600);

			this.AdjustHeader();
			
			setImmediate(function(){
				this.Background.setStyle({'opacity' : 1});
				this.Wrapper.removeClassName("out");
				setTimeout(async function(){
					this.Wrapper.removeClassName("visible");

					if (typeof(this.CustomEvents.onShow) !== "undefined") {
						try {
							let result = this.CustomEvents.onShow.bind(this)();
							result = await Promise.resolve(result);
							// if (result === false) {
							// 	return Promise.reject("cancelled by custom remove event");
							// }
						} catch (err) {
							// return Promise.reject(err || "cancelled by custom remove event");
						}
					}
			
				}.bind(this),500)
			}.bind(this));
		}
		
		this.InitDraggable();
	},
	
	async Remove(ignore_remove_event) {
		if (typeof(this.CustomEvents.onRemove) != "undefined" && !ignore_remove_event) {
			let result = this.CustomEvents.onRemove();
			try {
				result = await Promise.resolve(result);
				if (result === false) {
					return Promise.reject("cancelled by custom remove event");
				}
			} catch (err) {
				return Promise.reject(err || "cancelled by custom remove event");
			}
		}

		return new Promise((resolve, reject) => {
				
			this.DestroyDraggable();
				
			delete(SB_Popup.Popups[this.Id]);
	
			if (Object.size(SB_Popup.Popups) == 0) {
				$(document.body).removeClassName("has-popup");
			}
		
			this.Background.setStyle({'opacity' : 0});
			this.Wrapper.addClassName("visible").addClassName("out");
	
			setTimeout(() => {
				this.Background.remove();
				this.Wrapper.remove();
				resolve(this);
			}, 300)
		})
	},
	
	InitDraggable : function()
	{
		// let h1 = this.Wrapper.down("h1");
		// if (h1) {
		// 	h1.classList.add("drag-handle");
		// }

		if (SB_TouchDevice === true)
			return this;

		this.DestroyDraggable();

		var h1 = this.Wrapper.down("h1");
		if (h1)
		{
			h1.addClassName("drag-handle");
			// this.Draggable = new Draggable(this.Wrapper,{starteffect: void(0), endeffect: void(0), handle: h1, onEnd:this.DragEnd.bind(this) });
			this.Draggable = jQuery(this.Wrapper).draggable({
				start: function() {
					jQuery(this).data("height", jQuery(this).css('height'));
				},
				// stop: function() {
				// 	jQuery(this).css("height", jQuery(this).data('height'));
				// 	jQuery(this).data("height", null);
				// },
				starteffect: void(0),
				endeffect: void(0),
				handle: h1,
				stop:this.DragEnd.bind(this)
			});
		}

	},
	
	DestroyDraggable : function()
	{
		if (this.Draggable !== false)
		{
			// this.Draggable.destroy();
			this.Draggable.draggable("destroy");
			this.Draggable = false;
		}
	},
	
	DragEnd : function()
	{
		var wrapper_width = this.Wrapper.getWidth();
		var wrapper_x = this.Wrapper.getStyle('left').replace(/px/g,"") -0;
		var wrapper_y = this.Wrapper.getStyle('top').replace(/px/g,"") -0;
		
		var h1 = this.Wrapper.down("h1");
		var h1_width = h1.getWidth();
		var h1_height= h1.getHeight();
		var h1_offset = h1.positionedOffset();
		h1_offset.right = wrapper_width - h1_width - h1_offset.left;
		
		var viewport = document.viewport.getDimensions();
		
		var new_x = wrapper_x;
		var new_y = wrapper_y;
		
		
		if (wrapper_x+wrapper_width > viewport.width+h1_offset.right)
			new_x = viewport.width+h1_offset.right - wrapper_width;
		if (wrapper_x < -h1_offset.left)
			new_x = -h1_offset.left;
			
		if (wrapper_y > viewport.height-h1_offset.top-h1_height)
			new_y = viewport.height-h1_offset.top-h1_height;
		if (wrapper_y < -h1_offset.top)
			new_y = -h1_offset.top;
		
		if (wrapper_x != new_x || wrapper_y != new_y) {
			let oldTransition = this.Wrapper.getStyle('transition');
			this.Wrapper.setStyle({'transition': 'all .2s ease-in-out'});

			this.Wrapper.show().setStyle({
				'left' : new_x + 'px',
				// 'top' : '-' + (this.Wrapper.offsetHeight+50) + 'px',
				'top' : new_y + 'px',
			});
			setTimeout(() => {
				this.Wrapper.setStyle({'transition': oldTransition});
			}, 250);
		}
	},

	Promise: function(resolve, reject) {
		this.resolve = resolve;
		this.reject = reject;

		return this;
	},

	Resolve: function(data) {
		this.resolve(data);
	},
	Reject: function(data) {
		this.reject(data);
	}
});

// document.addEventListener('mousedown', function(event) {
// 	/** @type { HTMLElement } element */
// 	var element = event.target;
// 	if (element.classList.contains("drag-handle")) {
// 		var pos3 = event.clientX;
// 		var pos4 = event.clientY;
// 		let target = element.getAttribute("drag-target") || ".Popup";
// 		/** @type {HTMLElement} */
// 		var container = element.closest(target);
	
// 		var [, initial_left, initial_top] = container.style.transform.match(/translate\((-?\d+)px, ?(-?\d+)px\)/) || [0, 0, 0];
// 		// var initial_top = parseFloat(container.style.top);
// 		// var initial_left = parseFloat(container.style.left);

// 		event.preventDefault();
// 		document.addEventListener('mousemove', elementDrag);
// 		document.addEventListener('mouseup', () => {
// 			document.removeEventListener('mousemove', elementDrag);
// 		}, {once: true});
// 	}

// 	function elementDrag(event) {
// 		event.preventDefault();
// 		// calculate the new cursor position:
// 		let pos1 = +initial_left + event.clientX - pos3;
// 		let pos2 = +initial_top + event.clientY - pos4;
// 		// set the element's new position:
// 		// container.style.top = (initial_top - pos2) + "px";
// 		// container.style.left = (initial_left - pos1) + "px";	
// 		container.style.transform = `translate(${pos1}px, ${pos2}px)`;
// 	}
// })
// dragElement(document.getElementById("mydiv"));

// function dragElement(elmnt) {
//   var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
//   if (document.getElementById(elmnt.id + "header")) {
//     /* if present, the header is where you move the DIV from:*/
//     document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
//   } else {
//     /* otherwise, move the DIV from anywhere inside the DIV:*/
//     elmnt.onmousedown = dragMouseDown;
//   }

//   function dragMouseDown(e) {
//     e = e || window.event;
//     e.preventDefault();
//     // get the mouse cursor position at startup:
//     pos3 = e.clientX;
//     pos4 = e.clientY;
//     document.onmouseup = closeDragElement;
//     // call a function whenever the cursor moves:
//     document.onmousemove = elementDrag;
//   }

//   function elementDrag(e) {
//     e = e || window.event;
//     e.preventDefault();
//     // calculate the new cursor position:
//     pos1 = pos3 - e.clientX;
//     pos2 = pos4 - e.clientY;
//     pos3 = e.clientX;
//     pos4 = e.clientY;
//     // set the element's new position:
//     elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
//     elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
//   }

//   function closeDragElement() {
//     /* stop moving when mouse button is released:*/
//     document.onmouseup = null;
//     document.onmousemove = null;
//   }
// }