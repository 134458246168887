import "./node_modules/setimmediate/setImmediate.js";
import 'promise-polyfill/src/polyfill';

import "regenerator-runtime/runtime";

// jQuery
export { default as jQuery } from "jquery";
// @ts-ignore
import {} from "imports-loader?this=>window,define=>false,jQuery=jquery!./javascript/jquery-ui-1.12.1.js"; //eslint ignore
// @ts-ignore
import {} from "imports-loader?this=>window,define=>false,jQuery=jquery!./javascript/_jquery.ui.touch-punch.js";
// @ts-ignore
export * from "imports-loader?this=>window,define=>false,jQuery=jquery!./javascript/_jquery_sb-addons.js";
// export {
// 	default as Touche,
// 	// @ts-ignore
// } from "exports-loader?Touche!./javascript/_touche.js";
//
//
// Prototype og diverse
// @ts-ignore
export * from "Prototype";
// @ts-ignore
import {} from "./javascript/scripts2/_2_event.simulate.js";
export {
	default as Chosen,
	// @ts-ignore
} from "exports-loader?Chosen!imports-loader?this=>window,define=>false!./javascript/scripts2/_7_chosen.proto.js";
//
// Script.aculo.us
// export {
// 	default as Effect,
// } from "./javascript/scripts2/scriptaculous/_effects_full.js";
// export * from "exports-loader?Droppables,Draggables,Draggable,SortableObserver,Sortable!imports-loader?Effect=./_effects_full.js!./javascript/scripts2/scriptaculous/_dragdrop_full.js";
//
// Leaflet
// export { default as L } from "exports-loader?L!./leaflet/leaflet.js";
// export * from "./leaflet/leaflet-routing-machine.js";
// import {} from "./leaflet/leaflet.extra-markers.min.js";
export function loadLeaflet() {
	return import(
		/* webpackChunkName: "leaflet" */
		/* disable_webpackPrefetch: true */
		"./javascript/leaflet_build.js"
	);
}

// Selve versionens javascript
export * from "./javascript.es6.js";

export {default as fuzzysort} from "./javascript/fuzzysort.js";

import "./css.less";
import "./img/logo_180x180.png";
// import "./css-theme72.less";
// import "./css-cleanbiz.less";
// import "./css-leasebiz.less";

export function loadCodeMirror() {
	return import(
		/* webpackChunkName: "CodeMirror" */
		/* disable_webpackPrefetch: true */
		"./components/CodeMirrorLoader.js"
	);
}

export * from "./javascript/sb_modules/pages/index.js";

export { default as html2canvas } from "html2canvas";

// Hack til nogle android-enheder, som ikke fyrer et click af hvis der allerede er registreret et touchstart på et parent element.
// Her fanger vi det event i dets capturing phase, så vi kan nå at cancel det inden bubbling, hvor de andre events generelt lyttes på.
import { SB_Onload } from "./javascript.es6.js"
SB_Onload(()=>{
	if ('ontouchend' in document) {
		window.addEventListener("touchstart", function(evt) {
				let should_stop = evt.target.closest(".ui-sortable-handle input, .ui-sortable-handle [onclick], .ui-draggable-handle input, .ui-draggable-handle [onclick]");
				if (should_stop) {
					evt.stopImmediatePropagation();
				}
			}, {
			"capture": true,
			"passive": false,
		});
	}	
})