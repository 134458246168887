<script>
	export default {
		name: "transition-group-fade",
		functional: true,
		render: function (createElement, context) {
			var data = {
				props: {
					name: 'fade',
					mode: 'out-in'
				},
/*				on: {
					beforeEnter: function (el) {
						// console.log(el);
						var tmp = jQuery(el).clone(false);
						tmp.css({
							visibility: 'hidden',
							position: 'absolute',
						})
						tmp.insertAfter(jQuery(context.parent.$el));
						jQuery(el).height(tmp.height());
						tmp.remove();
					},
					afterEnter: function (el) {
						// console.log(el);
						jQuery(el).css({
							height: ""
						});
					},
					beforeLeave: function (el) {
						// console.log(el);
						jQuery(el).height(jQuery(el).height());
						return;
					},
					afterLeave: function (el) {
						// console.log(el);
						jQuery(el).css({
							height: ""
						});
					},
				},*/
			};
			return createElement('transition-group', data, context.children)
		}
	};
</script>
