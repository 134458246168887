<template>
	<span :key="_id" :class="_class" :title="_title" :style="{height: ($attrs.autosizeHeight ? 'initial' : '')}" @click="emit_click($event)">
		<div v-if="icon" style='position: absolute; left: 100%; margin-left:-3em; cursor: pointer; z-index: 1; width:4em;' >{{ icon }}</div>
		<label v-if="label" :class="position" :for='_for'><span v-if="locked"><i class="fa fa-lock fa-lg"></i>&nbsp;</span><span v-html="label"></span></label>
		<component :is="_input_type" :id="_id" :name="name" ref="input" v-bind="_bind" @input="change" :disabled="disabled || locked" :clearable="clearable" :closeOnSelect="closeOnSelect" @change="change" @focus="$emit('focus', $event)" @keydown="$emit('keydown', $event)" @blur="$emit('blur', $event)"></component>
		<span
			v-if="variable_hints"
			class='has-submenu'
			:data-popper-el='`#variable-hint-${name}`'
			style='position:absolute; top: .5em; right: .5em; display: inline-block; background: #dddddd; color: #333; height: 1.8em; line-height: 1.6em; border-radius: .3em; text-align: center; padding: 0 .3em; z-index: 100; cursor: pointer;'
			title='Vis variabler'
			v-html="'{{ }}'"
		></span>
		<div
			v-if="variable_hints"
			class='submenu' :id='`variable-hint-${name}`'
			style='display:none; position:absolute; z-index: 10000;'
			data-placement='bottom-end'
			data-boundaries-element='body'
		>
			<div
				class='mini-variable-hint'
			>
				<a
					v-for="(hint, key) in variable_hints"
					:key="key"
					class='button'
					:onclick="`insert_at_cursor(SB_Input('${name}').Input, '${key}', true); SB_Input('${name}').Input.click(); SB_Input('${name}').Input.focus()`"
					v-html="hint"
				></a>
			</div>
			<div class='popper__arrow' data-popper-arrow style='border-color: #ccc;'></div>
		</div>
	</span>
</template>
<script>
	export default {
		name: "sb-input",
		template: "#sb_input_template",
		inheritAttrs: false,
		props: {
			title: null,
			name: String,
			label: null,
			type: {
				default() {return "text"},
				validator: function(type) {
					switch (type) {
						case 'text':
						case 'textarea':
						case 'time':
						case 'timeseconds':
						case 'select':
						case 'radio':
						case 'date':
						case 'checkbox':
							return true;
						default:
							console.log(type);
							return false;
					}
				},
			},
			position: {
				default() {return "full"},
			},
			lock: {default: false},
			disabled: {default: false},
			clearable: {default: false},
			closeOnSelect: {
				default() {return false},
				type: Boolean,
			},
			variable_hints: {default: false},
			// placeholder: null,
			// checked: null,
			// options: null,
			// multiple: null,
			icon: null,
			value: null,
			_value: null,
		},
		model: {
			prop: '_value',
			event: 'input',
		},
		data: function(){
			return {
				"locked": false,
			};
		},
		beforeMount() {
			this.locked = this.lock;
		},
		methods: {
			change: function($event) {
				// console.log($event);
				if (typeof $event === "string" || typeof $event === "bool") {
					this.$emit('input', $event);
					this.$emit('change', $event);
				} else {
					if (this.type == "checkbox") {
						this.$emit('input', $event.target.checked);
						this.$emit('change', $event.target.checked);
					} else {
						this.$emit('input', $event.target.value);
						this.$emit('change', $event.target.value);
					}
				}
			},
			emit_click: function($event) {
				if (this.locked) {
					this.locked = false;
					$event.preventDefault();
					this.$emit('unlock');
				} else {
					this.$emit('click', $event)
				}
			}
		},
		computed: {
			_bind: function() {
				var _value = this._value;
				var value = this.value;
				var $attrs = this.$attrs;
				var type = this.type;
				if (type == "checkbox") {
					return Object.assign({
						checked: _value,
						value: value,
					}, $attrs);
				} else if (type == "radio") {
					return Object.assign({
						selected: _value == value,
						value: _value,
					}, $attrs);
				} else {
					var obj = {
						value: _value,
					};
					if (type == "textarea" && $attrs.autosizeHeight) {
						obj.autosizeHeight = $attrs.autosizeHeight;
					}
					return Object.assign(obj, $attrs);
				}
			},
			_class: function() {
				var classes = ['FormElement'];
				if (!this.$attrs["input-style"]) {
					classes.push('WrapLabel');
				} else {
					// if (this.$attrs["input-style"].match(/iPad/)) {
					// 	classes.push('iPad');
					// }
					// if (this.$attrs["input-style"].match(/WrapLabel/)) {
					// 	classes.push('WrapLabel');
					// }
					classes.push(this.$attrs["input-style"]);
				}
				if (this.label) {
					classes.push('haslabel');
				} else {
					classes.push('nolabel');
				}
				this.position && classes.push(this.position);
				(this.disabled || this.locked) && classes.push('disabled');
				this.lock && classes.push('lock');
				this.clearable && classes.push('clearable');
				switch (this.type) {
					case "select":
						classes.push('select', 'chosen-select');
					break;
					case "text":
						classes.push('text');
					break;
					default:
						classes.push(this.type);
					break;
				}
				return classes;
			},
			_title: function() {
				return this.title ? this.title : this.label;
			},
			_id: function() {
				return "input[" + this.name + "]";
			},
			_for: function() {
				switch (this.type) {
					case 'time':
						return 'input[' + this.name + '[hours]]';
					case 'radio':
						return 'input[' + this.name + '][' + this.value + ']';
					default:
						return this._id;
				}
			},
			_input_type: function() {
				switch (this.type) {
					case 'select':
						return 'chosen-select';
					case 'checkbox':
						return 'sb-input-checkbox';
					case 'radio':
						return 'sb-input-radio';
					case 'date':
						return 'sb-input-date';
					case 'textarea':
						return 'sb-input-textarea';
					case 'time':
						return 'sb-input-time';
					default:
						return 'sb-input-text';
				}
			}
		},
	};
</script>