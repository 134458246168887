/* global setImmediate L */

// export * from './leaflet/leaflet-src.js';
// export * from './leaflet/leaflet-routing-machine.js';
// export * from './leaflet/leaflet.extra-markers.min.js';

import { Class } from "Prototype";
export * from "./javascript/sb_modules/math.es6.js";
import {
	SB_Timestamp,
	SB_WindowOpenAsPost,
	SB_WindowRedirect,
	SB_Onload,
	Cookie,
} from "./javascript/sb_modules/common_functions.es6.js";
export * from "./javascript/sb_modules/common_functions.es6.js";
import {
	SB_Confirm,
	SB_Alert,
	SB_Dialog,
	SB_Input,
} from "./javascript/sb_modules/ui_elements.es6.js";
export * from "./javascript/sb_modules/ui_elements.es6.js";
import { GlobalEvent } from "./javascript/sb_modules/events.es6.js";
SB_Onload(GlobalEvent.initialize);
export * from "./javascript/sb_modules/events.es6.js";

export * from "./javascript/sb_modules/sms.es6.js";
export * from "./javascript/sb_modules/calendar.es6.js";
export * from "./javascript/sb_modules/message_stack.es6.js";
import { SB_List } from "./javascript/sb_modules/list.es6.js";
export * from "./javascript/sb_modules/list.es6.js";
export * from "./javascript/sb_modules/main_menu.es6.js";
// import {SB_MainMenu} from "./javascript/sb_modules/popup.es6.js";
import { SB_Popup } from "./javascript/sb_modules/popup.es6.js";
export * from "./javascript/sb_modules/popup.es6.js";
import { SB_Ajax } from "./javascript/sb_modules/ajax.es6.js";
export * from "./javascript/sb_modules/ajax.es6.js";
export * from "./javascript/sb_modules/tourtip.es6.js";
import { Vue } from "./javascript/sb_modules/vue.es6.js";
export * from "./javascript/sb_modules/vue.es6.js";
export * from "./javascript/bootstrap-tooltip.es6.js";

/* global VERSION, COMMITHASH, BRANCH, SB_ENVIRONMENT, SB_USER_ID, SB_USER_NAME */
export const SB = {
	// @ts-ignore
	git_version: typeof VERSION !== "undefined" ? VERSION : 'unknown',
	// @ts-ignore
	commithash: typeof COMMITHASH !== "undefined" ? COMMITHASH : 'unknown',
	// @ts-ignore
	branch: typeof BRANCH !== "undefined" ? BRANCH : 'unknown',
	// @ts-ignore
	environment: typeof SB_ENVIRONMENT !== "undefined" ? SB_ENVIRONMENT : 'unknown',
	user: {
		// @ts-ignore
		id: typeof SB_USER_ID !== "undefined" ? SB_USER_ID : 'unknown',
		// @ts-ignore
		name: typeof SB_USER_NAME !== "undefined" ? SB_USER_NAME : 'unknown',
	},
}

// import * as Sentry from '@sentry/browser';
// import * as Integrations from '@sentry/integrations';
// if (process.env.NODE_ENV !== "development") {
// 	Sentry.init({
// 		dsn: 'https://c39870a7fb784be98d340cfc6b735148@sentry.gitbiz.dk:8443/2',
// 		release: SB.git_version,
// 		environment: SB.environment,
// 		integrations: [new Integrations.Vue({Vue, logErrors: true})],
// 	});
// 	Sentry.setUser({
// 		id: SB.user.id,
// 		username: SB.user.name,
// 	});
// 	Sentry.setTag("branch", SB.branch);
// }
// export {Sentry};

export var SB_TouchDevice = false; // Sættes i php

// Event.observe(document,'dom:loaded',function() {
	
// 	$(document.body).on("blur","input",function(event, element){
// 		// console.log("test");
// 		element.value = SB_CalcField(element.value);
// 	});

// });




var SB_LogoutTimer = false; // eslint-disable-line no-unused-vars

export function SB_InitLogoutTimer()
{
	SB_LogoutTimer = setInterval(SB_LogoutCheck,5000);
}

/* global server_time_offset SB_SessionTTL SB_UrlDir */
function SB_LogoutCheck() {
	var timeout = Cookie.get('timeout');
	if (timeout==null || timeout == -1)
		return false;
	
	timeout = Number(timeout-0) + server_time_offset;
	let timestamp = SB_Timestamp();
	
	var warned = (typeof(SB_Popup.Popups["logout-warning"])!="undefined");
	
	if (timestamp < timeout + SB_SessionTTL - 300)
	{
		if (warned)
			SB_Popup.Popups["logout-warning"].Remove();
	}
	else if (!warned)
	{
		SB_Dialog({
			"id" 		: "logout-warning",
			"title" 	: "Advarsel",
			"text" 		: "Det ser ud til, at du har været inaktiv i SkvizBiz i længere tid.<br /><br />Af sikkerhedsmæssige årsager vil du automatisk blive logget ud om kort tid. Klik på &quot;Forbliv logget ind&quot; inden for 5 minutter for at forblive logget ind.",
			"buttons"	: [{"label" : "Log ud nu", "className" : "dialog-button", "fnc" : function() {new SB_Ajax("login_update.php","action=Logout").Request();}, "width" : 140}, {"label" : "Forbliv logget ind", "className" : "dialog-button-ok", "fnc" : SB_ResetLogoutTimer, "width" : 190}]
		});
		window.focus();
	}
	
	if (timestamp > timeout + SB_SessionTTL)
	{
		SB_WindowRedirect(SB_UrlDir + 'login.php?action=timeout&oncemore=1');
	}
}

function SB_ResetLogoutTimer()
{
	new SB_Ajax("global_ajax.php","action=Refresh").Request();
}






/* PHP.JS */

export var GetFromCVR = function(configObject){
	// cvr = cvr.replace(/[^0-9]/,"");
	let cvr = '';

	if(configObject.input)
		cvr = SB_Input(configObject.input).value();
	else
		cvr = configObject.cvr;

	new SB_Ajax(`https://cvrapi.dk/api?country=dk&vat=${cvr}`,{
	}).setIgnoreDefaultFailure().Request().then(function(transport){
		let json = transport.responseJSON;
		if(!json || !json.name){
			SB_Alert("CVR-nummeret blev ikke fundet.<br><br>Muligvis er firmaet helt nyoprettet, og derfor ikke at finde hos CVRapi endnu, eller også er det under konkurs/opløsning.<br><br>Prøv eventuelt at læse mere hos cvr.dk her:<br><a href='https://datacvr.virk.dk/soegeresultater?fritekst="+cvr+"' style='color: black;'>"+cvr+"</a>");
			return;
		}

		["name","address","city","zipcode","phone","email"].each(function(property){
			let targets = [];
			if(typeof configObject[property] != "object"){
				targets = [configObject[property]];
			}
			else if(configObject[property].length > 0){
				targets = configObject[property];
			}
			targets.each(function(target){
				if(target && !SB_Input(target).value())
					SB_Input(target).update(json[property]);
			});
		});
	});
};


( function(window) {
	//	"use strict";

	var hash = (function() {

		var fromHash = function() {
			var params = window.location.hash ? window.location.hash.substr(1).split("&") : [],
				paramsObject = {};

			for(var i = 0; i < params.length; i++) {
				var a = params[i].split("=");
				paramsObject[a[0]] =  decodeURIComponent(a[1]);
				if(paramsObject[a[0]][0] == ":")
					paramsObject[a[0]] = paramsObject[a[0]].substring(1).evalJSON();
			}
			return paramsObject;
		};

		var toHash = function(params) {
			var str = [];
			for(var p in params) {
				if(typeof params[p] == "object")
					params[p] = ":"+Object.toJSON(params[p]);
				str.push(p + "=" + encodeURIComponent(params[p]));
			}
			window.location.hash = str.join("&");
		};

		return {
			get: function(param) {
				var params = fromHash();
				if (param) {
					return params[param];
				} else {
					return params;
				}
			},
			add: function(newParams) {
				var params = fromHash();
				// if (typeof newParams["_each"] == "function"){
				// 	newParams.each(function(parm){
				// 		params.push(parm);
				// 	});
				// }
				// else{
					for (var p in newParams) {
						params[p] = newParams[p];
					}
				// }
				toHash(params);
			},
			remove: function(removeParams) {
				removeParams = (typeof(removeParams)=='string') ? [removeParams] : removeParams;
				var params = fromHash();
				for (var i = 0; i < removeParams.length; i++) {
					delete params[removeParams[i]];
				}
				toHash(params);
			},
			clear: function() {
				toHash({});
			}
		};
	})();

	window.hash = hash;

	var callstack = (function() {

		var executing = false;

		return {
			add: function(newParams) {
				if(executing == true) return;
				var params = hash.get("_cs") || [];
				params.push(newParams);
				hash.add({"_cs":params});
			},
			addThis: function(){
				var func = arguments.callee.caller.name;
				var args = $A(arguments.callee.caller.arguments);

				var params = hash.get("_cs") || [];
				for (var p = 0; p < params.length; p++){
					if(typeof params[p][func] != "undefined")
						return;
				}

				let tmp = {};
				tmp[func] = args;
				this.add(tmp);
			},
			remove: function(removeParams){
				if(executing == true) return;
				removeParams = (typeof(removeParams)=='string') ? [removeParams] : removeParams;
				var params = hash.get("_cs") || [];
				for (var i = 0; i < removeParams.length; i++) {
					for (var p = 0; p < params.length; p++){
						if(typeof params[p][removeParams[i]] != "undefined")
							delete params[p];
					}
					// delete params[removeParams[i]];
				}
				if(Object.keys(params).length == 0)
					hash.remove("_cs");
				else
					hash.add({"_cs":params});
			},
			clear: function(){
				if(executing == true) return;
				hash.remove("_cs");
			},
			execute: function(){
				executing = true;

				var functions = hash.get("_cs") || [];
				for (var p = 0; p < functions.length; p++){

					let params;
					for (var f in functions[p])
					{
						var fName = f;
						params = functions[p][f];
					}
					var func = window;
					var objs = fName.split(".");
					for (var i = 0; i < objs.length; i++){
						func = func[objs[i]];
					}
					if (typeof func == "function"){
						params = typeof params == "string" ? [params] : params;
						func.apply(window, params);
					}
				}

				executing = false;
			}
		};
	})();

	window.callstack = callstack;
})(window);

SB_Onload(window.callstack.execute);


var menuActive = false;

Event.observe(document,'dom:loaded',function() {
	jQuery(document).on("click", "#navbar-toggle", function(/* el */) {
		menuActive ? Menu.hide() : Menu.show();
		return false;
	});
	jQuery(document).on("click", "#sidebar-toggle", function(/* el */) {
		$(document.body).toggleClassName("sidebar-active");
		return false;
	});
	// if ($navbar = $('navbar-toggle')) {
	// 	$navbar.observe("click",function(evt){
	// 		menuActive ? Menu.hide() : Menu.show();
	// 		evt.stop();
	// 	});
	// }
	// if ($sidebar = $('sidebar-toggle')) {
	// 	$sidebar.observe("click",function(evt){
	// 		$(document.body).toggleClassName("sidebar-active");
	// 		evt.stop();
	// 	});
	// }
});

document.observe("sb:popup.initialized",function(){
	$(document.body).removeClassName("sidebar-active");
});


var Menu = {
	hide: function(){
		if(menuActive){
			$(document.body).removeClassName('menu-active');
			menuActive = false;
		}
	},
	
	show: function(){
		if(!menuActive){
			$(document.body).addClassName('menu-active');
			menuActive = true;
		}
	},
}

export function show_terms(type)
{
	// var type = type;
	new SB_Ajax("global_ajax.php",{
		action: "get_terms",
		type: type
	}).Request()
	.then(function(transport){
		var terms = "";
		terms = transport.responseText;
		SB_Dialog({id:type,text:terms,buttons:[{'label' : 'OK', 'className' : 'dialog-button-ok'}],width:500});
	});
}

export function confirm_subscription(payment_link, deals){
	if(!payment_link)
		return false;

	let deal_form = "<br><form id='subscription_deals' method='post'><h2>Disse aftaler vil blive tilmeldt:</h2>";
	for(let i=0; i<deals.length; i++)
	{
		if(i) deal_form += "<br>";
		deal_form += "<input type='checkbox' checked disabled name='deals[]' id='subscription_deal_'"+deals[i].id+"'' value='"+deals[i].id+"'><label>"+deals[i].description+"</label>";
	}
	deal_form += "</form>";

	let fnc_ok = function(){
		if($('confirm_subscription').getValue())
			SB_WindowOpenAsPost(payment_link/*+"&"+($('subscription_deals').serialize())*/);
	};
	SB_Dialog({
		"id" 		: "confirm-"+SB_Timestamp(),
		"title" 	: "Bekræft venligst",
		"text"		: "<input type='checkbox' id='confirm_subscription' onchange=\"var ok_button = $(this).up('.Dialog').down('.dialog-button-ok'); if(ok_button.disabled){ok_button.enable()}else{ok_button.disable()}\"/><label for='confirm_subscription'> Jeg accepterer <a href=\"#\" onclick=\"show_terms('subscription_terms'); return false;\">vilkårene for automatisk betaling</a>, samt <a href=\"#\" onclick=\"show_terms('trading_conditions'); return false;\">handelsbetingelserne</a> og de gældende priser, som jeg bekræfter at jeg er informeret om.</label>"+deal_form,
		"buttons"	: [{"label" : "Annuller"},{"label" : "OK", "className" : "dialog-button-ok confirm-subscription", "fnc" : fnc_ok}]
	});
	$$(".confirm-subscription")[0].disable();
}


export var addressAutocomplete = Class.create({
	options: {
		jsonp: !("withCredentials" in (new XMLHttpRequest())),
		baseUrl: 'https://api.dataforsyningen.dk',
		minLength: 2,
		delay: 0,
		adgangsadresserOnly: false,
		autoFocus: true,
		timeout: 10000,
		error: null,
		params: {},
		fuzzy: true,
		stormodtagerpostnumre: true,
		startfra: null,
		adgangsadresseid: null
	},
	cache: {},

	initialize: function(element, options){
		let self = this;

		options.input = SB_Input(element);
		element = $(SB_Input(element).Input);
		this.options = Object.extend(this.options, options);

		this.list = new SB_List(element.identify()+"_autocomplete", self.options.baseUrl + '/autocomplete');
		this.list
			.SetAutoComplete(element.identify())
			.SetAllowHighlight(true)
			.AddEvent("blur", function(/* event */) {
				self.options.startfra = null;
				self.options.adgangsadresseid = null;
			})
			.AddEvent("enter", function(event){
				if(self.list.Highlighted.length != 1) {
					let inputs = jQuery(':input:visible');
					let nextInput = inputs.get(inputs.index(self.options.input.Input) + (event.shiftKey ? -1 : 1));
					if (nextInput) {
						nextInput.focus();
					}
					return true;
				}
				let data = JSON.parse(this.Highlighted[0]);
				event.Stopped = true;
				event.stop();
				var ret = self.onSelect(data);
				if(ret) {
					let inputs = jQuery(':input:visible');
					let nextInput = inputs.get(inputs.index(self.options.input.Input) + (event.shiftKey ? -1 : 1));
					if (nextInput) {
						nextInput.focus();
					}
				}
				return false;
			})
			.AddEvent("mousedown", function(event){
				if(self.list.Highlighted.length != 1) {
					let inputs = jQuery(':input:visible');
					let nextInput = inputs.get(inputs.index(self.options.input.Input) + (event.shiftKey ? -1 : 1));
					if (nextInput) {
						nextInput.focus();
					}
					return true;
				}
				let data = JSON.parse(this.Highlighted[0]);
				event.Stopped = true;
				event.stop();
				var ret = self.onSelect(data);
				if(ret) {
					let inputs = jQuery(':input:visible');
					let nextInput = inputs.get(inputs.index(self.options.input.Input) + (event.shiftKey ? -1 : 1));
					if (nextInput) {
						nextInput.focus();
					}
				}
				return false;
			});


		this.list.CustomAjaxFunction = self.ajaxFunction.bind(this);

		return this;
	},

	update_search_box: function(data){
		// console.log(data);
		var html = this.generate_list_from_json(data);
		this.list.Container.update(html);
		this.list.Refreshed.bind(this.list)();
		// this.list.Container.update(JSON.stringify(data));
	},

	generate_list_from_json: function(data) {
		var wrapper = (new Element("div")).addClassName("ContentWrapper");
		var div = (new Element("div")).addClassName("List");
		wrapper.insert(div);
		var table = (new Element("table")).writeAttribute({
			"border":"0",
			"cellspacing":"0",
			"cellpadding":"0",
			"onselectstart":"return false;",
		});
		div.insert(table);
		for(let i = 0; i<data.length; i++)
		{
			var data_row = data[i];
			var tbody = (new Element("tbody")).writeAttribute("rowid",JSON.stringify(data_row));
			table.insert(tbody);
			var tr = (new Element("tr"));
			tbody.insert(tr);
			var td = (new Element("td")).update(data_row.forslagstekst);
			tr.insert(td);
		}
		var h = Math.min(data.length, 8) * 25;
		// console.log(this);
		this.list.Container.setStyle({"height": h+"px"});
		return wrapper;
	},

	get_search_text: function() {
		var self = self || this;
		var q = self.list.AutoComplete.getValue();
		// Hvis der står noget i postnr eller by, så føjer vi det på
		if(self.options.zipcodeElement && SB_Input(self.options.zipcodeElement).value()) {
			q += ", " + SB_Input(self.options.zipcodeElement).value();
			if(self.options.cityElement && SB_Input(self.options.cityElement).value())
				q += " " + SB_Input(self.options.cityElement).value();
		} else if (self.options.cityElement && SB_Input(self.options.cityElement).value()) {
			q += ", " + SB_Input(self.options.cityElement).value();
		}
		return q;
	},

	ajaxFunction: function(context, url){
		// Hvis der ikke står noget i inputfeltet, så slukker vi søgeboksen og foretager os ikke noget.
		if(!this.list.AutoComplete.getValue())
		{
			this.list.Container.hide();
			return;
		}
		// ...ellers viser vi den.
		this.list.Container.show();
		let params = {
			q:this.get_search_text(), //this.list.AutoComplete.getValue(),
			fuzzy: this.options.fuzzy,
		};

		// starfra låser søgningen til enten at søge i vejnavne, adgangsadresser eller adresser
		if(this.options.startfra)
			params.startfra = this.options.startfra;

		// adgangsadresseid låser søgningen til kun at søge adresser med et bestemt adgangspunkt (f.eks. frydsvej 9)
		if(this.options.adgangsadresseid)
			params.adgangsadresseid = this.options.adgangsadresseid;

		// Hvis der står noget i postnr eller by, så føjer vi det på
		// if(this.options.zipcodeElement && SB_Input(this.options.zipcodeElement).value()) {
		// 	params.q += ", " + SB_Input(this.options.zipcodeElement).value();
		// 	if(this.options.cityElement && SB_Input(this.options.cityElement).value())
		// 		params.q += " " + SB_Input(this.options.cityElement).value();
		// } else if (this.options.cityElement && SB_Input(this.options.cityElement).value()) {
		// 	params.q += ", " + SB_Input(this.options.cityElement).value();
		// }

		// Så laver vi en henvisning til update_search_box som er vores callback der laver listen ud fra json-svaret.
		let cb = this.update_search_box.bind(this);

		// Vi tjekker om vi allerede har fået svar fra serveren på denne søgning, og returnerer i så fald det.
		var stringifiedParams = JSON.stringify(params);
		if (this.cache[stringifiedParams]) {
			return cb(this.cache[stringifiedParams]);
		}

		// Ellers laver vi en forespørgsel hos AWS.dk
		/* let ajax =  */new Ajax.CorsRequest(url, {
			method: "GET",
			parameters: params,
			onSuccess: function(transport){
				let json = transport.responseJSON;
				// ...og cacher svaret.
				this.cache[stringifiedParams] = json;
				// Tjek lige, at der ikke er tastet videre i søgefeltet, inden vi fyrer callback'et af.
				if(transport.request.parameters.q == this.get_search_text())
					cb(json);
			}.bind(this),
		});
	},

	onSelect: function(data) {
		let self = this;
		if ("vejnavn" == data.type) {
			self.options.startfra = 'adgangsadresse';
			self.options.input.update(data.tekst);
			// setTimeout(function(){
			// 	self.options.input.activate();
			// }, 10);
			return false;
		}
		var changed = false;
		if(self.options.zipcodeElement && SB_Input(self.options.zipcodeElement).value() != data.data.postnr) {
			changed = true;
			SB_Input(self.options.zipcodeElement).update(data.data.postnr);
		}
		if(self.options.cityElement && SB_Input(self.options.cityElement).value() != data.data.postnrnavn) {
			changed = true;
			SB_Input(self.options.cityElement).update(data.data.postnrnavn);
		}
		if(self.options.address2 && SB_Input(self.options.address2).value() != data.data.supplerendebynavn) {
			changed = true;
			SB_Input(self.options.address2).update(data.data.supplerendebynavn);
		}

		if(self.options.latElement && self.options.lonElement && self.options.latlonStatusElement)
		{

			/* var ajax =  */new Ajax.CorsRequest(data.data.href.replace("http:",""), {
				method: "GET",
				onSuccess: function(transport){
					let json = transport.responseJSON;
					let coords;
					if(json.adgangspunkt)
						coords = json.adgangspunkt.koordinater;
					else if(json.adgangsadresse)
						coords = json.adgangsadresse.adgangspunkt.koordinater;

					if(self.options.latElement && self.options.lonElement) {
						if(
							SB_Input(self.options.latlonStatusElement).value() == "auto"
							|| (SB_Input(self.options.latElement).value() == "" && SB_Input(self.options.lonElement).value() == "")
						) {
							SB_Input(self.options.latElement).update(coords[1]);
							SB_Input(self.options.lonElement).update(coords[0]);
							SB_Input(self.options.latlonStatusElement).update("auto");
						} else if (SB_Input(self.options.latElement).value() != coords[1] && SB_Input(self.options.lonElement).value() != coords[0]) {
							SB_Confirm("Ønsker du at opdatere kundens placering på kortet ud fra den nye adresse?", function(){
								SB_Input(self.options.latElement).update(coords[1]);
								SB_Input(self.options.lonElement).update(coords[0]);
								SB_Input(self.options.latlonStatusElement).update("auto");
							});
						}
					}
				}.bind(self),
			});
		}

		let result = data.data.vejnavn + " " + data.data.husnr;
		if(data.type == "adresse") {
			if(data.data.etage + data.data["dør"])
			{
				result += ",";
				if(data.data.etage)
					result += " "+data.data.etage + ".";
				if(data.data["dør"])
					result += " "+data.data["dør"];
			}
		}
		if(self.options.input.value() != result) {
			changed = true;
			self.options.input.update(result);
		}

		if(data.type == "adgangsadresse" && changed) {
			self.options.adgangsadresseid = data.data.id;
			self.options.input.blur();
			setImmediate(function() {
				self.options.input.activate();
			});

		} else if (self.options.nextInput) {
			return true;
			// setTimeout(function(){
			// 	SB_Input(self.options.nextInput).activate();
			// }, 10);
		}

		return false;

		// console.log(data);
		// console.log(data.data.postnr);
		// console.log(data.data.postnrnavn);
	}
});



export function SB_OptimizeDay(customers, sortable_id)
{
	if (customers.split(",").length > 400) {
		SB_Alert("Ruter med mere end 400 kunder kan desværre ikke optimeres automatisk.");
		return false;
	}
	SB_Dialog({
		"id" 		: "optimize-route-lock-first-dialog",
		"title"		: "Lås første kunde",
		"text"		: "Du har mulighed for at låse den første kunde på ruten, så du selv vælger udgangspunktet for optimeringen.<br><br>Hvis du vælger ikke at låse første kunde, vil systemet søge efter den hurtigst mulige rækkefølge.",
		"buttons"	: 
			[
				{
					"label"		: "Optimer hele ruten",
					"className"	: "dialog-button",
					"fnc"		: function() {
						SB_Confirm("Hvis du forsøger at optimere rækkefølgen, vil de ændringer, du har lavet, IKKE blive gemt. Vil du fortsætte alligevel?", function() {
							new SB_Ajax('route_edit_ajax.php', {
							'action' : 'Optimize',
							'customers' : customers,
							'lock_first' : false
						}).AddEvent('onSuccess', function(response) {
							var json = JSON.parse(response.responseText);
							jQuery('#'+sortable_id).sortable('instance').setOrder(json.order)
						}).Request();
					}, false);
					},
					"width" 	: false
				},
				{
					"label"		: "Lås første kunde",
					"className"	: "dialog-button",
					"fnc"		: function() {
						SB_Confirm("Hvis du forsøger at optimere rækkefølgen, vil de ændringer, du har lavet, IKKE blive gemt. Vil du fortsætte alligevel?", function() {
							new SB_Ajax('route_edit_ajax.php', {
							'action' : 'Optimize',
							'customers' : customers,
							'lock_first' : true
						}).AddEvent('onSuccess', function(response) {
							var json = JSON.parse(response.responseText);
							jQuery('#'+sortable_id).sortable('instance').setOrder(json.order)
						}).Request();
					}, false);
					},
					"width" 	: false
				}
			],
		"width"		: false
	});
}
export function SB_OptimizeRoute(customers, sortable_id)
{
	if (customers.split(",").length > 400) {
		SB_Alert("Ruter med mere end 400 kunder kan desværre ikke optimeres automatisk.");
		return false;
	}
	SB_Dialog({
		"id" 		: "optimize-route-lock-first-dialog",
		"title"		: "Lås første kunde",
		"text"		: "Du har mulighed for at låse den første kunde på ruten, så du selv vælger udgangspunktet for optimeringen.<br><br>Hvis du vælger ikke at låse første kunde, vil systemet søge efter den hurtigst mulige rækkefølge.",
		"buttons"	: 
			[
				{
					"label"		: "Optimer hele ruten",
					"className"	: "dialog-button",
					"fnc"		: function() {
						SB_Confirm("Hvis du forsøger at optimere rækkefølgen, vil de ændringer, du har lavet, IKKE blive gemt. Vil du fortsætte alligevel?", function() {
							new SB_Ajax('route_edit_ajax.php', {
							'action' : 'Optimize',
							'customers' : customers,
							'lock_first' : false
						}).AddEvent('onSuccess', function(response) {
							var json = JSON.parse(response.responseText);
							json.order = json.order.map(val => `${val}`);
							customers.split(",").reverse().forEach(val => {
								if (json.order.indexOf(`${val}`) === -1) {
									json.order.unshift(`${val}`);
								}
							});
							jQuery('#'+sortable_id).sortable('instance').setOrderStable(json.order)
						}).Request();
					}, false);
					},
					"width" 	: false
				},
				{
					"label"		: "Lås første kunde",
					"className"	: "dialog-button",
					"fnc"		: function() {
						SB_Confirm("Hvis du forsøger at optimere rækkefølgen, vil de ændringer, du har lavet, IKKE blive gemt. Vil du fortsætte alligevel?", function() {
							new SB_Ajax('route_edit_ajax.php', {
							'action' : 'Optimize',
							'customers' : customers,
							'lock_first' : true
						}).AddEvent('onSuccess', function(response) {
							var json = JSON.parse(response.responseText);
							json.order = json.order.map(val => `${val}`);
							customers.split(",").reverse().forEach(val => {
								if (json.order.indexOf(`${val}`) === -1) {
									json.order.unshift(`${val}`);
								}
							});
							jQuery('#'+sortable_id).sortable('instance').setOrderStable(json.order)
						}).Request();
					}, false);
					},
					"width" 	: false
				}
			],
		"width"		: false
	});
}

export function SB_PlaceCustomerOnRoute(customers, new_customer, sortable_id)
{
	customers = customers.split(",");

	if (customers.length > 400) {
		SB_Alert("Kunder kan ikke placeres automatisk på ruter med mere end 400 kunder.");
		return false;
	}

	customers = jQuery.grep(customers, function(value) {
		return value != new_customer;
	});
	customers.unshift(new_customer);
	customers = customers.join(",");
	new SB_Ajax('route_edit_ajax.php', {
		'action' : 'PlaceCustomerOnRoute',
		'customers' : customers
	}).AddEvent('onSuccess', function(response) {
		var json = JSON.parse(response.responseText);
		// console.log(json);
		jQuery('#'+sortable_id).sortable('instance').setOrder(json.order)
	}).Request();
}

