import { SB_Onload } from "./common_functions.es6.js";

import Vue from 'vue';
Vue.config.devtools = true;
export {Vue};

import Vuex from 'vuex';
Vue.use(Vuex);
export {Vuex};

export var VueSharedStore = function (_ref) {
	var DEFAULT_SHARING_KEY = 'vuex-mutations-sharer';
	var predicate = _ref.predicate,
		sharingKey = _ref.sharingKey;
	return function (store) {
		if (typeof window === 'undefined' || !window.localStorage) {
			console.error('[vuex-shared-mutations] localStorage is not available. Disabling plugin');
			return;
		}

		if (typeof predicate !== 'function' && !Array.isArray(predicate)) {
			console.error('[vuex-shared-mutations] Predicate should be either array of mutation names or function. Disabling plugin');
			return;
		}

		try {
			window.localStorage.setItem('vuex-mutations-sharer__test', 'test');
			window.localStorage.removeItem('vuex-mutations-sharer__test');
		} catch (e) {
			console.error('[vuex-shared-mutations] Unable to use setItem on localStorage. Disabling plugin');
			return;
		}

		var committing = false;
		var key = sharingKey || DEFAULT_SHARING_KEY;

		var shouldShare = typeof predicate === 'function' ? predicate : function (mutation) {
			return predicate.indexOf(mutation.type) !== -1;
		};

		store.subscribe(function (mutation) {
			if (committing) return;
			if (shouldShare(mutation)) {
				try {
					window.localStorage.setItem(key, JSON.stringify(mutation));
				} catch (e) {
					console.error('[vuex-shared-mutations] Unable to use setItem on localStorage');
					console.error(e);
				}
			}
		});

		window.addEventListener('storage', function (event) {
			if (event.key === key) {
				try {
					var mutation = JSON.parse(event.newValue);
					committing = true;
					store.commit(mutation.type, mutation.payload);
				} catch (error) {
					console.error('[vuex-shared-mutations] Unable to parse shared mutation data');
					console.error(event.newValue, error);
				} finally {
					committing = false;
				}
			}
		});
	};
};

import {filter_object} from "./common_functions.es6";
export var VueStore = new Vuex.Store({
	plugins: [
		VueSharedStore({predicate: ['messages', 'message_count', 'unread_message_count', 'notifications','notification']}),
	],
	state: {
		messages: {},
		message_count: 0,
		unread_message_count: 0,
		notifications: {},
	},
	mutations: {
		messages: function(state, messages) {
			state.messages = messages;
			state.message_count = Object.keys(state.messages).length;
			state.unread_message_count = Object.keys(filter_object(state.messages, function(msg){return msg.status=="unread"})).length;
		},
		message_count: function(state, message_count) {
			state.message_count = message_count;
		},
		unread_message_count: function(state, unread_message_count) {
			state.unread_message_count = unread_message_count;
		},
		notifications: function(state, notifications) {
			state.notifications = notifications;
			try {
				sessionStorage.setItem("notifications", JSON.stringify(state.notifications));
			} catch (error) {
				// Hvis det fejler, er det sandsynligvis fordi vi er i private browsing mode.
				// Se evt. https://stackoverflow.com/questions/21159301/quotaexceedederror-dom-exception-22-an-attempt-was-made-to-add-something-to-st
			}
		},
		notification: function(state, notifications) {
			for (let i in notifications) {
				var item = notifications[i];
				if (item == false || item == null) {
					Vue.delete(state.notifications, i);
				} else {
					if (state.notifications === null) {
						state.notifications = {};
					}
					Vue.set(state.notifications, i, notifications[i]);
				}
			}
			try {
				sessionStorage.setItem("notifications", JSON.stringify(state.notifications));
			} catch (error) {
				// Hvis det fejler, er det sandsynligvis fordi vi er i private browsing mode.
				// Se evt. https://stackoverflow.com/questions/21159301/quotaexceedederror-dom-exception-22-an-attempt-was-made-to-add-something-to-st
			}
		},
	},
	getters: {
		notificationCount: function(state, getters) {
			return Object.keys(state.notifications || {}).length;
		},
		notifications: function(state, getters) {
			var arr = [];
			for (let x in state.notifications) {
				if (!Object.prototype.hasOwnProperty.call(state.notifications, x)) continue;
				var obj = state.notifications[x];
				obj.name = x;
				arr.push(obj);
			}
			return arr.sort(function(a, b) {
				if (a.sort < b.sort) return -1;
				if (a.sort > b.sort) return 1;
				return 0;
			});
		},
	}
});

if (sessionStorage.key("notifications")) {
	try {
		var notifications = JSON.parse(sessionStorage.getItem("notifications"));
		VueStore.commit("notifications", notifications);
	} catch (err) {
		console.error(err);
	}
}

Vue.component('transition-group-expand', require( /* webpackChunkName: "transition-group-expand" */ "../vue-components/transition-group-expand.vue").default);
Vue.component('transition-expand', require( /* webpackChunkName: "transition-expand" */ "../vue-components/transition-expand.vue").default);
Vue.component('transition-group-fade', require( /* webpackChunkName: "transition-group-fade" */ "../vue-components/transition-group-fade.vue").default);
Vue.component('transition-fade', require( /* webpackChunkName: "transition-fade" */ "../vue-components/transition-fade.vue").default);

Vue.component('chosen-select', require( /* webpackChunkName: "chosen-select" */ "../vue-components/chosen-select.vue").default);
Vue.component('sb-list', require( /* webpackChunkName: "sb-list" */ "../vue-components/sb-list.vue").default);
Vue.component('sb-input-text', require( /* webpackChunkName: "sb-input-text" */ "../vue-components/sb-input-text.vue").default);
Vue.component('sb-input-textarea', require( /* webpackChunkName: "sb-input-textarea" */ "../vue-components/sb-input-textarea.vue").default);
Vue.component('sb-input-date', require( /* webpackChunkName: "sb-input-date" */ "../vue-components/sb-input-date.vue").default);
Vue.component('sb-input-checkbox', require( /* webpackChunkName: "sb-input-checkbox" */ "../vue-components/sb-input-checkbox.vue").default);
Vue.component('sb-input', require( /* webpackChunkName: "sb-input" */ "../vue-components/sb-input.vue").default);
Vue.component('tokenized-search', require( /* webpackChunkName: "tokenized-search" */ "../vue-components/tokenized-search.vue").default);

Vue.component('expand-bar', () => import( /* webpackChunkName: "expand-bar" */ "../vue-components/expand-bar.vue"));
Vue.component('route-overview', () => import( /* webpackChunkName: "route-overview" */ "../vue-components/route-overview.vue"));
Vue.component('deal-calendar', () => import( /* webpackChunkName: "deal-calendar" */ "../vue-components/deal-calendar.vue"));
// Vue.component('sb-layout', () => import( /* webpackChunkName: "sb-layout" */ "../vue-components/sb-layout.vue"));
// Vue.component('sidebar-box', () => import( /* webpackChunkName: "sidebar-box" */ "../vue-components/sidebar-box.vue"));
Vue.component('sb-popup', () => import( /* webpackChunkName: "sb-popup" */ "../vue-components/sb-popup.vue"));

Vue.component('file-upload', () => import( /* webpackChunkName: "file-upload" */ "../vue-components/file-upload.vue"));

Vue.component('file-viewer', () => import( /* webpackChunkName: "file-viewer" */ "../vue-components/file-viewer.vue"));
Vue.component('weather-forecast', () => import( /* webpackChunkName: "weather-forecast" */ "../vue-components/weather-forecast.vue"));
Vue.component('pseudo-password', () => import( /* webpackChunkName: "pseudo-password" */ "../vue-components/pseudo-password.vue"));

Vue.component('sidebar-box', () => import( /* webpackChunkName: "sidebar-box" */ "../vue-components/sidebar-box.vue"));
Vue.component('sidebar-box-group', () => import( /* webpackChunkName: "sidebar-box-group" */ "../vue-components/sidebar-box-group.vue"));
Vue.component('sidebar-box-button', () => import( /* webpackChunkName: "sidebar-box-button" */ "../vue-components/sidebar-box-button.vue"));


export async function load_incasso_list() {
	let incassoListComponent = await import( /* webpackChunkName: "incasso-list" */ "../vue-components/incasso-list.vue");
	return Vue.extend(incassoListComponent.default);
}

SB_Onload(() => {
	document.querySelectorAll("[vue-standalone]").forEach(el => {
		el.removeAttribute("vue-standalone");
		new Vue({el});
	})

	var observer = new MutationObserver(function(mutations) {
		for (let mutation of mutations) {
			if (mutation.type !== "childList") {
				continue;
			}
			for (let el of mutation.addedNodes) {
				if (!(el instanceof HTMLElement)) {
					continue;
				}
				if (el.attributes && el.getAttribute("vue-standalone") != null) {
					el.removeAttribute("vue-standalone");
					new Vue({el});
				} else {
					el.querySelectorAll("[vue-standalone]").forEach(el => {
						el.removeAttribute("vue-standalone");
						new Vue({el});
					})
				}
			}
		}
	});
	
	observer.observe(document.body, {childList: true, subtree: true, attributeFilter: ['vue-standalone']});
})
