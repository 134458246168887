/* global $$ */

import { Chosen } from "../../../combined-new.js";
import { SB_Ajax } from "../ajax.es6.js"
import { SB_CalcField, SB_FormatNumber, SB_Microtime, SB_ReadNumber, SB_TimeToFloatUnitCount, SB_Timestamp, Num, __ } from "../common_functions.es6.js";
import { Vue } from "../vue.es6.js";

import { SB_Popup } from "../popup.es6.js";
import { SB_Dialog, SB_Input, SB_Form, SB_PopupAreas } from "../ui_elements.es6.js";

export const DealEditList = {};

export class DealEditClass {
	constructor(deal, customer, form_name, base_name, popup_name, DealRuleInput) {
		this.deal = deal;
		this.customer = customer;
		this.form_name = form_name;
		this.base_name = base_name;
		this.popup_name = popup_name;
		this.DealRuleInput = DealRuleInput;
		this.dealCalendar = undefined;
		this.TemplateTimeused = false;

		this.SB_DealTaxRate = this.deal['tax_rate'];

		SB_Popup.Init(this.popup_name).Show();
		SB_PopupAreas.InitAll();

		this.SB_DealFormBefore = $(this.form_name).serialize();

		DealEditList[this.base_name] = this;
	}

	async init_vue(element, options) {
		let deal_calendar_wrapper = await import( /* webpackChunkName: "deal-calendar-wrapper" */ "../../vue-components/pages/deal_calendar_wrapper.vue");
		let vue_deal_calendar_wrapper = await Vue.extend(deal_calendar_wrapper.default);
	
		if (this.dealCalendar) {
			return this.dealCalendar;
		}
	
		this.dealCalendar = new vue_deal_calendar_wrapper({
		}).$mount();

		this.dealCalendar.selectedDate = options.selectedDate;
		this.dealCalendar.base_name = this.base_name;
		this.dealCalendar.customer_id = this.customer['id'];
		this.dealCalendar.deal_id = this.deal['id'];
		element.appendChild(this.dealCalendar.$el);

		this.SB_DealGetCalendar();
	}
	
	destroy() {
		delete DealEditList[this.base_name];
	}
	
	SB_DealToggleProvision(value) {
	
		if(value != "auto" && value != "none" && SB_ReadNumber(SB_Input(this.base_name+'[provision]').value())) {
			if(window.orig_provision_type == "percent"){
				window.orig_percent = SB_ReadNumber(SB_Input(this.base_name+'[provision]').value());
				window.orig_provision = window.orig_percent * SB_ReadNumber(SB_Input(this.base_name+'[price]').value()) / 100;
			}
			else if(window.orig_provision_type == "amount"){
				window.orig_provision = SB_ReadNumber(SB_Input(this.base_name+'[provision]').value());
				window.orig_percent = window.orig_provision / SB_ReadNumber(SB_Input(this.base_name+'[price]').value()) * 100;
			}
		}
		window.orig_provision_type = value;
	
		if (value == 'auto')
		{
			SB_Input(this.base_name+'[provision]').disable()
				.update(__("(automatisk)"));
		}
		else if(value == 'amount')
		{
	
			SB_Input(this.base_name+'[provision]').enable()
				.update(SB_FormatNumber(window.orig_provision))
				.activate();
	
	//		$(this.base_name+'_ProvisionWrapper').show();
		}
		else if(value == 'percent')
		{
	//		if(SB_ReadNumber(SB_Input(this.base_name+'[provision]').value())){window.orig_provision = (SB_ReadNumber(SB_Input(this.base_name+'[provision]').value())).toString();}
	
	//		percent = SB_ReadNumber(window.orig_provision) / SB_ReadNumber(SB_Input(this.base_name+'[price]').value()) * 100;
	
			SB_Input(this.base_name+'[provision]').enable()
				.update(SB_FormatNumber(window.orig_percent))
				.activate();
	
	//		$(this.base_name+'_ProvisionWrapper').show();
		}
		else
		{
			SB_Input(this.base_name+'[provision]').disable()
				.update(Num(0,'sp'));
	
	//		$(this.base_name+'_ProvisionWrapper').hide();
	
		}
		
		if (value=="amount")
			SB_Input(this.base_name+'[provision]').changeLabel(__("Fast beløb i %s", __("CURRENCY_PREFIX")));
		else
			SB_Input(this.base_name+'[provision]').changeLabel(__("Fast sats i %%"));
	
	}
			
	SB_DealPriceChange(value, type) {
		value = SB_ReadNumber(SB_CalcField(value));
		
		if (!isNaN(value))
		{
			var price;
			if (type=="unit")
			{
				var count = this.SB_DealGetUnitCount();
				if (isNaN(count) || count == 0)	count = 1;
	
				price = count * value;
			}
			else
			{
				price = (type=="ex") ? value : value / (1+this.SB_DealTaxRate*0.01);
			}
	
			var price_incl = price * (1+this.SB_DealTaxRate/100);
			
			if (type!="unit")
				SB_Input(this.base_name+'[unit_price]').update(SB_FormatNumber(price));
			if (type=="unit" || type=="in")
				SB_Input(this.base_name+'[price]').update(SB_FormatNumber(price));
			if (type=="unit" || type=="ex")
				SB_Input(this.base_name+'[price_incl]').update(SB_FormatNumber(price_incl));
		}
		
		this.SB_DealPriceTimeChange();
	}
	
	SB_DealPriceTimeChange()
	{
		if (!$(`input[${this.base_name}[price_per_minute]]`))
			return false;
			
		var h = SB_ReadNumber(SB_Input(this.base_name+'[timeused][hours]').value());
		var m = SB_ReadNumber(SB_Input(this.base_name+'[timeused][minutes]').value());
		var s = 0;
		if (SB_Input(this.base_name+'[timeused][seconds]')) {
			s = SB_Input(this.base_name+'[timeused][seconds]').value();
		}
		var price = SB_ReadNumber(SB_Input(this.base_name+'[price]').value());
		
		if (isNaN(h))		h = 0;
		if (isNaN(m))		m = 0;
		if (isNaN(s))		s = 0;
		if (isNaN(price))	price = 0;
		
		var timeused = (h*60) + m + (s/60);
		
		if (timeused == 0)
			SB_Input(this.base_name+'[price_per_minute]').update("-");
		else
			SB_Input(this.base_name+'[price_per_minute]').update(SB_FormatNumber(price /timeused));
	}
	
	
	
	SB_DealUnlockInterval() {
	//	SB_Confirm('Er du sikker på, at du vil ændre interval for denne opgave? Alle fremtidige forekomster vil blive nulstillet.',function() {
			$(this.base_name + 'DealIntervalArea').removeClassName('locked').writeAttribute("onclick","").select(`input[name^='${this.base_name}[interval_'], select[name^='${this.base_name}[interval_']`).each((input) => {
				if (input.readAttribute('name') != `${this.base_name}[interval_simple][interval2]` || SB_Input(this.base_name+'[interval_simple][interval1]').value()!="0")
					SB_Input(input).enable();
			});
			$(this.base_name + 'DealIntervalRulesArea').removeClassName('locked').writeAttribute("onclick","").select(`input[name^='${this.base_name}[interval_'], select[name^='${this.base_name}[interval_']`).each((input) => {
				if (!input.up('.FormElement').hasClassName('inactive-rule'))
					SB_Input(input).enable();
			});
	
			var uniqid = SB_Microtime();
			$(this.base_name + 'DealIntervalRulesBody').insert({'bottom' : this.DealRuleInput});
	
			$(this.base_name + 'DealIntervalRulesBody').down(".FormLine:last-child").select("select").each((input) => {
				input.writeAttribute("name",input.readAttribute("name").replace("_uniqid_",uniqid)); 
				input.writeAttribute("id",input.readAttribute("id").replace("_uniqid_",uniqid));
	
				let disable_search;
				if(input.readAttribute("name").indexOf("time") > -1)
					disable_search = false;
				else
					disable_search = true;
				new Chosen(input,{disable_search: disable_search, width : '100%', allow_single_deselect:true});
			});
	
	
			this.SB_DealGetCalendar();
	//	});
	}
	
	SB_DealRuleChange(input)
	{
		var FormLine = $(input).up('.FormLine');
	
		if ($(input).value)
		{
			FormLine.select(".inactive-rule").each(function(sel) {
				sel.removeClassName('inactive-rule').addClassName('active-rule');
				SB_Input(sel.down('select')).enable();
			});
			
			$(input).down(".placeholder").update(__("Fjern regel")).writeAttribute("data-placeholder",__("Fjern regel"));
			Event.fire(input, "chosen:updated");
			
			if (!FormLine.next('.FormLine'))
			{
				var uniqid = SB_Microtime();
				
				FormLine.insert({'after' : this.DealRuleInput});//.replace("_uniqid_",uniqid)});
				FormLine.next(".FormLine").select("select").each(function(input) {
					input.writeAttribute("name",input.readAttribute("name").replace("_uniqid_",uniqid)); 
					input.writeAttribute("id",input.readAttribute("id").replace("_uniqid_",uniqid));
	
					let disable_search;
					if(input.readAttribute("name").indexOf("time") > -1)
						disable_search = false;
					else
						disable_search = true;
					new Chosen(input,{disable_search: disable_search, width : '100%', allow_single_deselect:true});
				});
			}
		}
		else
		{
			FormLine.select(".inactive-rule").each(function(sel) {
				sel.removeClassName('active-rule').addClassName('inactive-rule');
				SB_Input(sel.down('select')).disable();
			});
			
			if (FormLine.next('.FormLine'))
			{
				// FormLine.previous().remove();
				FormLine.remove();
			}
		}
	}
	
	SB_DealChangeUser(user_id)
	{
		if ($(this.base_name + 'DealIntervalRouteArea'))
			new SB_Ajax('deal_edit_ajax.php',{'action' : 'GetDealRoutesInput', 'deal_id' : this.deal['id'], 'customer_id' : this.customer['id'], 'user_id' : user_id, 'base_name' : this.base_name}).SetTarget(this.base_name + 'DealIntervalRouteArea').SetDelay(0).Request();
	}
	
	SB_DealToggleRoute(checkbox) {
		var nexttime = checkbox.readAttribute('sb_nexttime');
		var interval1 = $(`input[${this.base_name}[interval_simple][interval_start]]`);
		if (checkbox.checked && nexttime != "-" && !interval1.disabled && interval1.value == interval1.readAttribute('value'))
		{
			SB_Input(interval1).update(nexttime);
		}
	
		this.SB_DealGetCalendar();
	}
	
	SB_DealGetCalendar() {
		var routes = [];
		
		$$(".deal-edit-route-checkbox").each(function(checkbox) {
			if (!checkbox.checked)
				return false;
	
			routes.push(checkbox.value);
		});
	
		if (!$(this.base_name + 'DealIntervalArea').hasClassName('locked') && routes.size() > 0)
		{
			this.dealCalendar.routes = routes;
		}
		else
		{
			// $('DealCalendarWrapper').update();
			this.dealCalendar.routes = [];
		}
	}
	// setTimeout(SB_DealGetCalendar,200);
	
	
	SB_DealLoadRuleTemplate(input) {
		var value = SB_Input(input).value();
		if (value)
		{
			new SB_Ajax("deal_edit_ajax.php",{'action' : 'LoadRuleTemplate', 'template_id' : value, 'base_name': this.base_name}).SetTarget(this.base_name + "DealIntervalRulesBody").SetDelay(0).Request();
			
			SB_Input(input).update("");
			input.blur();
		}
	}
	SB_DealSimpleIntervalChange(interval1) {
		SB_Input(this.base_name+'[interval_simple][interval2]').enable((interval1>0));
		SB_Input(this.base_name+'[interval_simple][interval_start]').enable((interval1!=0));
	}
	SB_DealAdvancedTimeChange() {
		let container = $(this.base_name + '_interval_time_precision_advanced');
		let should_hide = Array.from(document.querySelectorAll('select[name^="DealEdit[interval_advanced][rules][time]"]')).map(el => el.getValue()).filter(value => value != '').length == 0;
		if (should_hide) {
			container.hide();
		} else {
			container.show();
		}
	}
	
	SB_DealGetUnitCount() {
		let unit_count = SB_Input(`${this.base_name}[unit_count]`).value();
		let unit_type = SB_Input(`${this.base_name}[unit_type]`).value();
		return SB_TimeToFloatUnitCount(unit_count, unit_type);
	}
	
	SB_DealChangeCount(has_focus)
	{
		let count = this.SB_DealGetUnitCount();
		has_focus = has_focus || false;
	
		if (isNaN(count) || count == 0)
		{
			if (!SB_TimeToFloatUnitCount(SB_Input(`${this.base_name}[unit_count]`).value(), 't')) {
				SB_Input(this.base_name+'[unit_type]').hide();
			} else {
				SB_Input(this.base_name+'[unit_type]').show();
			}
			
	//		if (!has_focus)
			{
				SB_Input(this.base_name+'[unit_price]').disable()/*.update( SB_Input(this.base_name+'[price]').value() )*/;
				SB_Input(this.base_name+'[price]').enable().update( SB_Input(this.base_name+'[unit_price]').value() );
				SB_Input(this.base_name+'[price_incl]').enable();
	
				this.SB_DealPriceChange( SB_Input(this.base_name+'[price]').value(), "ex" );
			}
		}
		else
		{
			SB_Input(this.base_name+'[unit_price]').enable();
			SB_Input(this.base_name+'[unit_type]').show();
			SB_Input(this.base_name+'[price]').disable();
			SB_Input(this.base_name+'[price_incl]').disable();
	
			this.SB_DealPriceChange( SB_Input(this.base_name+'[unit_price]').value(), "unit" );
		}
	
		this.SB_DealUpdateTimeused();
	
	}
	
	SB_DealTemplateSelect(input)
	{
		if ($(input).value != '')
		{
	
			this.SB_DealTemplateUpdate();
			SB_Input(this.base_name+'TemplateSelect').update("");
			
			SB_Input(`${this.base_name}[unit_count]`).update("");
			this.SB_DealChangeCount();
			setTimeout(() => {
				SB_Input(`${this.base_name}[unit_count]`).activate();
			},2);
		}
		else
		{
		}
	}
	
	
	
	SB_DealTemplateBlur()
	{
	}
	
	SB_DealTemplateUpdate()
	{
		var TemplateSelect = $(`input[${this.base_name}TemplateSelect]`);
		var TemplateNumber = $(`input[${this.base_name}[unit_count]]`);
		var TemplateUnitType = $(`input[${this.base_name}[unit_type]]`);
		var TemplateOption = $(TemplateSelect.value);
		
		if (!TemplateOption)
			return false;
	
		var Num = (TemplateNumber.value!="") ? SB_ReadNumber(TemplateNumber.value) : 1;
		var Desc = TemplateOption.readAttribute('template_description');
		var Price = TemplateOption.readAttribute('template_price');
		var UnitType = TemplateOption.readAttribute('template_unit_type');
		var Timeused = TemplateOption.readAttribute('template_timeused');
		var FinancialAccountId = TemplateOption.readAttribute('template_financial_account_id');
		
		Price = Price - 0;
		Timeused = Timeused - 0;
		
	/*	if (!isNaN(Num))
		{
			Desc = Desc.replace(/ %antal% /gi, " "+Num.toString().replace('.',',')+" ");
			Price = Price * Num;
			Timeused = Timeused * Num;
		}*/
		
		let PriceIncl = Price * (1+this.SB_DealTaxRate*0.01);
	
		SB_Input(this.base_name+'[description]').update(Desc);
		SB_Input(this.base_name+'[unit_type]').select(UnitType);
		SB_Input(this.base_name+'[price]').update(SB_FormatNumber(Price));
		SB_Input(this.base_name+'[price_incl]').update(SB_FormatNumber(PriceIncl));
		
		SB_Input(`${this.base_name}[financial_account_id]`).update(FinancialAccountId);
		
	
		this.SB_DealPriceChange(SB_FormatNumber(Price), "ex");
		this.SB_DealPriceTimeChange();
	
		this.TemplateTimeused = Timeused;
		this.SB_DealUpdateTimeused();
			
		
	}
	
	SB_DealUpdateTimeused()
	{
		if (this.TemplateTimeused === false)
			return false;
		
		var count = this.SB_DealGetUnitCount();
		if (isNaN(count) || count == 0)
			count = 1;
	
		var Timeused = this.TemplateTimeused * count;
	
		let TimeusedHours = Math.floor(Timeused/3600).toString();
		while (TimeusedHours.length < 2)	TimeusedHours = "0" + TimeusedHours;
		let TimeusedMinutes = Math.floor((Timeused%3600)/60).toString();
		while (TimeusedMinutes.length < 2)	TimeusedMinutes = "0" + TimeusedMinutes;
		let TimeusedSeconds = Math.floor(Timeused%60).toString();
		while (TimeusedSeconds.length < 2)	TimeusedSeconds = "0" + TimeusedSeconds;
		
		SB_Input(this.base_name+'[timeused][hours]').update(TimeusedHours);
		SB_Input(this.base_name+'[timeused][minutes]').update(TimeusedMinutes);
		if (SB_Input(this.base_name+'[timeused][seconds]')) {
			SB_Input(this.base_name+'[timeused][seconds]').update(TimeusedSeconds);
		}
	}
	
	SB_DealCheckChanges(fnc)
	{
		if (this.SB_DealFormBefore == $(this.form_name).serialize() && ($(this.form_name).serialize(true).deal_id > 0 || $(this.form_name).serialize(true).offer_line_id > 0))
		{
			fnc();
		}
		else
		{
			let allow_not_to_save = ($(this.form_name).serialize(true).deal_id > 0 || $(this.form_name).serialize(true).offer_line_id > 0) ? true : false;
			SB_Dialog({
				"id" 		: "dialog-"+SB_Timestamp(),
				"title" 	: "Gem ændringer?",
				"text"		: __("Ønsker du at gemme ændringerne på aftalen? Nye aftaler skal gemmes før du kan fortsætte."),
				"buttons"	: [
					{"label" : "Gem ikke", "fnc" : allow_not_to_save ? fnc : function(){}},
					// TODO: Find evt. en måde at tage deal_id med i det opdaterede funktionskald.
					{
						"label" : "Gem først",
						"className" : "dialog-button-ok",
						"fnc" : () => {
							new SB_Form(this.form_name).AddParam(this.base_name+'[next_action]=stay').Submit().then(
								async (response)=>{
									if (response.responseText.match(/.*SB_MessageStack.Add\(.error.*/)) {
										return;
									}
									await SB_Popup.Init(this.popup_name).Remove().then(async ()=>{
										await SB_Popup.Init(this.popup_name).Ajax('deal_edit.php',`action=edit&deal_id=${this.deal['id']}`).Request();
										return SB_Popup.Init('customer_edit').Reload();
									})
									fnc();
								}); 
						}
					}
				]
			});
		}
	}
}


