// import Pikaday from "./pikaday-sb.js";
import { SB_DateToTimestamp, SB_FormatDate, dateFormat } from "./common_functions.es6";
import { createPopper } from "@popperjs/core";
	
export var SB_CalendarInit = function(id, options= {}) {
	return import(/* webpackChunkName: "pikaday" */"./pikaday-sb.js").then(({default: Pikaday})=>{
		/** @type {HTMLElement} */
		let el;
		if (typeof id === "string") {
			el = document.getElementById(id);
		} else {
			el = id;
		}
		if (typeof el.pikaday !== "undefined") {
			return el.pikaday;
		}
		/** @param {Event} evt */
		let eventBlocker = function(evt) {
			evt.preventDefault();
			evt.stopImmediatePropagation();
		}
		let old_onchange = el.onchange;
		el.onchange = eventBlocker;
		el.addEventListener('change', eventBlocker);
		let initialDate = SB_DateToTimestamp(el.value);
		var cal = new Pikaday({
			field: el,
			bound: typeof options.bound !== "undefined" ? options.bound : undefined,
			blurFieldOnSelect: typeof options.blurFieldOnSelect !== "undefined" ? options.blurFieldOnSelect : undefined,
			format: "dd/LL-yyyy",
			firstDay: 1,
			// container: el.parentElement,
			container: el.closest("body, .Popup"),
			reposition: false,
			showWeekNumber: true,
			i18n: {
				previousMonth : 'Forrige måned',
				nextMonth     : 'Næste måned',
				months        : ['Januar','Februar','Marts','April','Maj','Juni','Juli','August','September','Oktober','November','December'],
				weekdays      : ['Søndag','Mandag','Tirsdag','Onsdag','Torsdag','Fredag','Lørdag'],
				weekdaysShort : ['Søn','Man','Tir','Ons','Tor','Fre','Lør']
			},
			keyboardInput: false,
			showDaysInNextAndPreviousMonths: true,
			enableSelectionDaysInNextAndPreviousMonths: true,
			numberOfMonths: 1,
			defaultDate: initialDate ? new Date(initialDate*1000) : null,
			setDefaultDate: !!initialDate,
			toString(date, format) {
				return dateFormat(date, format);
			},
			parse(dateString, format) {
				// dateString is the result of `toString` method
				if (dateString) {
					let unix = SB_DateToTimestamp(dateString, 'DMY');
					return new Date(+unix*1000);
				} else {
					return false;
				}
			},
			onOpen() {
				if (!this._o.field.value) {
					this.setDate(null);
				}
				this._o.field.select();
				// setTimeout(()=>{
					// this.el.style.top = "0px";
					// this.el.style.left = "0px";
					if (!this.popper) {
						let placement = "bottom-start";
						let scrollElement = el.closest(".LayoutBar");
						if (scrollElement) {
							this.el.addEventListener("wheel", (e)=>{
								e.preventDefault();
								e.stopPropagation();
								scrollElement.scrollTop += e.deltaY;
							});
						}
						// if (el.closest(".LayoutBar, .ContextMenu")) {
						if (el.closest(".WrapLabel")) {
							placement = "bottom-end";
						}
						this.popper = createPopper(el, this.el, {
							placement: placement,
							modifiers: [
								{
									name: "preventOverflow",
									options: {
										boundary: document.body,
									},
								},
							],
						});
					}
					// this.el.getBoundingClientRect().left;
					// this.popper.update()
				setup_listeners();
				// }, 300)
			},
			onClose() {
				if (this.popper) {
					this.popper.destroy();
				}
				this.popper = undefined;
				teardown_listeners();
			},
		})

		setup_listeners();
		// document.removeEventListener('keydown',cal._onKeyChange);
		// document.addEventListener('keydown',cal._onKeyChange);
	
		el.onchange = old_onchange;
		el.removeEventListener('change', eventBlocker);
		$(id).observe("blur", function() {
			var v = SB_DateToTimestamp(this.value);
			if (v) {
				this.value = SB_FormatDate(v);
			}
		});	

		var cleanup = setInterval(()=>{
			if (!document.body.contains(el)) {
				cal.hide();
				cal.destroy();
				clearInterval(cleanup);
			}
		}, 1000);

		el.pikaday = cal;
		return cal;

		function keydown_handler(event) {
			if (typeof(window.calendar)!="undefined")
				window.calendar.hide();
	
			if (event.keyCode == Event.KEY_LEFT) {
				let date;
				if (!this.value) {
					if (cal.getDate()) {
						date = cal.getDate();
					} else {
						date = new Date();
					}
				} else {
					date = new Date( (SB_DateToTimestamp(this.value) + 12*60*60) * 1000);
				}
				if (event.altKey || event.shiftKey) {
					date.setFullYear( date.getFullYear()-1);
				} else {
					date.setDate(date.getDate()-1);
				}
	
				let t = date.getTime().toString().substring(0,10)-0;
	
				this.value = SB_FormatDate(t);
				Event.stop(event);
				cal.setDate(date, true);
			} else if (event.keyCode == Event.KEY_RIGHT) {
				let date;
				if (!this.value) {
					if (cal.getDate()) {
						date = cal.getDate();
					} else {
						date = new Date();
					}
				} else {
					date = new Date( (SB_DateToTimestamp(this.value) + 12*60*60) * 1000);
				}
				if (event.altKey || event.shiftKey) {
					date.setFullYear( date.getFullYear()+1);
				} else {
					date.setDate(date.getDate()+1);
				}
	
				let t = date.getTime().toString().substring(0,10)-0;
	
				this.value = SB_FormatDate(t);
				Event.stop(event);
				cal.setDate(date, true);
			} else if (event.keyCode == Event.KEY_UP) {
				let date;
				if (!this.value) {
					if (cal.getDate()) {
						date = cal.getDate();
					} else {
						date = new Date();
					}
				} else {
					date = new Date( (SB_DateToTimestamp(this.value) + 12*60*60) * 1000);
				}
				if (event.altKey || event.shiftKey) {
					date.setMonth( date.getMonth()-1);
				} else {
					date.setDate(date.getDate()-7);
				}
	
				let t = date.getTime().toString().substring(0,10)-0;
	
				this.value = SB_FormatDate(t);
				Event.stop(event);
				cal.setDate(date, true);
			} else if (event.keyCode == Event.KEY_DOWN) {
				let date;
				if (!this.value) {
					if (cal.getDate()) {
						date = cal.getDate();
					} else {
						date = new Date();
					}
				} else {
					date = new Date( (SB_DateToTimestamp(this.value) + 12*60*60) * 1000);
				}
	
				if (event.altKey || event.shiftKey) {
					date.setMonth( date.getMonth()+1);
				} else {
					date.setDate(date.getDate()+7);
				}
	
				let t = date.getTime().toString().substring(0,10)-0;
	
				this.value = SB_FormatDate(t);
				Event.stop(event);
				cal.setDate(date, true);
			} else if (event.keyCode == Event.KEY_ESC) {
				Event.stop(event);
				cal.hide();
			}
		}
		function setup_listeners() {
			$(id).observe("keydown", keydown_handler);
		}
		function teardown_listeners() {
			$(id).stopObserving("keydown", keydown_handler);
		}
	});
}