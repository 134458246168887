/*
                      #                                                 #
  ##     ##           #              ##    ####                         #
 #  #     #           #               #    #                           ###
 #        #     ##    ###     ###     #    ###    #  #    ##    ###     #
 # ##     #    #  #   #  #   #  #     #    #      #  #   # ##   #  #    # #
 #  #     #    #  #   #  #   #  #     #    #       ##    ##     #  #    # #
  ##     ###    ##    ###     ####   ###   ####    ##     ###   #  #     #
*/
import { SB_Onload } from "./common_functions.es6.js";
import { SB_Popup } from "./popup.es6.js";
import { SB_MainMenu } from "./main_menu.es6.js";
import {SB_SmsShortcut } from "./sms.es6.js";

/* global ACCOUNT_ID */
var ACCOUNT_ID_SHOWING;

export var GlobalEvent = {
	Listeners : {
		"keyup" : [],
		"keydown" : [],
		"mousedown" : []
	},
	
	initialize : function()
	{
		$(document).observe("keyup",GlobalEvent.onkeyup);
		$(document).observe("keydown",GlobalEvent.onkeydown);
		$(document).observe("mousedown",GlobalEvent.onmousedown);
//		$(document).observe("keyup",GlobalEvent.onkeyup);
		


		GlobalEvent.Register("keydown",SB_Shortcuts.Keydown);
		GlobalEvent.Register("keyup",SB_Shortcuts.Keyup);

		GlobalEvent.Register("keydown",SB_SmsShortcut);
		GlobalEvent.Register("keydown",SB_Popup.Esc);
		
		GlobalEvent.Register("mousedown",SB_MainMenu.Mousedown);
		GlobalEvent.Register("keydown",SB_MainMenu.Keydown);
		GlobalEvent.Register("keyup",SB_MainMenu.Keyup);
	},
	
	onmousedown : function(event)
	{
		event.Stopped = false;
		event.Global = true;
		
		GlobalEvent.Listeners['mousedown'].each(function(fnc) {
			if (event.Stopped!==false)
				return false;
			
			fnc(event);
		});
	},

	onkeydown : function(event)
	{
		event.Stopped = false;
		event.Global = true;
		
		GlobalEvent.Listeners['keydown'].each(function(fnc) {
			if (event.Stopped!==false)
				return false;
			
			fnc(event);
		});
	},

	onkeyup : function(event)
	{
		event.Stopped = false;
		event.Global = true;
		
		GlobalEvent.Listeners['keyup'].each(function(fnc) {
			if (event.Stopped!==false)
				return false;
				
			fnc(event);
		});

		if (event.Stopped!==false && event.keyCode == 18)
		{
//			Debug("ALT");
			event.stop();
		}
	},
	
	Register : function(EventType, fnc)
	{
		GlobalEvent.Listeners[EventType].push(fnc);
	}

}

/*
	                             #                     #                    #
	  ##    ####           ##    #                     #                    #
	 #  #   #   #         #  #   #                    ###                  ###
	  #     ####           #     # #     ##    # #     #      ###   #  #    #     ####
	   #    #   #           #    ## #   #  #   ## #    # #   #      #  #    # #   ##
	 #  #   #   #         #  #   #  #   #  #   #       # #   #      #  #    # #     ##
	  ##    ####           ##    #  #    ##    #        #     ###    ###     #    ####
	
	               #####
*/


export var SB_Shortcuts = {
	Keydown : function(event)
	{
		var base = document.body;
		if (Object.size(SB_Popup.Popups) > 0) {
			base = $$(".Popup").pop();
			// return false;
		}

		let keyCode = event.keyCode;


		if (keyCode == 18 && !event.ctrlKey)
		{
			$(base).addClassName("shortcut-hints");

			$$('*[sb_shortcut]').each(function(e) {
				var combo = e.readAttribute("sb_shortcut");
				if (!e.down(".shortcut-hint"))
				{
					e.insert({'top' : "<div class='shortcut-hint'>["+combo+"]</div>" });
				}
			});
		}
		else if ((event.altKey || base.hasClassName("DialogPopup")) && !event.ctrlKey) // dette tjek ikke nødvendigt, men øger måske hastigheden lidt
		{
			let pressed = SB_Shortcuts.GetKeyCombo(event);
			
			var matches = base.select('*[sb_shortcut="'+pressed+'"]');
			if (matches.length == 0)
				return false;

			var match;
			if (matches.length == 1)
				match = matches[0];
			else
			{
			// Hvis flere knapper har denne shortcut, find da den første som ikke er display:none (virker kun hvis display:none er inline)
				match = matches.find(function(m) {
					var parent = m;
					while (parent)
					{
						if (parent.tagName.toLowerCase() == "body")
							break;
						if (!parent.visible())
							return false;
						parent = parent.up();
					}
					return true;
				});
			}
			if (
				match.readAttribute("sb_shortcut") != "Esc"
				&& (typeof(match.onclick) == "function" || match.href != "")
				&& (
					match.readAttribute("sb_shortcut") != "Enter"
					|| match.up(".DialogPopup")
				)
			) {
				$$(".shortcut-hints").invoke("removeClassName","shortcut-hints");
				
				match.click();
				event.Stopped = true;
				event.stop();
			}
		}
		else if (event.altKey && event.ctrlKey && event.shiftKey)
		{
			let pressed = SB_Shortcuts.GetKeyCombo(event);

			if (pressed == "Ctrl+Shift+Alt" && typeof(ACCOUNT_ID) != "undefined")
			{
				$(document.body).down(".LayoutBlock1").insert({'bottom' : "<div id='account_id' style='font-size: 26px; font-weight:bold; text-align: center;'>ID: "+ACCOUNT_ID+"</div>"});
				ACCOUNT_ID_SHOWING = true;
			}

		}
	},

	Keyup : function(event)
	{
		if (typeof(ACCOUNT_ID_SHOWING) != "undefined" && ACCOUNT_ID_SHOWING === true && $('account_id'))
		{
			$('account_id').remove();
			ACCOUNT_ID_SHOWING = false;
		}

		if (!event.altKey)
			$$(".shortcut-hints").invoke("removeClassName","shortcut-hints");
	},

	GetKeyCombo : function(event)
	{
		var code = event.keyCode;

		if (code == 219)	code = 56;
		if (code == 221)	code = 57;

		var character = (code == 17 || code == 16 || code == 18 || code == 224) ? "" : String.fromCharCode(code);

		if (code == 46) character="Delete";
//		if (code == 222) character="'"; // Duer ikke som genvejstast, da den = @ på Mac
//		if (code == 188) character="<"; // er åbenbart magen til , på mac

		if (code == 188) character=","; //If the user presses , when the type is onkeydown
		if (code == 190) character="."; //If the user presses , when the type is onkeydown
		
		if (code == 13) character="Enter";

		var combo = [];

		if(event.ctrlKey || code == 17)		combo.push("Ctrl");
		if(event.shiftKey || code == 16)	combo.push("Shift");
		if(event.altKey || code == 18)		combo.push("Alt");
		if(event.metaKey || code == 224)	combo.push("Meta");
	
		if (character != "")
			combo.push(character.substring(0,1).toUpperCase() + character.substring(1).toLowerCase());
		// Store bogstaver (eller stort begyndelsesbogstav ved fx Delete

		return combo.join("+");
	}
};

