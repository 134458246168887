/* global SB_TouchDevice */
import { SB_Popup } from "./popup.es6.js";
import { GlobalEvent } from "./events.es6.js";
import { SB_Input } from "./ui_elements.es6.js";
import { createPopper } from "../bootstrap-tooltip.es6.js";
import { SB_MainMenu } from "./main_menu.es6.js";
import { GetLast, SB_RemoveFocus } from "./common_functions.es6.js";
import { SB_Ajax } from "./ajax.es6.js";
// import {ContextBarAdjust} from "./ui_elements.es6";
import { Class } from "Prototype";

export var SB_Lists = {};
export var SB_ListDestroy = function(Id)
{
	if (typeof(SB_Lists[Id]) != "undefined")
		SB_Lists[Id].Destroy();
}

/**
 * Initialiserer en ny liste
 * @param  {string} Id            id på listen, så den kan findes i SB_Lists
 * @param  {string} AjaxUrl       url for ajax-scriptet
 * @param  {object} AjaxParams    Id ekstra post-parametre til ajax-scriptet
 * @return {object}               Liste-objektet
 */
export var SB_List = Class.create({
	
	initialize : function(Id, AjaxUrl, AjaxParams)
	{
	// Slet hvis listen allerede findes
		SB_ListDestroy(Id);
		
		SB_Lists[Id] = this;
		
		
		this.Id = Id;
		
		this.AllowHighlight = false;
		this.AllowMultilevel = false;
		this.AutoCompletePreselect = false;

		this.ContextBar = false;
		this.AutoComplete = false;
		
		this.Highlighted = [];
		this.HighlightedByShift = [];
		this.Reload = true;
		this.CustomEvents = {};
		this.InPlaceEditing = false;
		this.DblClickRow = false;
		
		this.Inputs = [];

		this.Delay = 300;
		
		if ($(this.Id))
		{
			this.DynamicContainer = false;
			this.Container = $(this.Id);
		}
		else
		{
			this.DynamicContainer = true;
			this.Container = new Element('div').addClassName('AutoComplete');
		}

		this.DynamicContainerPopper = false;

		let ListHeight = (this.DynamicContainer===false) ? (window.innerHeight - $(this.Id).cumulativeOffset().top) : 0;

		this.AjaxUrl = AjaxUrl || false;
		this.AjaxParams = Object.extend({
			'action' : 'GetList',
			'Highlighted' : '',
			'OrderBy' : '',
			'ListHeight' : ListHeight,
			'PaginateOffset' : 0
		}, AjaxParams || {});

		
//		if (SB_TouchDevice)
//			this.Container.observe('touchstart',this.MouseDown.bindAsEventListener(this));
//		else
			this.Container.observe('mousedown',this.MouseDown.bindAsEventListener(this));
		
		return this;
	},
	
	AddEvent : function(name, fnc)
	{
		this.CustomEvents[name] = fnc;

		return this;
	},

	SetDelay : function (Delay)
	{
		this.Delay = Delay;

		return this;
	},
	
	SetAllowHighlight : function(AllowHighlight)
	{
		this.AllowHighlight = AllowHighlight;
		
		return this;
	},
	
	SetAllowMultilevel : function(AllowMultilevel)
	{
		this.AllowMultilevel = AllowMultilevel;
		
		return this;
	},
	
	SetContextBar : function(ContextBar)
	{
		this.ContextBar = $(ContextBar);

		this.RefreshContextBar();

		return this;
	},
	
	SetPreselect : function(Preselect)
	{
		this.AutoCompletePreselect = true;
		return this;
	},
	
	ForceRefresh : function()
	{
		this.Reload = true;
		return this;
	},
	
	ListenGlobal : function()
	{
		GlobalEvent.Register("keydown",this.KeyDown.bindAsEventListener(this));
		
		return this;
	},
	
	SetAutoComplete : function(Input, Preselect, RefreshContextBar)
	{
		this.AutoCompletePreselect = Preselect || false;
		RefreshContextBar = RefreshContextBar || false;
		
		this.AutoComplete = $(Input);
		this.AutoComplete.observe("keyup",this.KeyUp.bindAsEventListener(this))
			.observe("change",this.KeyUp.bindAsEventListener(this))
			.observe("keydown",this.KeyDown.bindAsEventListener(this))
			.observe("focus",this.Focus.bindAsEventListener(this))
			.observe("blur",this.Blur.bindAsEventListener(this));

		if (RefreshContextBar)
			this.AutoComplete.observe("keyup",this.RefreshContextBar.curry({}).bind(this));

		this.AjaxParams = Object.extend({
			'id' : this.Id,
			'search' : SB_Input(this.AutoComplete).value()
		}, this.AjaxParams);

		return this;
	},
	
	Focus : function(/* event */)
	{
		if (this.InPlaceEditing===true)
			return;
		
		SB_Input(this.AutoComplete).onfocus();
		
		if (this.DynamicContainer)
		{
			// var Pos = this.AutoComplete.cumulativeOffset();
			this.Container.setStyle({
				// left 	: Pos.left+'px', 
				// top 	: (Pos.top+this.AutoComplete.getHeight())+'px',
				
				width 	: Math.max(this.AutoComplete.getWidth(),500)+'px',
				height : '200px'
			});
			
			if (!this.Container.descendantOf(document.body))
				$(document.body).insert({'bottom' : this.Container});
		
			this.DynamicContainerPopper = createPopper(this.AutoComplete, this.Container, {
				placement: "bottom-start",
				modifiers: [
					// {
					// 	name: "offset",
					// 	options: {
					// 		offset: [this.AutoComplete.getWidth(), 0],
					// 	},
					// },
					{
						name: "flip",
						enabled: false,
					},
					{
						name: "preventOverflow",
						enabled: false,
					},
					{
						name: "hide",
						enabled: false,
					},
				],
			});

			this.ForceRefresh();
			
			this.Refresh();
			
			this.AutoComplete.activate();
		}
	},
	
	Blur : function(event)
	{
		if (typeof(this.CustomEvents.blur) != "undefined") {
			this.CustomEvents.blur.bind(this)(event);
		}

		if (this.InPlaceEditing===true)
			return;
		
		if (this.DynamicContainer)
		{
			if (this.DynamicContainerPopper) {
				this.DynamicContainerPopper.destroy();
				this.DynamicContainerPopper = false;
			}
			this.Container.update().remove();
		}
	},
	
	KeyUp : function(event)
	{
		if (this.InPlaceEditing===true)
			return;

		 // Hvis man har åbnet menuen
		if ($('MainMenu').down(".open"))
		{
			SB_MainMenu.Keyup(event);
			return;
		}
		// Tjekker bare om indholdet har ændret sig
		if (this.AutoComplete && this.AjaxParams.search != SB_Input(this.AutoComplete).value())
		{
			this.Refresh();
			return;
		}
/*
		switch(event.keyCode)
		{
			case Event.KEY_TAB:
			case Event.KEY_LEFT:
			case Event.KEY_RIGHT:
			case Event.KEY_RETURN:
			case Event.KEY_UP:
			case Event.KEY_DOWN:	
			case Event.KEY_ALT:
			case 18: // ALT
			case 91: // CMD / Windows
			case 17: // CTRL
			case 16: // SHIFT
			case 224: // CMD
			return;

		}
		
		this.Refresh();*/
	},
	
	KeyDown : function(event)
	{
		if (event.element() && event.element().hasClassName("input-textarea"))
			return;
		
		if (event.Global === true && Object.size(SB_Popup.Popups) > 0)
			return;

		 // Hvis man har åbnet menuen
		if ($('MainMenu').down(".open"))
		{
			SB_MainMenu.Keydown(event);
			return;
		}

		if (typeof(this.CustomEvents.keydown) != "undefined")
		{
			if (this.CustomEvents.keydown.bind(this)(event))
			{
				if (this.AutoComplete)
					this.AutoComplete.blur();
				event.Stopped = true;
				event.stop();
				return;
			}
		}

		var Highlighted,
			Row,
			OtherDirectionHighlighted;
		switch(event.keyCode)
		{
			case Event.KEY_UP:
				if (this.InPlaceEditing===true)
					return;
				
				Highlighted = (this.Highlighted.size() > 0) ? this.GetRow(GetLast(this.Highlighted)) : false;
				Row = (Highlighted) ? Highlighted.previous('*[rowid]:not(.prevent-highlight)') : false;
				OtherDirectionHighlighted = (Highlighted && Highlighted.next('*[rowid]:not(.prevent-highlight)') && Highlighted.next('*[rowid]:not(.prevent-highlight)').hasClassName('highlight'));
				
				if (Row)
				{
					if (Row.hasClassName("highlight") && (event.metaKey || event.ctrlKey || event.shiftKey))
					{
						if (OtherDirectionHighlighted)
							Row = Highlighted.previous('*[rowid]:not(.prevent-highlight):not(.highlight)');
						else
							Row = Highlighted;
					}
					
					this.ToggleHighlight(Row,(event.metaKey || event.ctrlKey || event.shiftKey), !(event.metaKey || event.ctrlKey || event.shiftKey), ((event.metaKey || event.ctrlKey || event.shiftKey) && Row != Highlighted));

					if (this.DynamicContainer)
					{
						let tmp = (this.Container.getHeight() / 2) - Row.getHeight();
						if (Row.offsetTop < this.Container.scrollTop + tmp)
							this.Container.scrollTop = Row.offsetTop - tmp;
					}
				}
				else if (!(event.metaKey || event.ctrlKey || event.shiftKey))
				{
					this.ResetHighlight();
				}
				
				event.Stopped = true;
				event.stop();
			return;
			
			case Event.KEY_TAB:
			case Event.KEY_RETURN:
				if (this.InPlaceEditing===true)
					return;
				
				if (typeof(this.CustomEvents.enter) != "undefined")
				{
					if (this.CustomEvents.enter.bind(this)(event))
					{
						if (this.AutoComplete)
							this.AutoComplete.blur();
						event.Stopped = true;
						event.stop();
						return;
					}
				}
			return;
			
			case Event.KEY_ESC:
				if (this.InPlaceEditing)
				{
					this.StopInPlaceEdit();
					SB_RemoveFocus();

					event.Stopped = true;
					event.stop();			
				}
				else if (this.Highlighted.size() > 0)
				{
					this.ResetHighlight();
					
					event.Stopped = true;
					event.stop();			
				}
			return;
				
			case Event.KEY_DOWN:
				if (this.InPlaceEditing===true)
					return;
				Highlighted = (this.Highlighted.size() > 0) ? this.GetRow(GetLast(this.Highlighted)) : false;
				Row = (Highlighted) ? Highlighted.next('*[rowid]:not(.prevent-highlight)') : this.Container.down('*[rowid]:not(.prevent-highlight)');
				OtherDirectionHighlighted = (Highlighted && Highlighted.previous('*[rowid]:not(.prevent-highlight)') && Highlighted.previous('*[rowid]:not(.prevent-highlight)').hasClassName('highlight'));
				
				if (Row)
				{
					if (Row.hasClassName("highlight") && (event.metaKey || event.ctrlKey || event.shiftKey))
					{
						if (OtherDirectionHighlighted)
							Row = Highlighted.next('*[rowid]:not(.prevent-highlight):not(.highlight)');
						else
							Row = Highlighted;
					}
					
					this.ToggleHighlight(Row,(event.metaKey || event.ctrlKey || event.shiftKey), !(event.metaKey || event.ctrlKey || event.shiftKey), ((event.metaKey || event.ctrlKey || event.shiftKey) && Row != Highlighted));
		
					if (this.DynamicContainer)
					{
						let tmp = (this.Container.getHeight() / 2);
						if (Row.offsetTop > this.Container.scrollTop + tmp)
							this.Container.scrollTop = Row.offsetTop - tmp;
					}
				}
		
				event.Stopped = true;
				event.stop();			
			return;
		}
		
	},
	
	MouseDown : function(event)
	{
		if (this.InPlaceEditing===true)
			return;

		if ($('MainMenu').down(".open"))
		{
			SB_MainMenu.Mousedown(event);
		}

		var el = event.element();
		if (el.up(".chosen-select"))
			return;
//		Debug(el.tagName);
		var ForceHighlight = (el.tagName.toUpperCase() == "INPUT" || el.tagName.toUpperCase() == "SELECT" || el.tagName.toUpperCase() == "OPTION" || el.tagName.toUpperCase() == "TEXTAREA" || el.tagName.toUpperCase() == "LABEL");
		if (ForceHighlight)	return false;
//			return;

		var Row = event.findElement('*[rowid]') || false;
		var Level = (this.AllowMultilevel===false || Row === false || Row.readAttribute('rowid').indexOf("/")==-1) ? 0 : 1;
		
	// Dobbeltklik funktion
//		if (Level == 0)
		{
			if (this.DblClickRow===false || this.DblClickRow != Row)
			{
				this.DblClickRow = Row;
				setTimeout(function() {if (this.DblClickRow == Row) this.DblClickRow = false;}.bind(this),300);
			}
			else
			{
				this.DblClickRow = false;
				this.ToggleHighlight(Row, false, true);
				this.DblClick(event);
				event.stop();
				return;
			}
		}
		
		if (this.AllowMultilevel!==false)
		{
			if (this.Highlighted.find(function(id) {
				return (Level != ((id.indexOf("/")==-1) ? 0 : 1)) || 
					(
						(
							(Row!==false && Row.hasClassName("calendar-day-task-printed")) || 
							(Row!==false && Row.hasClassName("calendar-day-task-delivered"))
						) != (
							this.Container.down("[rowid='"+id+"']").hasClassName("calendar-day-task-printed") || 
							this.Container.down("[rowid='"+id+"']").hasClassName("calendar-day-task-delivered")
						)
					) || 
					(
						(
							Row!==false && 
							Row.readAttribute("sb_calendar") != this.Container.down("[rowid='"+id+"']").readAttribute("sb_calendar")
						)
					);   
			}.bind(this)))
			{
				ForceHighlight = true;
			}
		}

		var helperClick = false;
		var $t = jQuery(event.target);
		if ($t.add($t.parents()).is("[multihighlighthelper]")) {
			helperClick = true;
		}
		
		if (Row)
		{
			this.ToggleHighlight(Row,(event.ctrlKey || event.metaKey || event.shiftKey || helperClick),(ForceHighlight || typeof(this.CustomEvents.mousedown)!="undefined"),(event.shiftKey));
		}

		if (typeof(this.CustomEvents.mousedown) != "undefined")
		{
			if (this.CustomEvents.mousedown.bind(this)(event))
			{
				if (this.AutoComplete)
					this.AutoComplete.blur();
				event.stop();
				return;
			}
		}
		

//		if (this.AutoComplete)
//			this.AutoComplete.focus();
		if (Row && !ForceHighlight && !SB_TouchDevice && $(Row).up("table").readAttribute("onselectstart"))
			event.stop();
	},
	
	
	
	DblClick : function(event)
	{
		if (typeof(this.CustomEvents.dblclick) != "undefined")
		{
			if (this.CustomEvents.dblclick.bind(this)(event))
			{
				if (this.AutoComplete)
					this.AutoComplete.blur();
				event.stop();
				return;
			}
		}
		
	},

/*
Alternativ idé til at tage fokus:

Den fanger keydown, og hvis det ikke er en af modifier-tasterne,
og der i øvrigt ikke allerede er fokus et andet sted,
så sætter den fokus i det sidste [stealfocus]-felt på siden.
jQuery(document).on("keydown", function(evt){
	if (evt.key == "Alt" || evt.key == "Control" || evt.key == "Shift" || evt.key == "Meta") {return true}

	if (jQuery(":focus").length > 0) {return true}

	var f = jQuery("[stealfocus]");
	if (f.length > 0) {
		f.last().focus()
	}
})
 */

	TakeFocus : function()
	{
		var tmp_scroll = document.viewport.getScrollOffsets().top;
//		Debug(tmp_scroll);
		
		if (this.AutoComplete === false)
			SB_RemoveFocus();
		else if (!this.DynamicContainer) {
			var el = this.AutoComplete;
			el.style.transform = "translateY(" + (50 + document.viewport.getScrollOffsets().top - el.cumulativeOffset().top) + "px)";
			el.style.zIndex = -10;
			
			let forceLayout = el.clientLeft;
			
			setImmediate(function(){
				el.focus({preventScroll: true});
				setImmediate(function(){
					el.style.transform = "";
					el.style.zIndex = "";
				})
			})
			// this.AutoComplete.focus({preventScroll: true});	
		}
	// Hvis det er en dynamiccontainer, er der allerede styr på Focus

		setImmediate(function(){
			window.scrollTo(0,tmp_scroll);
		});
		// window.pageYOffset = tmp_scroll;
		// document.documentElement.scrollTop = tmp_scroll;
		// document.body.scrollTop = tmp_scroll;

	},
	
	ToggleHighlight : function(Row, AddToHighlight, Reset, ShiftKey, NoRefresh)
	{
		if (this.AllowHighlight === false)
			return false;
		if (Row.hasClassName('prevent-highlight'))
			return false;
		NoRefresh = NoRefresh || false;

		this.TakeFocus();

		var HighlightCache = this.Serialize();
		
		AddToHighlight = (this.AllowHighlight=='multiple' && AddToHighlight) || false;
		Reset = Reset || false;
		ShiftKey = (this.AllowHighlight=='multiple' && ShiftKey) || false;
		
		var Highlighted = (Reset) ? false : Row.hasClassName('highlight');
		var Level = (this.AllowMultilevel===false || Row.readAttribute('rowid').indexOf("/")==-1) ? 0 : 1;
		
		if (!AddToHighlight || Reset)
		{
			this.Container.select('.highlight').invoke('removeClassName','highlight');
			this.Highlighted = [];
		}
		
		var RowID = Row.readAttribute('rowid');
		if (ShiftKey && this.Highlighted.size() > 0)
		{
			let OrgRowID = GetLast(this.Highlighted.reject(function(r) {return this.HighlightedByShift.find(function(s) {return s == r;})}.bind(this)));
			var OrgRowFound = false;
			var RowFound = false;
			var ShiftRange = [];
			var ShiftDirection = "down";
			this.Container.select("*[rowid]").each(function(ShiftRow) {
				let ShiftRowID = ShiftRow.readAttribute("rowid");
				var ShiftRowLevel = (this.AllowMultilevel===false || ShiftRow.readAttribute('rowid').indexOf("/")==-1) ? 0 : 1;
				if (ShiftRowID == OrgRowID)
				{
				}
				else if (ShiftRowLevel != Level)
				{
				}
				else if (ShiftRowID == RowID || ( (OrgRowFound || RowFound) && !(OrgRowFound && RowFound)) )
				{
					ShiftRow.addClassName("highlight");
					
					ShiftRange.push(ShiftRowID);
				}
				else
				{
					if (ShiftRow.hasClassName("highlight") && this.HighlightedByShift.find(function(r) {return r == ShiftRowID;}) )
					{
						ShiftRow.removeClassName("highlight");
						this.Highlighted = this.Highlighted.reject(function(r) {return r == ShiftRowID;});
					}
				}
				
				if (ShiftRowID == OrgRowID)	OrgRowFound = true;
				if (ShiftRowID == RowID)	RowFound = true;
				
				if (RowFound && !OrgRowFound)	ShiftDirection = "up";
				
			}.bind(this));
			
			if (ShiftDirection=="up")
			{
				var tmp = [];
				ShiftRange.each(function(ShiftRowID) {tmp.unshift(ShiftRowID)});
				ShiftRange = tmp;
			}
			
			ShiftRange.each(function(ShiftRowID) {this.Highlighted = this.Highlighted.reject(function(r) {return r==ShiftRowID;}); this.Highlighted.push(ShiftRowID)}.bind(this));
			
			this.HighlightedByShift = ShiftRange;
		}
		else if (!Highlighted)
		{
			Row.addClassName('highlight');
			this.Highlighted.push(RowID);
		
			this.HighlightedByShift = [];
		}
		else
		{
			Row.removeClassName('highlight');
			this.Highlighted = this.Highlighted.reject(function(r) {return r == RowID});
		
			this.HighlightedByShift = [];
		}

		if (HighlightCache != this.Serialize())
		{
			if (typeof(this.CustomEvents.highlight) != "undefined")
			{
				var r = this.CustomEvents.highlight.bind(this)();
				if (r)
				{
					return;
				}
			}
			
			if (NoRefresh===false)
				this.RefreshContextBar();
		}
	},
	
	SetHighlight : function(highlight)
	{
		this.Highlighted = highlight;
		this.HighlightedByShift = [];

		if (typeof(this.CustomEvents.highlight) != "undefined") {
			var r = this.CustomEvents.highlight.bind(this)();
		}
		
		return this;
	},
	
	ResetHighlight : function(silent)
	{
		silent = silent || false;
		if (this.Highlighted.size() > 0)
		{
			this.Container.select('.highlight').invoke('removeClassName','highlight');
			this.Highlighted = [];
			this.HighlightedByShift = [];

			if (typeof(this.CustomEvents.highlight) != "undefined") {
				var r = this.CustomEvents.highlight.bind(this)();
			}
	
/*			if (typeof(this.CustomEvents.highlight) != "undefined")
			{
				var r = this.CustomEvents.highlight.bind(this)();
				if (r)
				{
					return;
				}
			}*/
			
			if (!silent)
				this.RefreshContextBar();
		}

		return this;
	},
	/*
	RequestContextDropdown : function()
	{
		var Params = 'action=GetContextDropdown&context='+this.Id+'&id='+this.Highlighted;
		
		Debug("Vis muligheder: "+this.AjaxUrl + "?" + Params);
	},*/
	
	InPlaceEdit : function(Params, form)
	{
		if (this.Highlighted.any(function(RowID) {return (RowID==-1 || this.Container.select('*[rowid="'+RowID+'"]').size() > 0);}.bind(this)))
		{
			if (typeof(this.CustomEvents.startinplaceedit) != "undefined") {
				this.CustomEvents.startinplaceedit.bind(this)();
			}
			if (typeof(this.CustomEvents.toggleinplaceedit) != "undefined") {
				this.CustomEvents.toggleinplaceedit.bind(this)(true);
			}

			this.InPlaceEditing = true;
			
			var ParamsOnce = Params || {};
			ParamsOnce.edit = '1';
			
			if (form && $(form)) {
				Object.extend(ParamsOnce, $(form).serialize(true));
			}
			
			this.Refresh({}, ParamsOnce);
			this.RefreshContextBar();
		}
	},
	
	AddInput : function(css)
	{
		this.Inputs.push(css);
		
		return this;
	},
	
	StopInPlaceEdit : function(Params)
	{
		if (this.InPlaceEditing !== false)
		{
			Params = Params || {};

			if (typeof(this.CustomEvents.stopinplaceedit) != "undefined") {
				this.CustomEvents.stopinplaceedit.bind(this)();
			}
			if (typeof(this.CustomEvents.toggleinplaceedit) != "undefined") {
				this.CustomEvents.toggleinplaceedit.bind(this)(false);
			}

			this.InPlaceEditing = false;
		
			if (this.Highlighted[0] == "-1")
			{
				this.Highlighted = [];
				this.HighlightedByShift = [];
			}

			this.Refresh(Params, {'edit' : 0});
			this.RefreshContextBar();
		}
		
		return this;
	},
	
	RefreshContextBar : function(Params)
	{
		if (this.ContextBar && this.AjaxUrl)
		{
			if (typeof(this.CustomEvents.refreshcontextbar) != "undefined")
			{
				var r = this.CustomEvents.refreshcontextbar.bind(this)();
				if (r)
				{
					return;
				}
			}
			
			var DefaultParams = Object.clone(this.AjaxParams);
			DefaultParams = Object.extend(DefaultParams,{
				'action' : 'GetContextBar',
				'context' : this.Id,
				'Highlighted' : this.Serialize()
			});
			if (this.InPlaceEditing)
				DefaultParams.edit = '1';
				
			for (let i in DefaultParams)
			{
				if (typeof(DefaultParams[i]) == "function")
					DefaultParams[i] = DefaultParams[i]();
			}
	
			var AjaxParams = Object.extend(DefaultParams, Params || {});
			
			new SB_Ajax(this.AjaxUrl, AjaxParams).SetDelay(0).SetTarget(this.ContextBar)/* .AddEvent("onSuccess",ContextBarAdjust) */.Request();
		}
		
		return this;
	},
	
	Refresh : function(ParamsSave, ParamsOnce)
	{
//		if (this.Container.cumulativeOffset()[1] < window.pageYOffset)
//			this.Container.scrollTo();
			
		if (this.AjaxUrl)
		{
			if (this.DynamicContainer)
			{
				this.Highlighted = [];
				this.HighlightedByShift = [];
			}
			
			this.AjaxParams.Highlighted = this.Serialize();
			var compare = Object.toQueryString(this.AjaxParams);
			
			if (this.AutoComplete && this.AjaxParams.search != SB_Input(this.AutoComplete).value())
			{
				this.AjaxParams.search = SB_Input(this.AutoComplete).value();
				this.AjaxParams.PaginateOffset = 0;
			}
			
			Object.extend(this.AjaxParams, this.SerializeInputs());
			Object.extend(this.AjaxParams, ParamsSave || {});
			
			// --- \\
			
			ParamsOnce = Object.extend(ParamsOnce || {}, this.AjaxParams);
			
			for (let i in ParamsOnce)
			{
				if (typeof(ParamsOnce[i]) == "function")
					ParamsOnce[i] = ParamsOnce[i]();
			}
	
			if (compare != Object.toQueryString(ParamsOnce) || this.Reload === true)
			{
				if (this.AllowHighlight == 'single')
				{
//					this.ResetHighlight();
//					ParamsOnce.Highlighted = this.Serialize();
				}
			
				if(this.CustomAjaxFunction)
				{
					this.CustomAjaxFunction(this,this.AjaxUrl);
				}
				else
				{
					new SB_Ajax(this.AjaxUrl,ParamsOnce).SetDelay(this.Delay).SetTarget(this.Container).AddEvent('onComplete',this.Refreshed.bind(this)).Request();
				}
				this.Reload = false;
			}
		}
		
		return this;
	},
	
	Refreshed : function()
	{
		this.Container.scrollTop = 0;
		// Løser chrome-problem, hvor det grå overlay ikke blev fjernet.
		// Laver samtidig et problem, hvor der hele tiden scrolles til toppen.
		// Det kunne muligvis løses i ForceRedraw, men måske har chrome allerede løst problemet,
		// så indtil videre er løsningen bare disabled.
		// 
		// (function(){
		// 	ForceRedraw(this.Container);
		// }).bind(this).defer();
		
		if (this.DynamicContainer && !this.AutoComplete.descendantOf(document.body) )
		{
			this.Destroy();
		}

		if (this.AutoCompletePreselect && this.Highlighted.size()==0)
		{
			var Row = this.Container.down('*[rowid]:not(.prevent-highlight)');
			if (Row)
				this.ToggleHighlight(Row);
		}
		
		if (this.InPlaceEditing===true)
		{
			var inputs = this.Container.select(".input");
			if (inputs.size() > 0)
			{
				SB_Input(inputs[0]).activate();

				/* (function() {
					if (typeof(inputs[0].opentip)!="undefined")
						inputs[0].opentip.show();
				}).defer(); // Skal forsinkes, da opentip'et også er initialiseres forsinket */
			}
			
		}
	},
	
	Serialize : function()
	{
		return this.Highlighted.join(",");
	},
	
	SerializeInputs : function()
	{
		var Serialized = {};
		
		this.Inputs.each(function(css) {
			$$(css).each(function(input) {
				Serialized[input.readAttribute('name')] = SB_Input(input).value();
			});
		});
		
		return Serialized;
	},
	
	GetRow : function(RowId)
	{
		var Rows = this.Container.select("*[rowid='"+RowId.replace(/\\/g, "\\\\")+"']");
		if (Rows.size() > 0)
			return Rows[0];
		else
			return false;
		
	},
	
	AddRow : function()
	{
		this.Highlighted = [-1]; 
		this.InPlaceEdit();
	},
	
	Submit : function(url, params)
	{
		params = params || "";
		if (typeof(params) == "object")
			params = Object.toQueryString(params);
			
		if (params != "")
			params = params + "&";
			
		params = params + "Highlighted="+this.Serialize();
		
		return new SB_Ajax(url,params).Request();
	},
	
	Destroy : function()
	{
		if (this.AutoComplete)
			this.AutoComplete.stopObserving();
		
		this.Container.stopObserving();
		
		if (this.DynamicContainer && this.Container && this.Container.up("body"))
		{
			if (this.DynamicContainerPopper) {
				this.DynamicContainerPopper.destroy();
				this.DynamicContainerPopper = false;
			}
			this.Container.update().remove();
		}
		
		delete(SB_Lists[this.Id]);
	}
});


import { animate_height_expand, animate_height_collapse } from "./ui_elements.es6.js";
export function toggle_additional_list_content(el) {
	let tbody = el.closest("tbody");
	let chevron = tbody.querySelector(".chevron");
	if (tbody.querySelector(".expandable").classList.contains("expanded")) {
		tbody.querySelector(".expandable").classList.remove("expanded");
		animate_height_collapse(tbody.querySelector(".expandable"), () => {
			tbody.querySelector(".expandable").classList.remove("expanded");
		});
		chevron.classList.remove("up");
		chevron.classList.add("down");
	} else {
		tbody.querySelector(".expandable").classList.add("expanded");
		animate_height_expand(tbody.querySelector(".expandable"), () => {
			tbody.querySelector(".expandable").classList.add("expanded");
		});
		chevron.classList.remove("down");
		chevron.classList.add("up");
	}
}